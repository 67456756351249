StudyService.$inject = ['$http', 'AppService']

export default function StudyService($http, AppService) {
  return {
    getStudies: function(profileId) {
      return $http.get('/api-prema2/studies?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"createdAt DESC"}').then(res => {
        var studies = res.data

        studies.forEach(study => {
          if (study.medias) study.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getStudy: function(profileId, slug) {
      return $http.get('/api-prema2/studies/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["durationUnit","services",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}').then(res => {
        var study = res.data

        if (study.medias) study.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getStudyById: function(studyId) {
      return $http.get('/api-prema2/studies/' + studyId)
    },
    initStudy: function() {
      var study = {
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return study
    },
    postStudy: function(study) {
      study.slug = AppService.toSlug(study.study)
      study.createdAt = new Date()
      study.updatedAt = new Date()
      
      return $http.post('/api-prema2/studies', study, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var study2 = res.data

        if (study.medias) {
          study.medias.forEach(media => {
            var studyToMedia = {
              order: media.order,
              studyId: study2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/studyToMedias', studyToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: study2.id.toString(),
          createdAt: study2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentStudy,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (study.services) {
            study.services.forEach(service => {
              var studyToService = {
                studyId: study2.id,
                serviceId: service.id
              }

              return $http.post('/api-prema2/studyToServices', studyToService, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
          }
        })
      })
    },
    putStudy: function(study) {
      study.slug = AppService.toSlug(study.study)
      study.updatedAt = new Date()
      study.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/studyToMedias?filter[where][studyId]=' + study.id).then(res => {
        var studyToMedias = res.data

        var diffs = [].concat(study.medias.filter(obj1 => studyToMedias.every(obj2 => obj1.id !== obj2.id)), studyToMedias.filter(obj2 => study.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!studyToMedias.includes(media)) {
            var studyToMedia = {
              order: media.order,
              studyId: study.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/studyToMedias', studyToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/studyToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.get('/api-prema2/studyToServices?filter[where][studyId]=' + study.id).then(res => {
          var studyToServices = res.data

          var diffs = [].concat(study.services.filter(obj1 => studyToServices.every(obj2 => obj1.id !== obj2.id)), studyToServices.filter(obj2 => study.services.every(obj1 => obj2.id !== obj1.id)))
          var added = diffs.filter(diff => diff.service)
          var removed = diffs.filter(diff => diff.studyId)
          if (added.length > 0) added.forEach(service => {
            var studyToService = {
              studyId: study.id,
              serviceId: service.id
            }

            $http.post('/api-prema2/studyToServices', studyToService, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
          if (removed.length > 0) removed.forEach(studyToService => {
            $http.delete('/api-prema2/studyToServices/' + studyToService.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          })

          return $http.put('/api-prema2/studies/' + study.id, study, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var study2 = res.data
    
            var auditLog = {
              objectId: study2.id.toString(),
              createdAt: study2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: study2.updatedById,
              componentId: AppService.componentStudy,
              logTypeId: AppService.logTypeUpdate
            }
    
            return AppService.postAuditLog(auditLog)
          })
        })
      })
    },
    deleteStudy: function(study) {
      return $http.delete('/api-prema2/studies/' + study.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}