import angular from 'angular'

function eventCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
    },
    template: require('./_card-event.html')
  }
}

export default angular.module('directives.eventCard', [])
  .directive('eventCard', eventCard)
  .name