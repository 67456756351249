primaryLocationSlideout.$inject = ['AppService', '$rootScope', '$timeout']

export default function primaryLocationSlideout(AppService, $rootScope, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      profile: '=',
      location: '='
    },
    link: function(scope, elem, attrs) {
      AppService.getCountries().then(res => scope.countries = res.data)

      var shell = scope.$parent.$parent.shell
      var home = scope.$parent.home

      scope.mapOptions = {}
      if (document.documentElement.getAttribute('data-theme')) {
        if (document.documentElement.getAttribute('data-theme') == 'dark') {
          scope.mapOptions = {
            styles: [
              {elementType: 'geometry', stylers: [{color: '#242424'}]},
              {elementType: 'labels.text.stroke', stylers: [{color: '#242424'}]},
              {elementType: 'labels.text.fill', stylers: [{color: '#747474'}]},
              {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d5d5d5'}]
              },
              {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d5d5d5'}]
              },
              {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [{color: '#263c3f'}]
              },
              {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [{color: '#6b9a76'}]
              },
              {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [{color: '#383838'}]
              },
              {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [{color: '#212121'}]
              },
              {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [{color: '#9c9c9c'}]
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [{color: '#747474'}]
              },
              {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [{color: '#1f1f1f'}]
              },
              {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [{color: '#f3f3f3'}]
              },
              {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [{color: '#2f2f2f'}]
              },
              {
                featureType: 'transit.station',
                elementType: 'labels.text.fill',
                stylers: [{color: '#d5d5d5'}]
              },
              {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [{color: '#17263c'}]
              },
              {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [{color: '#515c6d'}]
              },
              {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [{color: '#17263c'}]
              }
            ]
          }
        }
      }
      
      scope.toggleManagePrimaryLocation = function() {
        AppService.putLocation(scope.location).then(res => {
          var location = res.data

          $rootScope.profile.locationPrimary = location
          $rootScope.locationPrimary = location

          if (sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
            AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
              shell.setPrimaryLocation = true
              AppService.getTaskResponses(sessionStorage.getItem('contactId')).then(res => {
                home.taskResponses = res.data
  
                var intersections = home.profileTasks.filter(profileTask => home.taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))
  
                home.profileTasks.forEach(profileTask => {
                  profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
                })
              })
              sessionStorage.removeItem('taskId')
              sessionStorage.removeItem('contactId')
              $timeout(function() {
                shell.hideAlert('setPrimaryLocation')
              }, 5000)
            })
          } else {
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
          }
        })
        
        scope.slideout = false
      }

      scope.addCommunication = function() {
        if (!scope.location.communications) scope.location.communications = new Array()
        scope.location.communications.push({
          order: scope.location.communications.length + 1,
          isPublished: true
        })
      }
      scope.deleteCommunication = function(communication) {
        var index = scope.location.communications.indexOf(communication)
        scope.location.communications.splice(index, 1)
      }
    },
    template: require('./_slideout-primary-location.html')
  }

  return directive
}