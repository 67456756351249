serviceCategoriesSlideout.$inject = ['ServiceService']

export default function serviceCategoriesSlideout(ServiceService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getServiceCategories(profileId) {
        ServiceService.getServiceCategories(profileId).then(res => scope.serviceCategories = res.data)
      }

      getServiceCategories(sessionStorage.getItem('profileId'))

      scope.toggleManageCategories = function() {
        new Promise((resolve, reject) => {
          scope.serviceCategories.forEach(serviceCategory => ServiceService.putServiceCategory(serviceCategory).then(() => resolve()))
        }).then(() => getServiceCategories(sessionStorage.getItem('profileId')))
        
        scope.slideout = false
      }
      scope.addCategory = function() {
        scope.serviceCategories.push({
          order: scope.serviceCategories.length + 1
        })
      }
      scope.deleteCategory = function(serviceCategory) {
        ServiceService.deleteServiceCategory(serviceCategory).then(() => getServiceCategories(sessionStorage.getItem('profileId')))
      }
    },
    template: require('./_slideout-categories.html')
  }

  return directive
}