MeOrdersController.$inject = ['$rootScope', 'MeService']

export default function MeOrdersController($rootScope, MeService) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      MeService.getOrders(profileId).then(res => vm.orders = res.data)
    }
  })
}