import angular from 'angular'

reviewForm.$inject = ['AppService', '$timeout', '$state']

function reviewForm(AppService, $timeout, $state) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.contact = new Object(); scope.review = new Object()
      
      scope.setRating = function(index) {
        scope.review.rating = index
      }
      scope.createReview = function() {
        scope.submitted = true

        AppService.postReview(scope.review, scope.contact).then(() => {
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          shell.postedReview = true
          $state.go('shell.reviews', null, {reload: true})
          $timeout(function() {
            shell.hideAlert('postedReview')
          }, 5000)
        })
      }
    },
    template: require('./_form-review.html')
  }
}

export default angular.module('directives.reviewForm', [])
  .directive('reviewForm', reviewForm)
  .name