CustomerController.$inject = ['$rootScope', 'CustomerService', '$stateParams', 'AuthService', 'AppService', '$state', '$transitions']

export default function CustomerController($rootScope, CustomerService, $stateParams, AuthService, AppService, $state, $transitions) {
  var vm = this

  function getCustomer(profile) {
    var getCustomer = new Promise((resolve, reject) => CustomerService.getCustomer(profile.id, $stateParams.slug).then(res => {
      vm.customer = res.data
      copy = angular.copy(vm.customer)
      vm.customer.startDate = new Date(vm.customer.startDate)
      if (vm.customer.endDate) vm.customer.endDate = new Date(vm.customer.endDate)
      if (vm.customer.isSysUser) AuthService.getSysUserByContactId(vm.customer.contactId).then(res => vm.customer.sysUser = res.data)
      copy.startDate = new Date(copy.startDate)
      if (copy.endDate) copy.endDate = new Date(copy.endDate)
      if (copy.isSysUser) AuthService.getSysUserByContactId(copy.contactId).then(res => copy.sysUser = res.data)
      resolve()
    }))
    getCustomer.then(() => {
      AppService.getComponentByName('Customer').then(res => {
        var component = res.data

        CustomerService.getProfileTasks(profile.id, component.id).then(res => {
          vm.profileTasks = res.data

          CustomerService.getTaskResponses(vm.customer.contactId).then(res => {
            var taskResponses = res.data

            var intersections = vm.profileTasks.filter(profileTask => taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))

            vm.profileTasks.forEach(profileTask => {
              profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
            })
          })
        })
      })

      if (profile.settings.questionnaireId) {
        AppService.getQuestionnaire(profile.settings.questionnaireId).then(res => {
          vm.questionnaire = res.data
  
          AppService.getQuestionnaireQuestionResponses(vm.questionnaire.id, vm.customer.contactId).then(res => vm.questionnaireQuestionResponses = res.data)
        })
        vm.questionnaireId = profile.settings.questionnaireId
      }

      CustomerService.getBookings(vm.customer.contactId).then(res => vm.bookings = res.data)
      CustomerService.getUpcomingAndRecurringEventsInclGuests().then(res => {
        var events = res.data
        vm.events = events.filter(event => event.eventGuests.some(eventGuest => eventGuest.contactId === vm.customer.contactId))
      })
      CustomerService.getOrders(vm.customer.contactId).then(res => vm.orders = res.data)
      CustomerService.getMemberships(vm.customer.contactId).then(res => {
        vm.memberships = res.data

        function getPaymentById(membership) {
          var payment = membership.activities.find(activity => activity.activityType.type === 'Payment')
          if (payment) AppService.getPaymentById(payment.objectId).then(res => {
            membership.paymentMostRecent = res.data
      
            var now = new Date()
            var startDateUtc = new Date(membership.startDateUtc)
            var endDateUtc = membership.recurrencePattern.pattern === 'Every day' ? new Date(startDateUtc.setUTCDate(startDateUtc.getUTCDate() + 1)) : membership.recurrencePattern.pattern === 'Every week' ? new Date(startDateUtc.setUTCDate(startDateUtc.getUTCDate() + 7)) : membership.recurrencePattern.pattern === 'Every two weeks' ? new Date(startDateUtc.setDate(startDateUtc.getDate() + 14)) : membership.recurrencePattern.pattern === 'Every month' ? new Date(startDateUtc.setUTCMonth(startDateUtc.getUTCMonth() + 1)) : new Date(startDateUtc.setUTCYear(startDateUtc.getUTCYear() + 1))
            if (now > endDateUtc) membership.isExpired = true
          })
        }
        
        vm.memberships.forEach(membership => getPaymentById(membership) ? membership.activities.length > 0 : null)
      })
      CustomerService.getStudies(vm.customer.contactId).then(res => vm.projects = res.data)
      CustomerService.getPayments(vm.customer.contactId).then(res => {
        var payments = res.data
        var paymentsNetValues = payments.map(payment => payment.netValue)
        vm.paymentsTotalNetValue = paymentsNetValues.reduce((a, c) => a + c, 0)
      })
    })
  }
  
  var copy
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      getCustomer(profile)
    }
  })

  vm.delete = function() {
    vm.confirmDelete = true
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    CustomerService.deleteCustomer(vm.customer).then(() => $state.go('shell.customers'))
  }
  vm.tabs = [0, 1, 2, 3, 4, 5, 6, 7]
  vm.activeTab = 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
  $transitions.onBefore({}, () => {
    if (!angular.equals(vm.customer, copy)) return CustomerService.putCustomer(vm.customer)
  }, {invokeLimit: 1})
}