routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.articles', {
      url: '/articles?page',
      template: require('./articles.html'),
      controller: 'ArticlesController',
      controllerAs: 'articles',
      resolve: {
        $title: function() {
          return 'Articles'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.article', {
      url: '/articles/:slug',
      template: require('./article.html'),
      controller: 'ArticleController',
      controllerAs: 'article',
      resolve: {
        article: ['ArticleService', '$stateParams', function(ArticleService, $stateParams) {
          return ArticleService.getArticle(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['article', function(article) {
          return article.data.post
        }]
      }
    })
    .state('shell.newArticle', {
      url: '/articles/new',
      template: require('./new-article.html'),
      controller: 'NewArticleController',
      controllerAs: 'newArticle',
      resolve: {
        $title: function() {
          return 'New Article'
        }
      }
    })
    .state('shell.viewArticle', {
      url: '/articles/:slug',
      template: require('./view-article.html'),
      controller: 'ViewArticleController',
      controllerAs: 'viewArticle',
      resolve: {
        article: ['ArticleService', '$stateParams', function(ArticleService, $stateParams) {
          return ArticleService.getArticle('61f8e43a79fd747201d0d3da', $stateParams.slug)
        }],
        $title: ['article', function(article) {
          return article.data.post
        }]
      }
    })
}