routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.locations', {
      url: '/locations?page',
      template: require('./locations.html'),
      controller: 'LocationsController',
      controllerAs: 'locations',
      resolve: {
        $title: function() {
          return 'Locations'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.location', {
      url: '/locations/:slug',
      template: require('./location.html'),
      controller: 'LocationController',
      controllerAs: 'location',
      resolve: {
        location: ['LocationService', '$stateParams', function(LocationService, $stateParams) {
          return LocationService.getLocation(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['location', function(location) {
          return location.data.location
        }]
      }
    })
    .state('shell.newLocation', {
      url: '/locations/new',
      template: require('./new-location.html'),
      controller: 'NewLocationController',
      controllerAs: 'newLocation',
      resolve: {
        $title: function() {
          return 'New Location'
        }
      }
    })
}