ServiceService.$inject = ['$http', 'AppService']

export default function ServiceService($http, AppService) {
  return {
    getServiceCategories: function(profileId) {
      return $http.get('/api-prema2/serviceCategories?filter[where][profileId]=' + profileId + '&filter[order]=order%20ASC')
    },
    putServiceCategory: function(serviceCategory) {
      serviceCategory.slug = AppService.toSlug(serviceCategory.category)
      serviceCategory.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/serviceCategories', serviceCategory, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteServiceCategory: function(serviceCategory) {
      return $http.delete('/api-prema2/serviceCategories/' + serviceCategory.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getServices: function(profileId) {
      return $http.get('/api-prema2/services?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"paymentType"],"order":"order ASC"}').then(res => {
        var services = res.data

        services.forEach(service => {
          if (service.medias) service.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getService: function(profileId, slug) {
      return $http.get('/api-prema2/services/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"serviceCategories"]}').then(res => {
        var service = res.data

        if (service.medias) service.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getServiceById: function(serviceId) {
      return $http.get('/api-prema2/services/' + serviceId)
    },
    initService: function() {
      var service = {
        isBookable: true,
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      $http.get('/api-prema2/services/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var services = res.data
        service.order = services.count + 1
      })

      return service
    },
    postService: function(service) {
      service.slug = AppService.toSlug(service.service)
      service.createdAt = new Date()
      service.updatedAt = new Date()
      
      return $http.post('/api-prema2/services', service, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var service2 = res.data

        if (service.medias) {
          service.medias.forEach(media => {
            var serviceToMedia = {
              order: media.order,
              serviceId: service2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/serviceToMedias', serviceToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: service2.id.toString(),
          createdAt: service2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentService,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (service.serviceCategories) {
            service.serviceCategories.forEach(serviceCategory => {
              var serviceCategoryToService = {
                serviceCategoryId: serviceCategory.id,
                serviceId: service2.id
              }

              return $http.post('/api-prema2/serviceCategoryToServices', serviceCategoryToService, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
          }
        })
      })
    },
    putService: function(service) {
      service.slug = AppService.toSlug(service.service)
      service.updatedAt = new Date()
      service.updatedById = localStorage.getItem('userId')
      
      return $http.get('/api-prema2/serviceToMedias?filter[where][serviceId]=' + service.id).then(res => {
        var serviceToMedias = res.data

        var diffs = [].concat(service.medias.filter(obj1 => serviceToMedias.every(obj2 => obj1.id !== obj2.id)), serviceToMedias.filter(obj2 => service.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!serviceToMedias.includes(media)) {
            var serviceToMedia = {
              order: media.order,
              serviceId: service.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/serviceToMedias', serviceToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/serviceToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.get('/api-prema2/serviceCategoryToServices?filter[where][serviceId]=' + service.id).then(res => {
          var serviceCategoryToServices = res.data

          var diffs = [].concat(service.serviceCategories.filter(obj1 => serviceCategoryToServices.every(obj2 => obj1.id !== obj2.serviceCategoryId)), serviceCategoryToServices.filter(obj2 => service.serviceCategories.every(obj1 => obj2.serviceCategoryId !== obj1.id)))
          var added = diffs.filter(diff => diff.category)
          var removed = diffs.filter(diff => diff.serviceCategoryId)
          if (added.length > 0) added.forEach(serviceCategory => {
            var serviceCategoryToService = {
              serviceCategoryId: serviceCategory.id,
              serviceId: service.id
            }

            $http.post('/api-prema2/serviceCategoryToServices', serviceCategoryToService, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
          if (removed.length > 0) removed.forEach(serviceCategoryToService => {
            $http.delete('/api-prema2/serviceCategoryToServices/' + serviceCategoryToService.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          })

          return $http.put('/api-prema2/services/' + service.id, service, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var service2 = res.data
    
            var auditLog = {
              objectId: service2.id.toString(),
              createdAt: service2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: service2.updatedById,
              componentId: AppService.componentService,
              logTypeId: AppService.logTypeUpdate
            }
    
            return AppService.postAuditLog(auditLog)
          })
        })
      })
    },
    deleteService: function(service) {
      return new Promise((resolve, reject) => {
        if (service.serviceCategories.length) {
          service.serviceCategories.forEach(serviceCategory => $http.get('/api-prema2/serviceCategoryToServices/findOne?filter[where][serviceCategoryId]=' + serviceCategory.id + '&filter[where][serviceId]=' + service.id).then(res => {
            var serviceCategoryToService = res.data

            $http.delete('/api-prema2/serviceCategoryToServices/' + serviceCategoryToService.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => resolve())
          }))
        } else {
          resolve()
        }
      }).then(() => {
        return $http.delete('/api-prema2/services/' + service.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    },
    getSubscriptions: function(profileId) {
      return $http.get('/api-prema2/subscriptions?filter[where][profileId]=' + profileId)
    },
    initSubscription: function(subscriptions) {
      var subscription = {
        order: subscriptions.length + 1,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return subscription
    },
    postSubscription: function(subscription) {
      subscription.createdAt = new Date()
      subscription.updatedAt = new Date()
      
      return $http.post('/api-prema2/subscriptions', subscription, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var subscription2 = res.data

        var auditLog = {
          objectId: subscription2.id.toString(),
          createdAt: subscription2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentSubscription,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    putSubscription: function(subscription) {
      subscription.updatedAt = new Date()
      subscription.updatedById = localStorage.getItem('userId')
      return $http.put('/api-prema2/subscriptions', subscription, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var subscription2 = res.data

        var auditLog = {
          objectId: subscription2.id.toString(),
          createdAt: subscription2.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentSubscription,
          logTypeId: AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    deleteSubscription: function(subscription) {
      return $http.delete('/api-prema2/subscriptions/' + subscription.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getSubscriptionById: function(subscriptionId) {
      return $http.get('/api-prema2/subscriptions/' + subscriptionId, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}