faqForm.$inject = []

export default function faqForm() {
  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.setPromoted = function(faq) {
        faq.isPromoted = !faq.isPromoted
      }
      scope.setPublished = function(faq) {
        faq.isPublished = !faq.isPublished
      }
      scope.delete = function(faq) {
        var higherIndexes = scope.$parent.$parent.faqs.faqs.filter((faq2, index) => index > scope.$parent.$parent.faqs.faqs.indexOf(faq))
        higherIndexes.forEach(faq => faq.order = faq.order - 1)

        var index = scope.$parent.$parent.faqs.faqs.indexOf(faq)
        scope.$parent.$parent.faqs.faqs.splice(index, 1)
      }
    },
    template: require('./_form-faq.html')
  }

  return directive
}