ModalSendEmailController.$inject = ['$scope', '$uibModalInstance']

export default function ModalSendEmailController($scope, $uibModalInstance) {
  var vm = this

  $scope.sendEmailPrefs = new Object()

  $scope.close = function() {
    $uibModalInstance.close($scope.sendEmailPrefs)
  }
}