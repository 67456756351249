import angular from 'angular'
import uirouter from 'angular-ui-router'
import 'particles.js'

import routing from './auth.routes'
import AuthService from './auth.service'
import AuthController from './auth.controller'
import ModalSysUserController from './modal-sys-user.controller'
import LoginFormDirective from './login-form.directive'
import RegisterFormDirective from './register-form.directive'
import PasswordFormDirective from './password-form.directive'
import ForgotPasswordFormDirective from './forgot-password-form.directive'
import SysUserFormDirective from './sys-user-form.directive'
import ParticlesDirective from './particles.directive'
import './auth.less'

export default angular.module('app.auth', [uirouter])
  .config(routing)
  .service('AuthService', AuthService)
  .controller('AuthController', AuthController)
  .controller('ModalSysUserController', ModalSysUserController)
  .directive('loginForm', LoginFormDirective)
  .directive('registerForm', RegisterFormDirective)
  .directive('passwordForm', PasswordFormDirective)
  .directive('forgotPasswordForm', ForgotPasswordFormDirective)
  .directive('sysUserForm', SysUserFormDirective)
  .directive('particles', ParticlesDirective)
  .name