routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.contracts', {
      url: '/contracts?page',
      template: require('./contracts.html'),
      controller: 'ContractsController',
      controllerAs: 'contracts',
      resolve: {
        $title: function() {
          return 'Contracts'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.contract', {
      url: '/contracts/:referenceNo',
      template: require('./contract.html'),
      controller: 'ContractController',
      controllerAs: 'contract',
      resolve: {
        contract: ['ContractService', '$stateParams', function(ContractService, $stateParams) {
          return ContractService.getContract($stateParams.referenceNo).then(res => res.data)
        }],
        $title: ['contract', function(contract) {
          var name
          if (contract.contactId) {
            name = contract.contact.lastName ? contract.contact.firstName + ' ' + contract.contact.lastName : contract.contact.firstName
          } else {
            name = ''
          }
          return 'Ref. &#35;' + contract.referenceNo + ' ' + name
        }]
      }
    })
}