addLessonSlideout.$inject = ['AppService']

export default function addLessonSlideout(AppService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      course: '='
    },
    link: function(scope, elem, attrs) {
      scope.lesson = new Object()
      AppService.getTimeUnits().then(res => scope.timeUnits = res.data)

      scope.toggleAddLesson = function() {
        if (!scope.course.lessons) scope.course.lessons = new Array()
        scope.lesson.order = scope.course.lessons.length + 1
        scope.course.lessons.push(scope.lesson)
        scope.lesson = new Object()
        scope.slideout = false
      }
      scope.addLearningOutcome = function() {
        if (!scope.lesson.learningOutcomes) scope.lesson.learningOutcomes = new Array()
        scope.lesson.learningOutcomes.length++
      }
      scope.deleteLearningOutcome = function(index) {
        scope.lesson.learningOutcomes.splice(index, 1)
      }
      scope.addResource = function() {
        if (!scope.lesson.resources) scope.lesson.resources = new Array()
        scope.lesson.resources.push({
          order: scope.lesson.resources.length + 1
        })
      }
      scope.deleteResource = function(index) {
        scope.lesson.resources.splice(index, 1)
      }
    },
    template: require('./_slideout-add-lesson.html')
  }

  return directive
}