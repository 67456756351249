import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './event.routes'
import EventService from './event.service'
import EventCard from './event-card.directive'
import EventFormDirective from './event-form.directive'
import EventCategoriesSlideoutDirective from './categories-slideout.directive'
import AddGuestSlideoutDirective from './add-guest.directive'
import CalendarDirective from './calendar.directive'
// import BookingTypesSlideoutDirective from './booking-types-slideout.directive'
import EventsController from './events.controller'
import EventController from './event.controller'
import BookingController from './booking.controller'
import NewEventController from './new-event.controller'
import './event.less'

export default angular.module('app.event', [uirouter, EventCard])
  .config(routing)
  .service('EventService', EventService)
  .directive('eventForm', EventFormDirective)
  .directive('eventCategoriesSlideout', EventCategoriesSlideoutDirective)
  .directive('addGuestSlideout', AddGuestSlideoutDirective)
  .directive('calendar', CalendarDirective)
  // .directive('bookingTypesSlideout', BookingTypesSlideoutDirective)
  .controller('EventsController', EventsController)
  .controller('EventController', EventController)
  .controller('BookingController', BookingController)
  .controller('NewEventController', NewEventController)
  .name