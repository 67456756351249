termsForm.$inject = []

export default function termsForm() {
  var directive = {
    restrict: 'E',
    replace: true,
    template: require('./_form-terms.html')
  }

  return directive
}