ContractsController.$inject = ['$rootScope', 'ContractService', '$stateParams', 'angularGridInstance', 'AppService', '$timeout', '$state']

export default function ContractsController($rootScope, ContractService, $stateParams, angularGridInstance, AppService, $timeout, $state) {
  var vm = this

  function getPaymentById(contract) {
    var payment = contract.activities.find(activity => activity.activityType.type === 'Payment')
    if (payment) AppService.getPaymentById(payment.objectId).then(res => {
      contract.paymentMostRecent = res.data

      var now = new Date()
      var startDateUtc = new Date(contract.startDateUtc)
      var endDateUtc = contract.recurrencePattern.pattern === 'Every day' ? new Date(startDateUtc.setUTCDate(startDateUtc.getUTCDate() + 1)) : contract.recurrencePattern.pattern === 'Every week' ? new Date(startDateUtc.setUTCDate(startDateUtc.getUTCDate() + 7)) : contract.recurrencePattern.pattern === 'Every two weeks' ? new Date(startDateUtc.setDate(startDateUtc.getDate() + 14)) : contract.recurrencePattern.pattern === 'Every month' ? new Date(startDateUtc.setUTCMonth(startDateUtc.getUTCMonth() + 1)) : new Date(startDateUtc.setUTCYear(startDateUtc.getUTCYear() + 1))
      if (now > endDateUtc) contract.isExpired = true
    })
  }

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      ContractService.getContracts().then(res => {
        vm.contracts = res.data

        vm.contracts.forEach(contract => getPaymentById(contract) ? contract.activities.length > 0 : null)
      })
      vm.settings = profile.settings.components.find(component => component.name === 'contracts')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components, $rootScope.profile.settings.id)
  }
  vm.setSortBy = function(property) {
    var copy = vm.contracts
    vm.contracts = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    function compareNested(a, b) {
      let comparison = 0
      while (comparison < property.length) { a = a[property[comparison]]; b = b[property[comparison]]; comparison++; }
      if (a > b) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a < b) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    if (property.includes('.')) {
      property = property.split('.')
      copy.sort(compareNested)
    } else {
      copy.sort(compare)
    }
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.contracts = copy
    }, 100)
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
  vm.numberOfPages = function() {
    return parseInt(vm.contracts.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}