LeadService.$inject = ['$http', 'AppService']

export default function LeadService($http, AppService) {
  return {
    getLeadStatuses: function() {
      return $http.get('/api-prema2/leadStatuses')
    },
    getLeads: function(profileId) {
      return $http.get('/api-prema2/leads?filter[where][profileId]=' + profileId + '&filter[include]=contact&filter[include]=leadStatus&filter[order]=createdAt%20DESC')
    },
    getLead: function(id) {
      return $http.get('/api-prema2/leads/' + id + '?filter={"include":[{"relation":"contact","scope":{"include":["contactType","locations"]}},"leadStatus",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}')
    },
    getLeadById: function(leadId) {
      return $http.get('/api-prema2/leads/' + leadId + '?filter[include]=contact')
    },
    putLead: function(lead) {
      lead.updatedAt = new Date()
      lead.updatedById = localStorage.getItem('userId')

      if (lead.contactId) {
        $http.get('/api-prema2/contacts/findOne?filter[where][id]=' + lead.contactId).then(res => {
          var contact = res.data
  
          if (!angular.equals(lead.contact, contact)) $http.put('/api-prema2/contacts/' + contact.id, lead.contact)
        })
      }

      return $http.put('/api-prema2/leads/' + lead.id, lead, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var lead2 = res.data

        var auditLog = {
          objectId: lead2.id.toString(),
          createdAt: lead2.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: lead2.updatedById,
          componentId: AppService.componentLead,
          logTypeId: AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    patchLead: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/leads/' + id, data).then(res => {
        var lead = res.data

        var auditLog = {
          objectId: lead.id.toString(),
          createdAt: lead.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentLead,
          logTypeId: key === 'isRead' ? AppService.logTypeRead : AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    emailLeads: function(leadEmails, message, cta) {    
      var data = {
        data: leadEmails,
        message: message,
        cta: cta,
        profileId: sessionStorage.getItem('profileId')
      }  
      return $http.post('/api-prema2/leads/email', data)
    }
  }
}