ContractService.$inject = ['$http', 'AppService']

export default function ContractService($http, AppService) {
  return {
    getContractStatuses: function() {
      return $http.get('/api-prema2/contractStatuses')
    },
    getContracts: function() {
      return $http.get('/api-prema2/contracts?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"contractStatus","recurrencePattern",{"relation":"activities","scope":{"include":"activityType"}}],"order":"createdAt DESC"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getContract: function(referenceNo) {
      return $http.get('/api-prema2/contracts/findOne?filter={"where":{"referenceNo":"' + referenceNo + '","profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":"contactType"}},"contractStatus","contractLineItems",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getContractById: function(contractId) {
      return $http.get('/api-prema2/contracts/' + contractId + '?filter[include]=contact', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putContract: function(contract) {
      contract.updatedAt = new Date()
      contract.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/contractLineItems?filter[where][contractId]=' + contract.id).then(res => {
        var contractLineItems = res.data

        var diffsProducts = [].concat(contract.contractLineItemProducts.filter(obj1 => contractLineItems.every(obj2 => obj1.product !== obj2.product)), contractLineItems.filter(obj2 => contract.contractLineItemProducts.every(obj1 => obj2.product !== obj1.product)))
        console.log(diffsProducts)
        if (diffsProducts.length > 0) diffsProducts.forEach(contractLineItem => {
          if (!contractLineItems.includes(contractLineItem)) {
            var contractLineItem = {
              product: {
                productNo: contractLineItem.product.productNo ? contractLineItem.product.productNo : undefined,
                product: contractLineItem.product.product,
                unitSalePrice: contractLineItem.product.unitSalePrice,
                id: contractLineItem.product.id
              },
              count: contractLineItem.count,
              totalValue: contractLineItem.totalValue,
              contractId: contract.id
            }
  
            $http.post('/api-prema2/contractLineItems', contractLineItem, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            $http.delete('/api-prema2/contractLineItems/' + contractLineItem.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        var diffsServices = [].concat(contract.contractLineItemServices.filter(obj1 => contractLineItems.every(obj2 => obj1.service !== obj2.service)), contractLineItems.filter(obj2 => contract.contractLineItemServices.every(obj1 => obj2.service !== obj1.service)))
        console.log(diffsServices)
        if (diffsServices.length > 0) diffsServices.forEach(contractLineItem => {
          if (!contractLineItems.includes(contractLineItem)) {
            var contractLineItem = {
              service: {
                serviceNo: contractLineItem.service.serviceNo ? contractLineItem.service.serviceNo : undefined,
                service: contractLineItem.service.service,
                unitSalePrice: contractLineItem.service.unitSalePrice,
                id: contractLineItem.service.id
              },
              count: contractLineItem.count,
              totalValue: contractLineItem.totalValue,
              contractId: contract.id
            }
  
            $http.post('/api-prema2/contractLineItems', contractLineItem, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            $http.delete('/api-prema2/contractLineItems/' + contractLineItem.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        if (contract.contactId) {
          $http.get('/api-prema2/contacts/findOne?filter[where][id]=' + contract.contactId).then(res => {
            var contact = res.data
    
            if (!angular.equals(contract.contact, contact)) $http.put('/api-prema2/contacts/' + contact.id, contract.contact)
          })
        }
  
        return $http.put('/api-prema2/contracts/' + contract.id, contract, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var contract2 = res.data
  
          var auditLog = {
            objectId: contract2.id.toString(),
            createdAt: contract2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: contract2.updatedById,
            componentId: AppService.componentContract,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    patchContract: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/contracts/' + id, data).then(res => {
        var contract = res.data

        var auditLog = {
          objectId: contract.id.toString(),
          createdAt: contract.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentContract,
          logTypeId: key === 'isRead' ? AppService.logTypeRead : AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    }
  }
}