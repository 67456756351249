EventService.$inject = ['$http', 'AppService']

export default function EventService($http, AppService) {
  function calcDuration(startDateUtc, endDateUtc) {
    var start = Date.parse(startDateUtc)
    var end = Date.parse(endDateUtc)

    return end - start
  }

  return {
    getEventCategories: function(profileId) {
      return $http.get('/api-prema2/eventCategories?filter[where][profileId]=' + profileId + '&filter[order]=order%20ASC')
    },
    putEventCategory: function(eventCategory) {
      eventCategory.slug = AppService.toSlug(eventCategory.category)
      eventCategory.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/eventCategories', eventCategory, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteEventCategory: function(eventCategory) {
      return $http.delete('/api-prema2/eventCategories/' + eventCategory.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    // getBookingTypes: function(profileId) {
    //   return $http.get('/api-prema2/bookingTypes?filter[where][profileId]=' + profileId + '&filter[order]=order%20ASC')
    // },
    // putBookingType: function(bookingType) {
    //   bookingType.profileId = sessionStorage.getItem('profileId')
    //   return $http.put('/api-prema2/bookingTypes', bookingType, { headers: { 'Authorization': localStorage.getItem('token') } })
    // },
    // deleteBookingType: function(bookingType) {
    //   return $http.delete('/api-prema2/bookingTypes/' + bookingType.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    // },
    getEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"recurrencePattern"]}').then(res => {
        var events = res.data

        events.forEach(event => {
          if (event.medias) event.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getUpcomingAndRecurringEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","or":[{"and":[{"endDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":false}]},{"and":[{"recurrenceEndDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":true}]},{"and":[{"recurrenceEndDateUtc":{"neq":true}},{"isRecurring":true}]}]},"include":["recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc ASC"}').then(res => {
        var events = res.data

        events.forEach(event => {
          if (event.medias) event.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        var eventsToRemove = events.filter(event => event.recurrenceEndDateUtc && new Date() >= new Date(event.recurrenceEndDateUtc))
        eventsToRemove.forEach(eventToRemove => events.splice(events.findIndex(event => event.recurrenceEndDateUtc === eventToRemove.recurrenceEndDateUtc), 1))
        
        res.data = events
        return res
      })
    },
    getUpcomingEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","endDateUtc":{"gt":"' + new Date() + '"},"isRecurring":false},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc ASC"}').then(res => {
        var events = res.data

        events.forEach(event => {
          if (event.medias) event.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getRecurringEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","or":[{"and":[{"recurrenceEndDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":true}]},{"and":[{"recurrenceEndDateUtc":{"neq":true}},{"isRecurring":true}]}]},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"recurrencePattern"],"order":"startDateUtc ASC"}').then(res => {
        var events = res.data

        events.forEach(event => {
          if (event.medias) event.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        var eventsToRemove = events.filter(event => event.recurrenceEndDateUtc && new Date() >= new Date(event.recurrenceEndDateUtc))
        eventsToRemove.forEach(eventToRemove => events.splice(events.findIndex(event => event.recurrenceEndDateUtc === eventToRemove.recurrenceEndDateUtc), 1))
        
        res.data = events
        return res
      })
    },
    getPastEvents: function(profileId) {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + profileId + '","or":[{"and":[{"endDateUtc":{"lt":"' + new Date() + '"}},{"isRecurring":false}]},{"recurrenceEndDateUtc":{"lt":"' + new Date() + '"}}]},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"recurrencePattern"],"order":"startDateUtc DESC"}').then(res => {
        var events = res.data

        events.forEach(event => {
          if (event.medias) event.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getEvent: function(profileId, slug) {
      return $http.get('/api-prema2/events/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"recurrencePattern","eventTicketTypes","eventPassTypes","worker","eventCategories"]}').then(res => {
        var event = res.data

        if (event.medias) event.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getEventById: function(eventId) {
      return $http.get('/api-prema2/events/' + eventId)
    },
    initEvent: function() {
      var event = {
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return event
    },
    postEvent: function(event) {
      event.slug = AppService.toDateSlug(event.startDateUtc, event.event)
      event.duration = calcDuration(event.startDateUtc, event.endDateUtc)
      event.createdAt = new Date()
      event.updatedAt = new Date()
      
      return $http.post('/api-prema2/events', event, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var event2 = res.data

        if (event.eventTicketTypes) {
          event.eventTicketTypes.forEach(eventTicketType => {
            eventTicketType.eventId = event2.id

            return $http.post('/api-prema2/eventTicketTypes', eventTicketType, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        if (event.eventPassTypes) {
          event.eventPassTypes.forEach(eventPassType => {
            eventPassType.eventId = event2.id

            return $http.post('/api-prema2/eventPassTypes', eventPassType, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        if (event.medias) {
          event.medias.forEach(media => {
            var eventToMedia = {
              order: media.order,
              eventId: event2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/eventToMedias', eventToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: event2.id.toString(),
          createdAt: event2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentEvent,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (event.eventCategories) {
            event.eventCategories.forEach(eventCategory => {
              var eventCategoryToEvent = {
                eventCategoryId: eventCategory.id,
                eventId: event2.id
              }

              return $http.post('/api-prema2/eventCategoryToEvents', eventCategoryToEvent, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
          }
        })
      })
    },
    putEvent: function(event) {
      event.slug = AppService.toDateSlug(event.startDateUtc, event.event)
      event.duration = calcDuration(event.startDateUtc, event.endDateUtc)
      event.updatedAt = new Date()
      event.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/eventTicketTypes?filter[where][eventId]=' + event.id).then(res => {
        var eventTicketTypes = res.data

        var diffs = [].concat(event.eventTicketTypes.filter(obj1 => eventTicketTypes.every(obj2 => obj1.id !== obj2.id)), eventTicketTypes.filter(obj2 => event.eventTicketTypes.every(obj1 => obj2.id !== obj1.id)))
        var added = diffs.filter(diff => diff.type)
        var removed = diffs.filter(diff => diff.id)
        if (added.length > 0) added.forEach(eventTicketType => {
          eventTicketType.eventId = event.id

          $http.post('/api-prema2/eventTicketTypes', eventTicketType, { headers: { 'Authorization': localStorage.getItem('token') } })
        })
        if (removed.length > 0) removed.forEach(eventTicketType => {
          $http.delete('/api-prema2/eventTicketTypes/' + eventTicketType.id, { headers: { 'Authorization': localStorage.getItem('token') } })
        })

        return $http.get('/api-prema2/eventPassTypes?filter[where][eventId]=' + event.id).then(res => {
          var eventPassTypes = res.data
  
          var diffs = [].concat(event.eventPassTypes.filter(obj1 => eventPassTypes.every(obj2 => obj1.id !== obj2.id)), eventPassTypes.filter(obj2 => event.eventPassTypes.every(obj1 => obj2.id !== obj1.id)))
          var added = diffs.filter(diff => diff.type)
          var removed = diffs.filter(diff => diff.id)
          if (added.length > 0) added.forEach(eventPassType => {
            eventPassType.eventId = event.id
  
            $http.post('/api-prema2/eventPassTypes', eventPassType, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
          if (removed.length > 0) removed.forEach(eventPassType => {
            $http.delete('/api-prema2/eventPassTypes/' + eventPassType.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          })

          return $http.get('/api-prema2/eventToMedias?filter[where][eventId]=' + event.id).then(res => {
            var eventToMedias = res.data

            var diffs = [].concat(event.medias.filter(obj1 => eventToMedias.every(obj2 => obj1.id !== obj2.id)), eventToMedias.filter(obj2 => event.medias.every(obj1 => obj2.id !== obj1.id)))
            if (diffs.length > 0) diffs.forEach(media => {
              if (!eventToMedias.includes(media)) {
                var eventToMedia = {
                  order: media.order,
                  eventId: event.id,
                  mediaId: media.mediaId,
                  mediaPlacementId: media.mediaPlacementId
                }
      
                return $http.post('/api-prema2/eventToMedias', eventToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
              } else {
                return $http.delete('/api-prema2/eventToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
              }
            })

            return $http.get('/api-prema2/eventCategoryToEvents?filter[where][eventId]=' + event.id).then(res => {
              var eventCategoryToEvents = res.data

              var diffs = [].concat(event.eventCategories.filter(obj1 => eventCategoryToEvents.every(obj2 => obj1.id !== obj2.eventCategoryId)), eventCategoryToEvents.filter(obj2 => event.eventCategories.every(obj1 => obj2.eventCategoryId !== obj1.id)))
              var added = diffs.filter(diff => diff.category)
              var removed = diffs.filter(diff => diff.eventCategoryId)
              if (added.length > 0) added.forEach(eventCategory => {
                var eventCategoryToEvent = {
                  eventCategoryId: eventCategory.id,
                  eventId: event.id
                }

                $http.post('/api-prema2/eventCategoryToEvents', eventCategoryToEvent, { headers: { 'Authorization': localStorage.getItem('token') } })
              })
              if (removed.length > 0) removed.forEach(eventCategoryToEvent => {
                $http.delete('/api-prema2/eventCategoryToEvents/' + eventCategoryToEvent.id, { headers: { 'Authorization': localStorage.getItem('token') } })
              })

              return $http.put('/api-prema2/events/' + event.id, event, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
                var event2 = res.data
        
                var auditLog = {
                  objectId: event2.id.toString(),
                  createdAt: event2.updatedAt,
                  profileId: sessionStorage.getItem('profileId'),
                  createdById: event2.updatedById,
                  componentId: AppService.componentEvent,
                  logTypeId: AppService.logTypeUpdate
                }
        
                return AppService.postAuditLog(auditLog)
              })
            })
          })
        })
      })
    },
    deleteEvent: function(event) {
      return new Promise((resolve, reject) => {
        if (event.eventCategories.length) {
          event.eventCategories.forEach(eventCategory => $http.get('/api-prema2/eventCategoryToEvents/findOne?filter[where][eventCategoryId]=' + eventCategory.id + '&filter[where][eventId]=' + event.id).then(res => {
            var eventCategoryToEvent = res.data

            $http.delete('/api-prema2/eventCategoryToEvents/' + eventCategoryToEvent.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => resolve())
          }))
        } else {
          resolve()
        }
      }).then(() => {
        return $http.delete('/api-prema2/events/' + event.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    },
    getEventResponseTypes: function() {
      return $http.get('/api-prema2/eventResponseTypes', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    postEventResponse: function(eventResponse, contactId) {
      return $http.get('/api-prema2/eventResponses/count?filter[where][contactId]=' + contactId).then(res => {
        var eventResponses = res.data

        eventResponse.eventResponseNo = eventResponses.count + 1
        eventResponse.createdAt = new Date()
        eventResponse.updatedAt = new Date()
        eventResponse.createdById = localStorage.getItem('userId')
        eventResponse.updatedById = localStorage.getItem('userId')
        eventResponse.contactId = contactId

        return $http.post('/api-prema2/eventResponses', eventResponse)
      })
    },
    postEventGuest: function(eventGuest, isPrimary, event, contact) {
      return $http.get('/api-prema2/eventGuests/count?where[eventId]=' + event.id).then(res => {
        var eventGuests = res.data

        eventGuest.ticketNo = eventGuests.count + 1
        eventGuest.isPrimary = isPrimary
        eventGuest.createdAt = new Date()
        eventGuest.updatedAt = new Date()
        eventGuest.profileId = sessionStorage.getItem('profileId')
        eventGuest.createdById = localStorage.getItem('userId')
        eventGuest.updatedById = localStorage.getItem('userId')
        eventGuest.eventId = event.id

        var promise = new Promise((resolve, reject) => {
          if (contact.id) {
            eventGuest.contactId = contact.id
            resolve()
          } else {
            return $http.get('/api-prema2/contactTypes').then(res => {
              var contactTypes = res.data
      
              var data = {
                firstName: AppService.toTitleCase(contact.firstName),
                lastName: contact.lastName ? AppService.toTitleCase(contact.lastName) : undefined,
                displayName: AppService.toTitleCase(contact.firstName),
                profileId: sessionStorage.getItem('profileId'),
                contactTypeId: contactTypes[2].id
              }
      
              if (contact.telephone || contact.email) data.communications = new Array()
              
              if (contact.email) data.communications.push({
                method: 'Email',
                value: contact.email,
                order: data.communications.length + 1,
                isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
              })
      
              if (contact.telephone) data.communications.push({
                method: 'Telephone',
                value: contact.telephone,
                order: data.communications.length + 1,
                isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
              })
      
              return $http.post('/api-prema2/contacts', data).then(res => {
                var contact = res.data
                
                eventGuest.contactId = contact.id
                resolve()
              })
            })
          }
        })
        
        return promise.then(() => $http.post('/api-prema2/eventGuests', eventGuest))
      })
    },
    getEventGuests: function() {
      return $http.get('/api-prema2/eventGuests?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":["contact","eventTicketType","paymentStatus","paymentMethod",{"relation":"referrer","scope":{"include":"contact"}},{"relation":"activities","scope":{"include":"activityType"}}]}')
    },
    getEventGuestsByEventId: function(eventId) {
      return $http.get('/api-prema2/eventGuests?filter={"where":{"eventId":"' + eventId + '"},"include":["createdBy","contact","eventTicketType","paymentStatus","paymentMethod",{"relation":"referrer","scope":{"include":"contact"}},{"relation":"activities","scope":{"include":"activityType"}}]}')
    },
    getEventGuestBySearch: function(search) {
      if (isNaN(search)) {
        var promise = new Promise((resolve, reject) => {
          $http.get('/api-prema2/eventGuests?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":["event","contact"]}').then(res => {
            var eventGuests = res.data

            resolve(eventGuests.find(eventGuest => eventGuest.contact.displayName.toLowerCase() == search.toLowerCase()))
          })
        })

        return promise
      } else {
        return $http.get('/api-prema2/eventGuests/findOne?filter={"where":{"referenceNo":"' + search + '"},"include":["event","contact"]}')
      }
    },
    patchEventGuest: function(key, value, eventGuestId) {
      var data = {
        [key]: value
      }

      return $http.patch('/api-prema2/eventGuests/' + eventGuestId, data)
    },
    getEventExhibitorsByEventId: function(eventId) {
      return $http.get('/api-prema2/eventExhibitors?filter={"where":{"eventId":"' + eventId + '"},"include":["contact","eventPassType","paymentStatus","paymentMethod",{"relation":"activities","scope":{"include":"activityType"}}]}')
    },
    patchEventExhibitor: function(key, value, eventExhibitorId) {
      var data = {
        [key]: value
      }

      return $http.patch('/api-prema2/eventExhibitors/' + eventExhibitorId, data)
    },
    getBookingStatuses: function() {
      return $http.get('/api-prema2/bookingStatuses')
    },
    getBookings: function() {
      return $http.get('/api-prema2/bookings?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"service","bookingStatus","recurrencePattern",{"relation":"activities","scope":{"include":"activityType"}}],"order":"createdAt DESC"}')
    },
    getBooking: function(referenceNo) {
      return $http.get('/api-prema2/bookings/findOne?filter={"where":{"referenceNo":"' + referenceNo + '","profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":"contactType"}},"bookingStatus","bookingLineItems",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}')
    },
    getBookingById: function(bookingId) {
      return $http.get('/api-prema2/bookings/' + bookingId + '?filter[include]=contact')
    },
    putBooking: function(booking) {
      booking.updatedAt = new Date()
      booking.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/bookingLineItems?filter[where][bookingId]=' + booking.id).then(res => {
        var bookingLineItems = res.data

        var diffs = [].concat(booking.bookingLineItems.filter(obj1 => bookingLineItems.every(obj2 => obj1.subscription !== obj2.subscription)), bookingLineItems.filter(obj2 => booking.bookingLineItems.every(obj1 => obj2.subscription !== obj1.subscription)))
        if (diffs.length > 0) diffs.forEach(bookingLineItem => {
          if (!bookingLineItems.includes(bookingLineItem)) {
            var bookingLineItem = {
              product: {
                productNo: bookingLineItem.product.productNo ? bookingLineItem.product.productNo : undefined,
                product: bookingLineItem.product.product,
                unitSalePrice: bookingLineItem.product.unitSalePrice,
                id: bookingLineItem.product.id
              },
              count: bookingLineItem.count,
              totalValue: bookingLineItem.totalValue,
              bookingId: booking.id
            }
  
            $http.post('/api-prema2/bookingLineItems', bookingLineItem, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            $http.delete('/api-prema2/bookingLineItems/' + bookingLineItem.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        if (booking.contactId) {
          $http.get('/api-prema2/contacts/findOne?filter[where][id]=' + booking.contactId).then(res => {
            var contact = res.data
    
            if (!angular.equals(booking.contact, contact)) $http.put('/api-prema2/contacts/' + contact.id, booking.contact)
          })
        }
  
        return $http.put('/api-prema2/bookings/' + booking.id, booking, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var booking2 = res.data
  
          var auditLog = {
            objectId: booking2.id.toString(),
            createdAt: booking2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: booking2.updatedById,
            componentId: AppService.componentBooking,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    patchBooking: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/bookings/' + id, data).then(res => {
        var booking = res.data

        var auditLog = {
          objectId: booking.id.toString(),
          createdAt: booking.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentBooking,
          logTypeId: key === 'isRead' ? AppService.logTypeRead : AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    }
  }
}