routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.subscriptions', {
      url: '/subscriptions?page',
      template: require('./subscriptions.html'),
      controller: 'SubscriptionsController',
      controllerAs: 'subscriptions',
      resolve: {
        $title: function() {
          return 'Subscriptions'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.subscription', {
      url: '/subscriptions/:slug',
      template: require('./subscription.html'),
      controller: 'SubscriptionController',
      controllerAs: 'subscription',
      resolve: {
        subscription: ['SubscriptionService', '$stateParams', function(SubscriptionService, $stateParams) {
          return SubscriptionService.getSubscription(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['subscription', function(subscription) {
          return subscription.data.subscription
        }]
      }
    })
    .state('shell.newSubscription', {
      url: '/subscriptions/new',
      template: require('./new-subscription.html'),
      controller: 'NewSubscriptionController',
      controllerAs: 'newSubscription',
      resolve: {
        $title: function() {
          return 'New Subscription'
        }
      }
    })
}