tasksSlideout.$inject = ['AppService']

export default function tasksSlideout(AppService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      component: '='
    },
    link: function(scope, elem, attrs) {
      function getProfileTasks(component) {
        AppService.getProfileTasks(sessionStorage.getItem('profileId'), component.id).then(res => {
          scope.profileToTasks = res.data
          copy = angular.copy(scope.profileToTasks)
        })
      }

      var copy
      AppService.getComponentByName(scope.component).then(res => {
        scope.component = res.data
        getProfileTasks(scope.component)
      })

      scope.toggleManageTasks = function() {
        var profileToTasks = JSON.parse(angular.toJson(scope.profileToTasks))

        new Promise((resolve, reject) => {
          profileToTasks.forEach(profileToTask => {
            var index = copy.findIndex(copy => copy.id === profileToTask.id)
            if (index === -1) {
              AppService.postProfileTask(profileToTask.task, profileToTask).then(() => resolve())
            } else {
              var copyProfileToTask = copy.find(copy => copy.id === profileToTask.id)

              if (!angular.equals(profileToTask, copyProfileToTask)) {
                AppService.putProfileTask(profileToTask.task, profileToTask).then(() => resolve())
              }
            }
          })
        }).then(() => getProfileTasks())

        scope.slideout = false
      }
      scope.addProfileTask = function() {
        scope.profileToTasks.push(AppService.initProfileTask(scope.profileToTasks, scope.component))
      }
      scope.deleteProfileTask = function(profileToTask) {
        AppService.deleteProfileTask(profileToTask).then(() => getProfileTasks())
      }
    },
    template: require('./_slideout-tasks.html')
  }

  return directive
}