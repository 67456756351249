EventController.$inject = ['$rootScope', 'EventService', '$stateParams', 'AppService', 'CorporateService', '$uibModal', '$state', '$transitions', '$scope', '$window', 'AuthService']

export default function EventController($rootScope, EventService, $stateParams, AppService, CorporateService, $uibModal, $state, $transitions, $scope, $window, AuthService) {
  var vm = this

  function saveSelection() {
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        var ranges = []
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
        return ranges
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange()
    }
    return null
  }

  var copy
  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      EventService.getEvent(profileId, $stateParams.slug).then(res => {
        vm.event = res.data

        EventService.getEventGuestsByEventId(vm.event.id).then(res => {
          vm.eventGuests = res.data

          vm.eventGuestsNotInclGuestlist = vm.eventGuests.filter(eventGuest => eventGuest.eventTicketType.type !== 'Guestlist')

          var eventGuestsTicketTypesUnitSalePrices = vm.eventGuestsNotInclGuestlist.map(eventGuest => eventGuest.eventTicketType.unitSalePrice)
          vm.eventGuestsTicketTypesTotalUnitSalePrice = eventGuestsTicketTypesUnitSalePrices.reduce((a, c) => a + c, 0)

          var timeOptions = {
            weekday: 'short',
            day: 'numeric',
            hour12: false
          }

          vm.eventGuestsNotInclGuestlist.forEach(eventGuest => eventGuest.createdAtDateOnly = new Date(new Date(eventGuest.createdAt).getUTCFullYear(), new Date(eventGuest.createdAt).getUTCMonth(), new Date(eventGuest.createdAt).getUTCDate()))

          var dates = vm.eventGuestsNotInclGuestlist.map(eventGuest => eventGuest.createdAtDateOnly).reduce((a, c) => {
            !a.hash[c] && a.result.push(c)
            a.hash[c] = true
            return a
          }, {result: [], hash: {}})
          var dateLocales = new Array()
          var eventGuestsCountByDate = new Array()
          dates.result.forEach(date => {
            dateLocales.push(date.toLocaleDateString('en-CY', timeOptions))

            eventGuestsCountByDate.push(vm.eventGuestsNotInclGuestlist.reduce((a, c) => c.createdAtDateOnly.toISOString() === date.toISOString() ? ++a : a, 0))
          })

          var d = new Date().toLocaleDateString('en-CY', timeOptions)
          vm.eventGuestsCreatedAtToday = eventGuestsCountByDate.length > 0 && dateLocales[dateLocales.length - 1] === d ? eventGuestsCountByDate[eventGuestsCountByDate.length - 1] : 0

          vm.eventGuestsChart = {
            labels: dateLocales,
            series: [
              eventGuestsCountByDate
            ]
          }

          function getPaymentById(eventGuest) {
            var payment = eventGuest.activities.find(activity => activity.activityType.type === 'Payment')
            if (payment) AppService.getPaymentById(payment.objectId).then(res => eventGuest.paymentMostRecent = res.data)
          }

          vm.eventGuestsNotInclGuestlist.forEach(eventGuest => {
            if (!eventGuest.isPrimary) {
              var eventGuestPrimary = vm.eventGuestsNotInclGuestlist.find(eventGuest2 => eventGuest2.referenceNo === eventGuest.referenceNo && eventGuest2.isPrimary)
              eventGuest.activities = eventGuestPrimary.activities
            }

            getPaymentById(eventGuest) ? eventGuest.activities.length > 0 : null
          })
        })

        EventService.getEventExhibitorsByEventId(vm.event.id).then(res => {
          vm.eventExhibitors = res.data

          function getPaymentById(eventExhibitor) {
            var payment = eventExhibitor.activities.find(activity => activity.activityType.type === 'Payment')
            if (payment) AppService.getPaymentById(payment.objectId).then(res => eventExhibitor.paymentMostRecent = res.data)
          }

          vm.eventExhibitors.forEach(eventExhibitor => {
            if (!eventExhibitor.isPrimary) {
              var eventExhibitorPrimary = vm.eventExhibitors.find(eventExhibitor2 => eventExhibitor2.referenceNo === eventExhibitor.referenceNo && eventExhibitor2.isPrimary)
              eventExhibitor.activities = eventExhibitorPrimary.activities
            }

            getPaymentById(eventExhibitor) ? eventExhibitor.activities.length > 0 : null
          })
        })

        copy = angular.copy(vm.event)
        vm.event.startDateUtc = new Date(vm.event.startDateUtc)
        vm.event.endDateUtc = new Date(vm.event.endDateUtc)
        if (vm.event.recurrenceEndDateUtc) {
          vm.event.recurrenceEndDateUtc = new Date(vm.event.recurrenceEndDateUtc)
          vm.event.recurrenceEndDateUtc.setUTCDate(vm.event.recurrenceEndDateUtc.getUTCDate() - 1)
        }
        if (vm.event.eventTicketTypes.length > 0) {
          vm.event.eventTicketTypes.forEach(eventTicketType => {
            if (eventTicketType.endDate) eventTicketType.endDate = new Date(eventTicketType.endDate)
          })
        }
        copy.startDateUtc = new Date(copy.startDateUtc)
        copy.endDateUtc = new Date(copy.endDateUtc)
        if (copy.recurrenceEndDateUtc) {
          copy.recurrenceEndDateUtc = new Date(copy.recurrenceEndDateUtc)
          copy.recurrenceEndDateUtc.setUTCDate(copy.recurrenceEndDateUtc.getUTCDate() - 1)
        }
        if (copy.eventTicketTypes.length > 0) {
          copy.eventTicketTypes.forEach(eventTicketType => {
            if (eventTicketType.endDate) eventTicketType.endDate = new Date(eventTicketType.endDate)
          })
        }
      })
    }
  })

  AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => vm.profilePaymentMethods = res.data)
  CorporateService.getWorkers(sessionStorage.getItem('profileId')).then(res => {
    var workers = res.data

    vm.referrers = workers.filter(worker => worker.workerRoles.some(workerRole => workerRole.role === 'Referrer')).sort((a, b) => a.contact.firstName.localeCompare(b.contact.firstName))
  })
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)
  vm.search = $stateParams.search ? $stateParams.search : undefined

  // Charts
  vm.chartOptions = {
    fullWidth: true,
    chartPadding: {
      right: 40
    }
  }

  vm.insertLink = function() {
    AppService.savedSel = saveSelection()

    $uibModal.open({
      controller: 'ModalLinkController',
      controllerAs: 'modalLink',
      template: require('../../app/link-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.toggleAddGuest = function() {
    vm.addGuest = !vm.addGuest
  }
  vm.copyEventGuestEmails = function() {
    var body = angular.element($window.document.body)
    var textarea = angular.element('<textarea/>')
    textarea.css({
      position: 'fixed',
      opacity: '0'
    })
    
    function copy(toCopy) {
      textarea.val(toCopy)
      body.append(textarea)
      textarea[0].select()
  
      var successful = document.execCommand('copy')
      if (!successful) {
        throw successful
      }
      textarea.remove()
    }
    
    var eventGuestContactEmails = vm.eventGuests.map(eventGuest => eventGuest.contact && eventGuest.contact.communications ? eventGuest.contact.communications.map(communication => communication.method === 'Email' ? communication.value : undefined).filter(el => el !== undefined) : undefined).filter(el => el !== undefined).filter(el => el.length).flat()

    var eventGuestCreatedByEmails = [...new Set(vm.eventGuests.map(eventGuest => eventGuest.createdBy.email.toLowerCase()))]

    CorporateService.getTeam(sessionStorage.getItem('profileId')).then(res => {
      var team = res.data

      var teamEmails = new Array()
      var counter = 0
      var promise = new Promise((resolve, reject) => team.filter(worker => worker.isSysUser).forEach(worker => AuthService.getSysUserById(worker.sysUserId).then(res => {
        var sysUser = res.data
        
        teamEmails.push(sysUser.email)
        counter++
        if (counter == team.filter(worker => worker.isSysUser).length) resolve()
      })))
      
      promise.then(() => {
        var eventGuestEmails = [...new Set([...eventGuestContactEmails, ...eventGuestCreatedByEmails])].filter(el => !teamEmails.includes(el))
        eventGuestEmails.length > 0 ? copy(eventGuestEmails.join(';')) : copy(';')
      })
    })
  }
  vm.setPromoted = function() {
    vm.event.isPromoted = !vm.event.isPromoted
  }
  vm.setPublished = function() {
    vm.event.isPublished = !vm.event.isPublished
  }
  vm.delete = function() {
    vm.confirmDelete = true
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    EventService.deleteEvent(vm.event).then(() => $state.go('shell.events'))
  }
  vm.tabs = [0, 1, 2, 3]
  vm.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
  vm.setEventGuestTicketType = function(eventTicketTypeId, eventGuestId) {
    vm.eventGuests.find(eventGuest => eventGuest.id === eventGuestId).eventTicketType = vm.event.eventTicketTypes.find(eventTicketType => eventTicketType.id === eventTicketTypeId)
    EventService.patchEventGuest('eventTicketTypeId', eventTicketTypeId, eventGuestId)
  }
  vm.setEventGuestPaymentMethod = function(paymentMethodId, eventGuestId, paymentId) {
    !paymentId ? EventService.patchEventGuest('paymentMethodId', paymentMethodId, eventGuestId) : AppService.patchPayment('paymentMethodId', paymentMethodId, paymentId)
  }
  vm.setEventGuestPaymentStatus = function(paymentStatusId, eventGuestId, paymentId) {
    !paymentId ? EventService.patchEventGuest('paymentStatusId', paymentStatusId, eventGuestId) : AppService.patchPayment('paymentStatusId', paymentStatusId, paymentId)
  }
  vm.setEventExhibitorPassType = function(eventPassTypeId, eventExhibitorId) {
    vm.eventExhibitors.find(eventExhibitor => eventExhibitor.id === eventExhibitorId).eventPassType = vm.event.eventPassTypes.find(eventPassType => eventPassType.id === eventPassTypeId)
    EventService.patchEventExhibitor('eventPassTypeId', eventPassTypeId, eventExhibitorId)
  }
  vm.setEventExhibitorPaymentMethod = function(paymentMethodId, eventExhibitorId, paymentId) {
    !paymentId ? EventService.patchEventExhibitor('paymentMethodId', paymentMethodId, eventExhibitorId) : AppService.patchPayment('paymentMethodId', paymentMethodId, paymentId)
  }
  vm.setEventExhibitorPaymentStatus = function(paymentStatusId, eventExhibitorId, paymentId) {
    !paymentId ? EventService.patchEventExhibitor('paymentStatusId', paymentStatusId, eventExhibitorId) : AppService.patchPayment('paymentStatusId', paymentStatusId, paymentId)
  }
  $transitions.onBefore({}, (transition) => {
    if ($scope.$parent.shell.search && transition.to().name !== 'shell.event') $scope.$parent.shell.search = ''
    if (vm.event.eventTicketTypes) vm.event.eventTicketTypes.forEach(eventTicketType => delete eventTicketType.typeUnitSalePriceStr)
    if (!angular.equals(vm.event, copy)) return EventService.putEvent(vm.event)
  }, {invokeLimit: 1})
}