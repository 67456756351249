registerForm.$inject = ['$window', 'AuthService', '$timeout', '$state']

export default function registerForm($window, AuthService, $timeout, $state) {
  var body = angular.element($window.document.body)
  var textarea = angular.element('<textarea/>')
  textarea.css({
    position: 'fixed',
    opacity: '0'
  })

  function copy(toCopy) {
    textarea.val(toCopy)
    body.append(textarea)
    textarea[0].select()

    var successful = document.execCommand('copy')
    if (!successful) {
      throw successful
    }
    textarea.remove()
  }

  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      AuthService.getBusinessSectors().then(res => scope.businessSectors = res.data)
      scope.password = AuthService.generatePassword()
      scope.rememberMe = true

      scope.generatePassword = function() {
        scope.password = AuthService.generatePassword()
      }
      scope.copyPassword = function() {
        copy(scope.password)
      }
      scope.doRegister = function() {
        scope.submitted = true

        AuthService.getSysUserByEmail(scope.email).then(() => {
          scope.auth.existingUser = true
          $timeout(function() {
            scope.auth.hideAlert('existingUser')
            $state.go('login')
          }, 5000)
        }, () => {
          AuthService.registerBusiness(scope.firstName, scope.lastName, scope.businessName, scope.businessSectorId, scope.email, scope.password).then(() => {
            AuthService.login(scope.email, scope.password).then(() => {
              $state.go('shell.home')
            })
          })
        })
      }
      scope.dismissModal = function() {
        scope.$dismiss()
      }
    },
    template: require('./_form-register.html')
  }

  return directive
}