import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './customer.routes'
import CustomerService from './customer.service'
import CustomerCard from './customer-card.directive'
import CustomerFormDirective from './customer-form.directive'
import CustomersController from './customers.controller'
import CustomerController from './customer.controller'
import NewCustomerController from './new-customer.controller'
import './customer.less'

export default angular.module('app.customer', [uirouter, CustomerCard])
  .config(routing)
  .service('CustomerService', CustomerService)
  .directive('customerForm', CustomerFormDirective)
  .controller('CustomersController', CustomersController)
  .controller('CustomerController', CustomerController)
  .controller('NewCustomerController', NewCustomerController)
  .name