routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.home', {
      url: '/',
      template: require('./home.html'),
      controller: 'HomeController',
      controllerAs: 'home',
      resolve: {
        $title: function() {
          return 'Dashboard'
        }
      }
    })
}