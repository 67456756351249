CustomersController.$inject = ['$rootScope', 'CustomerService', '$stateParams', 'angularGridInstance', 'AppService', '$timeout', '$state']

export default function CustomersController($rootScope, CustomerService, $stateParams, angularGridInstance, AppService, $timeout, $state) {
  var vm = this

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      CustomerService.getCustomers(profile.id).then(res => vm.customers = res.data)
      vm.settings = profile.settings.components.find(component => component.name === 'customers')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components, $rootScope.profile.settings.id)
  }
  vm.toggleManageQuestionnaire = function() {
    vm.manageQuestionnaire = !vm.manageQuestionnaire
  }
  vm.toggleManageTasks = function() {
    vm.manageTasks = !vm.manageTasks
  }
  vm.setSortBy = function(property) {
    var copy = vm.customers
    vm.customers = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    function compareNested(a, b) {
      let comparison = 0
      while (comparison < property.length) { a = a[property[comparison]]; b = b[property[comparison]]; comparison++; }
      if (a > b) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a < b) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    if (property.includes('.')) {
      property = property.split('.')
      copy.sort(compareNested)
    } else {
      copy.sort(compare)
    }
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.customers = copy
    }, 100)
  }
  vm.numberOfPages = function() {
    return parseInt(vm.customers.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}