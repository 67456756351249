routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.services', {
      url: '/services',
      template: require('./services.html'),
      controller: 'ServicesController',
      controllerAs: 'services',
      resolve: {
        $title: function() {
          return 'Services'
        }
      }
    })
    .state('shell.service', {
      url: '/services/:slug',
      template: require('./service.html'),
      controller: 'ServiceController',
      controllerAs: 'service',
      resolve: {
        service: ['ServiceService', '$stateParams', function(ServiceService, $stateParams) {
          return ServiceService.getService(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['service', function(service) {
          return service.data.service
        }]
      }
    })
    .state('shell.newService', {
      url: '/services/new',
      template: require('./new-service.html'),
      controller: 'NewServiceController',
      controllerAs: 'newService',
      resolve: {
        $title: function() {
          return 'New Service'
        }
      }
    })
}