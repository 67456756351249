import angular from 'angular'

contractForm.$inject = ['AppService', '$timeout', '$state']

function contractForm(AppService, $timeout, $state) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.contract = new Object()

      scope.startDate = false
      scope.dateOptions = {
        startingDay: 1
      }
      scope.timeOptions = {
        showMeridian: false,
        minuteStep: 15
      }

      scope.toggleStartDate = function() {
        scope.startDate = !scope.startDate
      }
      scope.createContract = function() {
        scope.submitted = true

        AppService.postContract(scope.contract).then(res => {
          var contract = res.data
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          shell.postedContract = true
          $state.go('shell.contract', { referenceNo: contract.referenceNo })
          $timeout(function() {
            shell.hideAlert('postedContract')
          }, 5000)
        })
      }
    },
    template: require('./_form-contract.html')
  }
}

export default angular.module('directives.contractForm', [])
  .directive('contractForm', contractForm)
  .name