eventCategoriesSlideout.$inject = ['EventService']

export default function eventCategoriesSlideout(EventService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getEventCategories() {
        EventService.getEventCategories(sessionStorage.getItem('profileId')).then(res => scope.eventCategories = res.data)
      }

      getEventCategories()

      scope.toggleManageCategories = function() {
        new Promise((resolve, reject) => {
          scope.eventCategories.forEach(eventCategory => EventService.putEventCategory(eventCategory).then(() => resolve()))
        }).then(() => getEventCategories())
        
        scope.slideout = false
      }
      scope.addCategory = function() {
        scope.eventCategories.push({
          order: scope.eventCategories.length + 1
        })
      }
      scope.deleteCategory = function(eventCategory) {
        EventService.deleteEventCategory(eventCategory).then(() => getEventCategories())
      }
    },
    template: require('./_slideout-categories.html')
  }

  return directive
}