import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './subscription.routes'
import SubscriptionService from './subscription.service'
import SubscriptionCard from './subscription-card.directive'
import SubscriptionFormDirective from './subscription-form.directive'
import SubscriptionsController from './subscriptions.controller'
import SubscriptionController from './subscription.controller'
import NewSubscriptionController from './new-subscription.controller'
import './subscription.less'

export default angular.module('app.subscription', [uirouter, SubscriptionCard])
  .config(routing)
  .service('SubscriptionService', SubscriptionService)
  .directive('subscriptionForm', SubscriptionFormDirective)
  .controller('SubscriptionsController', SubscriptionsController)
  .controller('SubscriptionController', SubscriptionController)
  .controller('NewSubscriptionController', NewSubscriptionController)
  .name