leadSourcesSlideout.$inject = ['$rootScope', 'AppService']

export default function leadSourcesSlideout($rootScope, AppService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getLeadSources() {
        $rootScope.$watch('profile.id', profileId => {
          if (profileId !== undefined) AppService.getLeadSources(profileId).then(res => scope.leadSources = res.data)
        })
      }

      getLeadSources()

      scope.toggleManageLeadSources = function() {
        new Promise((resolve, reject) => {
          scope.leadSources.forEach(leadSource => AppService.putLeadSource(leadSource).then(() => resolve()))
        }).then(() => getLeadSources())

        scope.slideout = false
      }
      scope.addLeadSource = function() {
        scope.leadSources.push({
          order: scope.leadSources.length + 1
        })
      }
      scope.deleteLeadSource = function(leadSource) {
        AppService.deleteLeadSource(leadSource).then(() => getLeadSources())
      }
    },
    template: require('./_slideout-lead-sources.html')
  }

  return directive
}