import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './supplier.routes'
import SupplierService from './supplier.service'
import SupplierCard from './supplier-card.directive'
import SupplierFormDirective from './supplier-form.directive'
import SuppliersController from './suppliers.controller'
import SupplierController from './supplier.controller'
import NewSupplierController from './new-supplier.controller'
import './supplier.less'

export default angular.module('app.supplier', [uirouter, SupplierCard])
  .config(routing)
  .service('SupplierService', SupplierService)
  .directive('supplierForm', SupplierFormDirective)
  .controller('SuppliersController', SuppliersController)
  .controller('SupplierController', SupplierController)
  .controller('NewSupplierController', NewSupplierController)
  .name