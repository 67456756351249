CustomerService.$inject = ['$http', 'AppService']

export default function CustomerService($http, AppService) {
  return {
    getCustomers: function(profileId) {
      return $http.get('/api-prema2/customers?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"leadSource"],"order":"createdAt DESC"}', { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var customers = res.data

        customers.forEach(customer => {
          if (customer.contact.medias) customer.contact.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getCustomer: function(profileId, slug) {
      return $http.get('/api-prema2/customers/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"leadSource"]}', { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var customer = res.data

        if (customer.contact.medias) customer.contact.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getCustomerById: function(customerId) {
      return $http.get('/api-prema2/customers/' + customerId + '?filter={"include":[{"relation":"contact","scope":{"include":[{"relation":"locations","scope":{"include":"country"}},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}},"leadSource"]}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    initCustomer: function() {
      var customer = {
        startDate: new Date(),
        isActive: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      $http.get('/api-prema2/customers/count?where[profileId]=' + sessionStorage.getItem('profileId'), { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var customers = res.data
        customer.customerNo = customers.count + 1
      })

      return customer
    },
    postCustomer: function(customer) {
      customer.contact.firstName = customer.contact.displayName
      customer.contact.profileId = sessionStorage.getItem('profileId')
      customer.contact.contactTypeId = '590b41ee2dc5a21c38dcda2c'
      
      return $http.post('/api-prema2/contacts', customer.contact, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var contact = res.data

        if (customer.contact.medias) {
          customer.contact.medias.forEach(media => {
            var contactToMedia = {
              order: media.order,
              contactId: contact.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/contactToMedias', contactToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var name = contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName
        customer.slug = AppService.toSlug(name)
        customer.startDate = new Date(customer.startDate).setUTCHours(0, 0, 0, 0)
        customer.createdAt = new Date()
        customer.updatedAt = new Date()
        customer.contactId = contact.id
        
        return $http.post('/api-prema2/customers', customer, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var customer2 = res.data
  
          var auditLog = {
            objectId: customer2.id.toString(),
            createdAt: customer2.createdAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            componentId: AppService.componentWorker,
            logTypeId: AppService.logTypeCreate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    getBookings: function(contactId) {
      return $http.get('/api-prema2/bookings?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][contactId]=' + contactId + '&filter[include]=bookingStatus&filter[order]=createdAt%20DESC')
    },
    getUpcomingAndRecurringEventsInclGuests: function() {
      return $http.get('/api-prema2/events?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '","or":[{"and":[{"endDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":false}]},{"and":[{"recurrenceEndDateUtc":{"gt":"' + new Date() + '"}},{"isRecurring":true}]},{"and":[{"recurrenceEndDateUtc":{"neq":true}},{"isRecurring":true}]}]},"include":["eventGuests","recurrencePattern",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"startDateUtc ASC"}').then(res => {
        var events = res.data

        events.forEach(event => {
          if (event.medias) event.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getOrders: function(contactId) {
      return $http.get('/api-prema2/orders?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][contactId]=' + contactId + '&filter[include]=orderStatus&filter[order]=createdAt%20DESC')
    },
    getMemberships: function(contactId) {
      return $http.get('/api-prema2/memberships?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '","contactId":"' + contactId + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"membershipType","membershipStatus","recurrencePattern",{"relation":"activities","scope":{"include":"activityType"}}],"order":"createdAt DESC"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getStudies: function(contactId) {
      return $http.get('/api-prema2/projects?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][contactId]=' + contactId + '&filter[include]=projectStatus&filter[order]=createdAt%20DESC')
    },
    getPayments: function(contactId) {
      return $http.get('/api-prema2/payments?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[where][contactId]=' + contactId + '&filter[order]=createdAt%20DESC')
    },
    getProfileTasks: function(profileId, componentId) {
      return $http.get('/api-prema2/profileToTasks?filter[where][profileId]=' + profileId + '&filter[where][componentId]=' + componentId + '&filter[include]=task&filter[order]=order%20ASC')
    },
    getTaskResponses: function(contactId) {
      return $http.get('/api-prema2/taskResponses?filter[where][contactId]=' + contactId)
    },
    postTaskResponse: function(taskId, contactId) {
      return $http.get('/api-prema2/taskResponses/count?filter[where][contactId]=' + contactId).then(res => {
        var taskResponses = res.data

        var data = {
          taskResponseNo: taskResponses.count + 1,
          createdAt: new Date(),
          updatedAt: new Date(),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          taskId: taskId,
          contactId: contactId
        }

        return $http.post('/api-prema2/taskResponses', data)
      })
    },
    putCustomer: function(customer) {
      customer.contact.firstName = customer.contact.displayName
      
      return $http.get('/api-prema2/contactToMedias?filter[where][contactId]=' + customer.contact.id).then(res => {
        var contactToMedias = res.data

        var diffs = [].concat(customer.contact.medias.filter(obj1 => contactToMedias.every(obj2 => obj1.id !== obj2.id)), contactToMedias.filter(obj2 => customer.contact.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!contactToMedias.includes(media)) {
            var contactToMedia = {
              order: media.order,
              contactId: customer.contact.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/contactToMedias', contactToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/contactToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.put('/api-prema2/contacts/' + customer.contact.id, customer.contact, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var contact = res.data

          var name = contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName
          customer.slug = AppService.toSlug(name)
          customer.updatedAt = new Date()
          customer.updatedById = localStorage.getItem('userId')

          return $http.put('/api-prema2/customers/' + customer.id, customer, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var customer2 = res.data
    
            var auditLog = {
              objectId: customer2.id.toString(),
              createdAt: customer2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: customer2.updatedById,
              componentId: AppService.componentCustomer,
              logTypeId: AppService.logTypeUpdate
            }
    
            return AppService.postAuditLog(auditLog)
          })
        })
      })
    },
    deleteCustomer: function(customer) {
      return $http.delete('/api-prema2/customers/' + customer.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}