CheckoutController.$inject = ['$rootScope', 'OrderService', 'AuthService', '$state']

export default function CheckoutController($rootScope, OrderService, AuthService, $state) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      OrderService.getPaymentMethods(profileId).then(res => vm.paymentMethods = res.data)
    }
  })

  vm.checkoutStep = AuthService.isAuthenticated() ? 2 : 1
  vm.rememberMe = true
  if (AuthService.isAuthenticated()) AuthService.getCurrentUser().then(res => {
    vm.currentUser = res.data

    if (vm.currentUser.contact.contactType.type === 'Customer') {
      vm.hasLocation = vm.currentUser.contact.locations ? true : false
    } else if (vm.currentUser.contact.contactType.type === 'Owner' || vm.currentUser.contact.contactType.type === 'Employee') {
      vm.hasLocation = vm.currentUser.profile.location ? true : false
    }
    
    vm.isExistingBillingAddress = AuthService.isAuthenticated() && vm.hasLocation ? true : false
  })
  vm.isSameShippingAddress = true
  var basketItemsTotal = parseFloat(sessionStorage.getItem('basketItemsTotal'))
  var deliveryValue = parseFloat(sessionStorage.getItem('standardDelivery'))
  vm.newOrder = {
    grossValue: basketItemsTotal,
    deliveryValue: deliveryValue,
    netValue: basketItemsTotal + deliveryValue
  }

  vm.nextCheckoutStep = function() {
    vm.checkoutStep++
  }
  vm.registerAndNextCheckoutStep = function() {
    AuthService.register(vm.firstName, vm.lastName, vm.companyName, vm.email, vm.password).then(() => {
      if (!localStorage.getItem('token')) AuthService.login(vm.email, vm.password).then(() => $state.reload())
    })
  }
}