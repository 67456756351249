OrdersController.$inject = ['$rootScope', 'OrderService']

export default function OrdersController($rootScope, OrderService) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      OrderService.getOrders(profileId).then(res => vm.orders = res.data)
    }
  })

  vm.toggleManageDelivery = function() {
    vm.manageDelivery = !vm.manageDelivery
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
}