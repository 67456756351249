addEventResponseSlideout.$inject = ['CustomerService', 'EventService', 'moment']

export default function addEventResponseSlideout(CustomerService, EventService, moment) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      contactId: '='
    },
    link: function(scope, elem, attrs) {
      scope.$watch('contactId', contactId => {
        if (contactId) {
          EventService.getEventResponseTypes().then(res => scope.eventResponseTypes = res.data)
          CustomerService.getUpcomingAndRecurringEventsInclGuests(sessionStorage.getItem('profileId')).then(res => {
            var upcomingAndRecurringEventsInclGuests = res.data
            var upcomingAndRecurringEvents = upcomingAndRecurringEventsInclGuests.filter(event => event.eventGuests.some(eventGuest => eventGuest.contactId === scope.contactId))

            upcomingAndRecurringEvents.forEach(event => {
              var startDateUtc = moment.utc(event.startDateUtc)

              startDateUtc = !event.isAllDay ? moment.utc(event.startDateUtc).format('dddd DD MMMM YYYY, HH:mm') : moment.utc(event.startDateUtc).format('dddd DD MMMM YYYY')
              
              if (event.recurrencePatternId) {
                startDateUtc = event.recurrencePattern.pattern === 'Every day' ? 'Every day from ' + startDateUtc : event.recurrencePattern.pattern === 'Every week' ? 'Every week from ' + startDateUtc : event.recurrencePattern.pattern === 'Every two weeks' ? 'Every two weeks from ' + startDateUtc : event.recurrencePattern.pattern === 'Every month' ? 'Every month from ' + startDateUtc : event.recurrencePattern.pattern === 'Every year' ? 'Every year from ' + startDateUtc : null
              }

              event.startDateUtc = startDateUtc
            })
            scope.upcomingAndRecurringEvents = upcomingAndRecurringEvents
          })
        }
      })

      scope.setEvent = function(eventId) {
        scope.event = scope.upcomingAndRecurringEvents.find(event => event.id === eventId)
        
        scope.startDate = false
        scope.startDateOptions = {
          minDate: new Date(scope.event.startDateUtc),
          dateDisabled: function(data) {
            var date = data.date, mode = data.mode
            return mode === 'day' && (date.getDay() !== new Date(scope.event.startDateUtc).getDay())
          },
          startingDay: 1
        }
      }
      scope.toggleStartDate = function() {
        scope.startDate = !scope.startDate
      }
      scope.toggleAddEventResponse = function() {
        if (scope.eventResponse.occurenceDateUtc) scope.eventResponse.occurenceDateUtc.setUTCHours(new Date(scope.event.startDateUtc).getUTCHours()).setUTCMinutes(new Date(scope.event.startDateUtc).getUTCMinutes())
        scope.eventResponse.eventGuestId = scope.event.eventGuests.find(eventGuest => eventGuest.contactId === scope.contactId).id
        EventService.postEventResponse(scope.eventResponse, scope.contactId).then(() => {
          scope.event.recurrencePatternId = undefined
          delete scope.eventResponse
        })

        scope.slideout = false
      }
    },
    template: require('./_slideout-add-event-response.html')
  }

  return directive
}