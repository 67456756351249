PrivacyController.$inject = ['$rootScope', '$transitions', 'AppService']

export default function PrivacyController($rootScope, $transitions, AppService) {
  var vm = this
  
  var copy
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      copy = angular.copy(profile.settings.privacy)
    }
  })
  
  $transitions.onBefore({}, () => {
    if (!angular.equals($rootScope.profile.settings.privacy, copy)) return AppService.patchSettings('privacy', $rootScope.profile.settings.privacy, $rootScope.profile.settings.id)
  }, {invokeLimit: 1})
}