FaqsController.$inject = ['$rootScope', 'CorporateService', '$transitions']

export default function FaqsController($rootScope, CorporateService, $transitions) {
  var vm = this

  var copy
  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      CorporateService.getFaqs(profileId).then(res => {
        vm.faqs = res.data
        copy = angular.copy(vm.faqs)
      })
    }
  })

  vm.addFaq = function() {
    vm.faqs.push(CorporateService.initFaq(vm.faqs))
  }
  $transitions.onBefore({}, () => {
    if (!angular.equals(vm.faqs, copy)) {
      vm.faqs.forEach(faq => {
        if (faq.id) {
          var index = vm.faqs.findIndex(faq2 => faq2 === faq)
          if (!angular.equals(vm.faqs[index], copy[index])) CorporateService.putFaq(faq)
        } else {
          CorporateService.postFaq(faq)
        }
      })

      var diffs = [].concat(vm.faqs.filter(obj1 => copy.every(obj2 => obj1.id !== obj2.id)), copy.filter(obj2 => vm.faqs.every(obj1 => obj2.id !== obj1.id)))
      if (diffs.length > 0) diffs.forEach(faq => {
        if (!vm.faqs.includes(faq)) CorporateService.deleteFaq(faq)
      })
    }
  }, {invokeLimit: 1})
}