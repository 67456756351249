import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './membership.routes'
import MembershipService from './membership.service'
import MembershipCard from './membership-card.directive'
import MembershipsController from './memberships.controller'
import MembershipController from './membership.controller'
import './membership.less'

export default angular.module('app.membership', [uirouter, MembershipCard])
  .config(routing)
  .service('MembershipService', MembershipService)
  .controller('MembershipsController', MembershipsController)
  .controller('MembershipController', MembershipController)
  .name