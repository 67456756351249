suppliersSlideout.$inject = ['ProductService']

export default function suppliersSlideout(ProductService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getSuppliers(profileId) {
        ProductService.getSuppliers(profileId).then(res => {
          scope.suppliers = res.data
          copy = angular.copy(scope.suppliers)
        })
      }

      var copy
      getSuppliers(sessionStorage.getItem('profileId'))

      scope.toggleManageSuppliers = function() {
        var suppliers = JSON.parse(angular.toJson(scope.suppliers))

        new Promise((resolve, reject) => {
          suppliers.forEach(supplier => {
            var index = copy.findIndex(copy => copy.slug === supplier.slug)
            if (index === -1) {
              ProductService.postSupplier(supplier).then(() => resolve())
            } else {
              var index2 = copy.findIndex(copy => copy.businessName === supplier.businessName && copy.description === supplier.description)
              if (index2 === -1) {
                ProductService.putSupplier(supplier).then(() => resolve())
              }
            }
          })
        }).then(() => getSuppliers(sessionStorage.getItem('profileId')))

        scope.slideout = false
      }
      scope.addSupplier = function() {
        scope.suppliers.push(ProductService.initSupplier())
      }
      scope.deleteSupplier = function(supplier) {
        ProductService.deleteSupplier(supplier).then(() => getSuppliers(sessionStorage.getItem('profileId')))
      }
    },
    template: require('./_slideout-suppliers.html')
  }

  return directive
}