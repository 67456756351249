passwordForm.$inject = ['$window', 'AuthService', '$location', '$state', '$timeout']

export default function passwordForm($window, AuthService, $location, $state, $timeout) {
  var body = angular.element($window.document.body)
  var textarea = angular.element('<textarea/>')
  textarea.css({
    position: 'fixed',
    opacity: '0'
  })

  function copy(toCopy) {
    textarea.val(toCopy)
    body.append(textarea)
    textarea[0].select()

    var successful = document.execCommand('copy')
    if (!successful) {
      throw successful
    }
    textarea.remove()
  }

  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.changedPassword = false
      scope.password = AuthService.generatePassword()
      AuthService.getAccessTokenById(localStorage.getItem('token') ? localStorage.getItem('token') : $location.search().access_token).then(res => {
        scope.accessToken = res.data
      }, function() {
        $state.go('unauthorised')
      })

      scope.generatePassword = function() {
        scope.password = AuthService.generatePassword()
      }
      scope.copyPassword = function() {
        copy(scope.password)
      }

      scope.doChangePassword = function() {
        scope.submitted = true
        
        AuthService.changePassword(scope.password, scope.accessToken).then(() => {
          if (scope.$$topModalIndex === 0) scope.$dismiss()
          scope.changedPassword = true
          $timeout(function() {
            scope.hideAlert('changedPassword')
            $state.go('login')
          }, 5000)
        })
      }
      scope.dismissModal = function() {
        scope.$dismiss()
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-password.html')
  }

  return directive
}