LocationService.$inject = ['$http', 'AppService']

export default function LocationService($http, AppService) {
  return {
    getLocations: function(profileId) {
      return $http.get('/api-prema2/profiles/' + profileId + '/locations?filter={"where":{"isPrimary":{"neq":true}},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"createdAt DESC"}').then(res => {
        var locations = res.data

        locations.forEach(location => {
          if (location.medias) location.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getLocation: function(profileId, slug) {
      return $http.get('/api-prema2/locations/findOne?filter={"where":{"slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}').then(res => {
        var location = res.data

        if (location.medias) location.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getLocationById: function(locationId) {
      return $http.get('/api-prema2/locations/' + locationId)
    },
    initLocation: function() {
      var location = {
        isPublished: true,
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return location
    },
    postLocation: function(location) {
      location.slug = AppService.toSlug(location.location)
      location.createdAt = new Date()
      location.updatedAt = new Date()
      
      return $http.post('/api-prema2/locations', location, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var location2 = res.data

        var locationToProfile = {
          locationId: location2.id,
          profileId: sessionStorage.getItem('profileId')
        }

        $http.post('/api-prema2/locationToProfiles', locationToProfile)

        if (location.medias) {
          location.medias.forEach(media => {
            var locationToMedia = {
              order: media.order,
              locationId: location2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/locationToMedias', locationToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: location2.id.toString(),
          createdAt: location2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentLocation,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (location.services) {
            location.services.forEach(service => {
              var locationToService = {
                locationId: location2.id,
                serviceId: service.id
              }

              return $http.post('/api-prema2/locationToServices', locationToService, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
          }
        })
      })
    },
    putLocation: function(location) {
      location.slug = AppService.toSlug(location.location)
      location.updatedAt = new Date()
      location.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/locationToMedias?filter[where][locationId]=' + location.id).then(res => {
        var locationToMedias = res.data

        var diffs = [].concat(location.medias.filter(obj1 => locationToMedias.every(obj2 => obj1.id !== obj2.id)), locationToMedias.filter(obj2 => location.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!locationToMedias.includes(media)) {
            var locationToMedia = {
              order: media.order,
              locationId: location.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/locationToMedias', locationToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/locationToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.put('/api-prema2/locations/' + location.id, location, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var location2 = res.data
  
          var auditLog = {
            objectId: location2.id.toString(),
            createdAt: location2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: location2.updatedById,
            componentId: AppService.componentLocation,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    deleteLocation: function(location) {
      return $http.get('/api-prema2/locationToProfiles/findOne?filter[where][locationId]=' + location.id).then(res => {
        var locationToProfile = res.data

        $http.delete('/api-prema2/locationToProfiles/' + locationToProfile.id, { headers: { 'Authorization': localStorage.getItem('token') } })
        return $http.delete('/api-prema2/locations/' + location.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    }
  }
}