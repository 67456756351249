SupplierService.$inject = ['$http', 'AppService']

export default function SupplierService($http, AppService) {
  return {
    getSuppliers: function(profileId) {
      return $http.get('/api-prema2/suppliers?filter={"where":{"profileId":"' + profileId + '"},"include":["contacts",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"businessName ASC"}').then(res => {
        var suppliers = res.data

        suppliers.forEach(supplier => {
          if (supplier.medias) supplier.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getSupplier: function(profileId, slug) {
      return $http.get('/api-prema2/suppliers/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["contacts",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}').then(res => {
        var supplier = res.data

        if (supplier.medias) supplier.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getSupplierById: function(supplierId) {
      return $http.get('/api-prema2/suppliers/' + supplierId)
    },
    initSupplier: function() {
      var supplier = {
        isActive: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return supplier
    },
    postSupplier: function(supplier) {
      supplier.businessName = AppService.toTitleCase(supplier.businessName)
      supplier.slug = AppService.toSlug(supplier.businessName)
      supplier.createdAt = new Date()
      supplier.updatedAt = new Date()
      
      return $http.post('/api-prema2/suppliers', supplier, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var supplier2 = res.data

        if (supplier.medias) {
          supplier.medias.forEach(media => {
            var supplierToMedia = {
              order: media.order,
              supplierId: supplier2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/supplierToMedias', supplierToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: supplier2.id.toString(),
          createdAt: supplier2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentSupplier,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    putSupplier: function(supplier) {
      supplier.slug = AppService.toSlug(supplier.businessName)
      supplier.updatedAt = new Date()
      supplier.updatedById = localStorage.getItem('userId')

      if (supplier.contacts.length > 0) {
        supplier.contacts.forEach(contact => {
          $http.get('/api-prema2/contacts/findOne?filter[where][id]=' + contact.id).then(res => {
            var contact2 = res.data
    
            if (!angular.equals(contact, contact2)) $http.put('/api-prema2/contacts/' + contact2.id, contact)
          })
        })
      }

      return $http.get('/api-prema2/supplierToMedias?filter[where][supplierId]=' + supplier.id).then(res => {
        var supplierToMedias = res.data

        var diffs = [].concat(supplier.medias.filter(obj1 => supplierToMedias.every(obj2 => obj1.id !== obj2.id)), supplierToMedias.filter(obj2 => supplier.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!supplierToMedias.includes(media)) {
            var supplierToMedia = {
              order: media.order,
              supplierId: supplier.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/supplierToMedias', supplierToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/supplierToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.put('/api-prema2/suppliers/' + supplier.id, supplier, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var supplier2 = res.data
  
          var auditLog = {
            objectId: supplier2.id.toString(),
            createdAt: supplier2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: supplier2.updatedById,
            componentId: AppService.componentSupplier,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    deleteSupplier: function(supplier) {
      return $http.delete('/api-prema2/suppliers/' + supplier.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getProfileTasks: function(profileId, componentId) {
      return $http.get('/api-prema2/profileToTasks?filter[where][profileId]=' + profileId + '&filter[where][componentId]=' + componentId + '&filter[include]=task&filter[order]=order%20ASC')
    },
    getTaskResponses: function(contactId) {
      return $http.get('/api-prema2/taskResponses?filter[where][contactId]=' + contactId)
    },
    postTaskResponse: function(taskId, contactId) {
      return $http.get('/api-prema2/taskResponses/count?filter[where][contactId]=' + contactId).then(res => {
        var taskResponses = res.data

        var data = {
          taskResponseNo: taskResponses.count + 1,
          createdAt: new Date(),
          updatedAt: new Date(),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          taskId: taskId,
          contactId: contactId
        }

        return $http.post('/api-prema2/taskResponses', data)
      })
    }
  }
}