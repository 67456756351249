ProductsController.$inject = ['$rootScope', 'ProductService', '$stateParams', 'AppService', '$timeout', 'angularGridInstance', '$state', '$transitions']

export default function ProductsController($rootScope, ProductService, $stateParams, AppService, $timeout, angularGridInstance, $state, $transitions) {
  var vm = this

  function getProductStores(profileId) {
    ProductService.getProductStores(profileId).then(res => vm.productStores = res.data)
  }
  function getProducts(profileId) {
    ProductService.getProducts(profileId).then(res => vm.products = res.data)
  }
  function doUploadCsv(file) {
    AppService.uploadFile(file).then(res => {
      var name = res.data.result.files.file[0].name
      AppService.getFile(name).then(res => {
        var lines = res.data.split(/\r\n/)
        var headers = lines.shift().split(/;(?=\S)/)
        var rows = lines.filter(line => line !== headers && line).map(row => row.split(/;(?=\S)/))

        vm.putProductCounter = new Number()
        vm.rows = rows.length
        vm.uploadedCsv = true
        $rootScope.$on('putProductCounter', (event, data) => {
          vm.putProductCounter = data
          if (vm.putProductCounter === rows.length) {
            $timeout(function() {
              vm.hideAlert('uploadedCsv')
            }, 5000)
          }
        })

        new Promise((resolve, reject) => {
          ProductService.getSuppliers(sessionStorage.getItem('profileId')).then(res => {
            var suppliers = res.data

            ProductService.getProductCategories(sessionStorage.getItem('profileId')).then(res => {
              var productCategories = res.data
              
              rows.forEach(row => {
                row.forEach((v, i) => {
                  if ([undefined, ',', ';'].includes(v)) v = ''
                  row[i] = v.trim()
                })

                if (headers[0] === 'productNo') {
                  ProductService.putProducts(productCategories, vm.products, suppliers, row).then(() => resolve())
                } else if (headers[0] === 'variationNo') {
                  ProductService.putProductVariations(productCategories, vm.products, suppliers, row).then(() => resolve())
                }
              })
            })
          })
        }).then(() => {
          vm.products = null
          $timeout(function() {
            getProducts(sessionStorage.getItem('profileId'))
          }, 100)
        })
      })
    })
  }

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      getProductStores(profile.id)
      getProducts(profile.id)
      vm.settings = profile.settings.components.find(component => component.name === 'products')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.uploadCsv = function(file) {
    AppService.getContainers().then(res => {
      var containers = res.data
      if (containers.find(container => container.name === sessionStorage.getItem('profileId'))) {
        doUploadCsv(file)
      } else {
        AppService.postContainer().then(() => doUploadCsv(file))
      }
    })
  }
  vm.toggleManageSuppliers = function() {
    vm.manageSuppliers = !vm.manageSuppliers
  }
  vm.toggleManageStockLocations = function() {
    vm.manageStockLocations = !vm.manageStockLocations
  }
  vm.toggleManageCategories = function() {
    vm.manageCategories = !vm.manageCategories
  }
  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components)
  }
  vm.setSortBy = function(property) {
    var copy = vm.products
    vm.products = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.products = copy
    }, 100)
  }
  vm.setPromoted = function(product) {
    ProductService.patchProduct(product.id, 'isPromoted', !product.isPromoted).then(() => getProducts(sessionStorage.getItem('profileId')))
  }
  vm.setPublished = function(product) {
    ProductService.patchProduct(product.id, 'isPublished', !product.isPublished).then(() => getProducts(sessionStorage.getItem('profileId')))
  }
  vm.delete = function(product) {
    vm.confirmDelete = true
    vm.productToDelete = product
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    ProductService.deleteProduct(vm.productToDelete).then(() => getProducts(sessionStorage.getItem('profileId')))
  }
  vm.setProperty = function(id, key, value) {
    if (key === 'product') ProductService.patchProduct(id, 'slug', AppService.toSlug(value))
    ProductService.patchProduct(id, key, value).then(() => getProducts(sessionStorage.getItem('profileId')))
  }
  vm.numberOfPages = function() {
    return parseInt(vm.products.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
  $transitions.onSuccess({}, () => {
    AppService.setSearch('')
  }, {invokeLimit: 1})
}