ProductService.$inject = ['$http', '$rootScope', 'AppService']

export default function ProductService($http, $rootScope, AppService) {
  var putProductCounter = new Number()

  return {
    getProductStores: function(profileId) {
      return $http.get('/api-prema2/productStores?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"productCategories"],"order":"order ASC"}').then(res => {
        var productStores = res.data

        productStores.forEach(productStore => {
          if (productStore.medias) productStore.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    putProductStore: function(productStore) {
      productStore.slug = AppService.toSlug(productStore.store)
      productStore.profileId = sessionStorage.getItem('profileId')

      return $http.put('/api-prema2/productStores', productStore, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var productStore2 = res.data

        if (productStore.medias) {
          return $http.get('/api-prema2/productStoreToMedias?filter[where][productStoreId]=' + productStore.id).then(res => {
            var productStoreToMedias = res.data
    
            var diffs = [].concat(productStore.medias.filter(obj1 => productStoreToMedias.every(obj2 => obj1.id !== obj2.id)), productStoreToMedias.filter(obj2 => productStore.medias.every(obj1 => obj2.id !== obj1.id)))
            if (diffs.length > 0) diffs.forEach(media => {
              if (!productStoreToMedias.includes(media)) {
                var productStoreToMedia = {
                  order: media.order,
                  productStoreId: productStore2.id,
                  mediaId: media.mediaId,
                  mediaPlacementId: media.mediaPlacementId
                }
      
                return $http.post('/api-prema2/productStoreToMedias', productStoreToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
              } else {
                return $http.delete('/api-prema2/productStoreToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
              }
            })
          })
        }
      })
    },
    deleteProductStore: function(productStore) {
      return $http.delete('/api-prema2/productStores/' + productStore.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getProductStore: function(profileId, slug) {
      return $http.get('/api-prema2/productStores/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"productCategories","scope":{"include":[{"relation":"products","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"productVariations"],"order":"product ASC"}},"productCategories"],"order":"order ASC"}}]}').then(res => {
        var productStore = res.data

        if (productStore.medias) productStore.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getSuppliers: function(profileId) {
      return $http.get('/api-prema2/suppliers?filter={"where":{"profileId":"' + profileId + '"},"order":"businessName ASC"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getSupplierById: function(supplierId) {
      return $http.get('/api-prema2/suppliers/' + supplierId, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    initSupplier: function() {
      var supplier = {
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return supplier
    },
    postSupplier: function(supplier) {
      supplier.slug = AppService.toSlug(supplier.businessName)
      supplier.createdAt = new Date()
      supplier.updatedAt = new Date()
      
      return $http.post('/api-prema2/suppliers', supplier, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var supplier2 = res.data

        var auditLog = {
          objectId: supplier2.id.toString(),
          createdAt: supplier2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentSupplier,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    putSupplier: function(supplier) {
      supplier.slug = AppService.toSlug(supplier.businessName)
      supplier.updatedAt = new Date()
      supplier.updatedById = localStorage.getItem('userId')
      return $http.put('/api-prema2/suppliers', supplier, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var supplier2 = res.data

        var auditLog = {
          objectId: supplier2.id.toString(),
          createdAt: supplier2.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: supplier2.updatedById,
          componentId: AppService.componentSupplier,
          logTypeId: AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    deleteSupplier: function(supplier) {
      return $http.delete('/api-prema2/suppliers/' + supplier.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getStockLocations: function(profileId) {
      return $http.get('/api-prema2/stockLocations?filter={"where":{"profileId":"' + profileId + '"},"order":"order ASC"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putStockLocation: function(stockLocation) {
      stockLocation.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/stockLocations', stockLocation, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteStockLocation: function(stockLocation) {
      return $http.delete('/api-prema2/stockLocations/' + stockLocation.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getProductCategory: function(profileId, slug) {
      return $http.get('/api-prema2/productCategories/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["productStore","productCategories",{"relation":"products","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"productVariations"],"order":"product ASC"}}]}')
    },
    getProductCategories: function(profileId) {
      return $http.get('/api-prema2/productCategories?filter={"where":{"profileId":"' + profileId + '"},"include":"productCategories","order":"order ASC"}')
    },
    putProductCategory: function(productCategory) {
      productCategory.slug = AppService.toSlug(productCategory.category)
      productCategory.profileId = sessionStorage.getItem('profileId')
      if (!productCategory.productCategoryId) productCategory.productCategoryId = undefined
      return $http.put('/api-prema2/productCategories', productCategory, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteProductCategory: function(productCategory) {
      return $http.delete('/api-prema2/productCategories/' + productCategory.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getProducts: function(profileId) {
      return $http.get('/api-prema2/products?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"productVariations"],"order":"product ASC"}').then(res => {
        var products = res.data

        products.forEach(product => {
          if (product.medias) product.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getProduct: function(profileId, slug) {
      return $http.get('/api-prema2/products/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"productVariations","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"order ASC"}},"supplier","productCategories"]}').then(res => {
        var product = res.data

        if (product.medias) product.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        if (product.productVariations) product.productVariations.forEach(productVariation => {
          if (productVariation.medias) productVariation.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getProductById: function(productId) {
      return $http.get('/api-prema2/products/' + productId)
    },
    patchProduct: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/products/' + id, data, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var product = res.data

        var auditLog = {
          objectId: product.id.toString(),
          createdAt: product.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentProduct,
          logTypeId: AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    putProducts: function(productCategories, products, suppliers, row) {
      if (putProductCounter !== 0) putProductCounter = new Number()
      
      var product = {
        productNo: row[0] ? row[0] : undefined,
        product: AppService.toTitleCase(AppService.toClean(row[1])),
        slug: AppService.toSlug(row[1]),
        shortLede: row[2] ? AppService.toClean(row[2]) : undefined,
        description: row[3] ? AppService.toClean(row[3]) : undefined,
        totalCount: row[4] ? parseInt(row[4]) : undefined,
        isInStock: row[5] ? JSON.parse(row[5].toLowerCase()) : true,
        stockCount: row[6] ? parseInt(row[6]) : undefined,
        leadTime: row[7] ? parseInt(AppService.toNumber(row[7])) : undefined,
        unitCostPrice: row[8] ? parseFloat(AppService.toNumber(row[8])) : undefined,
        unitSalePrice: row[9] ? parseFloat(AppService.toNumber(row[9])) : undefined,
        weight: row[11] ? parseFloat(AppService.toNumber(row[11])) : undefined,
        isFamily: row[12] ? JSON.parse(row[12].toLowerCase()) : false,
        isPublished: row[16] ? JSON.parse(row[16].toLowerCase()) : true,
        isPromoted: row[17] ? JSON.parse(row[17].toLowerCase()) : false,
        updatedAt: new Date(),
        id: row[18] ? row[18] : null,
        profileId: sessionStorage.getItem('profileId'),
        updatedById: localStorage.getItem('userId')
      }

      if (row[14]) { // Hero
        if (row[14].includes('//')) {
          product.imgs = {
            imgId: row[14]
          }
        }
      }

      if (row[15]) { // Bodys
        var bodys = row[15].split(/,(?=\S)/)

        bodys.forEach(body => {
          if (body.includes('//')) {
            product.imgs.bodyImgs = {
              imgId: body
            }
          }
        })
      }
      
      if (!product.id) {
        var match = products.find(product2 => product2.productNo === row[0])
        if (match) {
          product.createdAt = match.createdAt
          product.id = match.id
          product.createdById = match.createdById
        } else {
          product.createdAt = new Date()
          product.createdById = localStorage.getItem('userId')
        }
      }

      if (row[19]) { // Supplier
        var supplier = suppliers.find(supplier => supplier.businessName === row[19])
        product.supplierId = supplier.id
      }

      return $http.put('/api-prema2/products', product, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var product2 = res.data

        // if (row[14] || row[15]) { // Medias
        //   var bodys = row[15].split(/,(?=\S)/)

        //   product.medias.forEach(media => {
        //     var productToMedia = {
        //       order: media.order,
        //       productId: product2.id,
        //       mediaId: media.mediaId,
        //       mediaPlacementId: media.mediaPlacementId
        //     }

        //     return $http.post('/api-prema2/productToMedias', productToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
        //   })
        // }

        var auditLog = {
          objectId: product2.id.toString(),
          createdAt: product.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentProduct,
          logTypeId: product.id ? AppService.logTypeUpdate : AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (row[21]) { // Product categories
            var productCategories2 = row[21].split(/;(?=\S)/)

            var productCategories3 = new Array()
            productCategories2.forEach(productCategory2 => {
              productCategories3.push(productCategories.find(productCategory => productCategory.category === productCategory2))
            })

            return $http.get('/api-prema2/productCategoryToProducts?filter[where][productId]=' + product.id).then(res => {
              var productCategoryToProducts = res.data
    
              var diffs = [].concat(productCategories3.filter(obj1 => productCategoryToProducts.every(obj2 => obj1.id !== obj2.productCategoryId)), productCategoryToProducts.filter(obj2 => productCategories3.every(obj1 => obj2.productCategoryId !== obj1.id)))
              var added = diffs.filter(diff => diff.category)
              var removed = diffs.filter(diff => diff.productCategoryId)
              if (added.length > 0) added.forEach(productCategory => {
                var productCategoryToProduct = {
                  productCategoryId: productCategory.id,
                  productId: product2.id
                }
    
                $http.post('/api-prema2/productCategoryToProducts', productCategoryToProduct, { headers: { 'Authorization': localStorage.getItem('token') } })
              })
              if (removed.length > 0) removed.forEach(productCategoryToProduct => {
                $http.delete('/api-prema2/productCategoryToProducts/' + productCategoryToProduct.id, { headers: { 'Authorization': localStorage.getItem('token') } })
              })

              putProductCounter++
              $rootScope.$broadcast('putProductCounter', putProductCounter)
            })
          } else {
            return $http.get('/api-prema2/productCategoryToProducts?filter[where][productId]=' + product.id).then(res => {
              var productCategoryToProducts = res.data

              productCategoryToProducts.forEach(productCategoryToProduct => {
                $http.delete('/api-prema2/productCategoryToProducts/' + productCategoryToProduct.id, { headers: { 'Authorization': localStorage.getItem('token') } })
              })

              putProductCounter++
              $rootScope.$broadcast('putProductCounter', putProductCounter)
            })
          }
        })
      })
    },
    initProduct: function() {
      var product = {
        isInStock: true,
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return product
    },
    postProduct: function(product) {
      product.slug = AppService.toSlug(product.product)
      product.createdAt = new Date()
      product.updatedAt = new Date()
      
      return $http.post('/api-prema2/products', product, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var product2 = res.data

        if (product.medias) {
          product.medias.forEach(media => {
            var productToMedia = {
              order: media.order,
              productId: product2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/productToMedias', productToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: product2.id.toString(),
          createdAt: product2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentProduct,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (product.productCategories) {
            product.productCategories.forEach(productCategory => {
              var productCategoryToProduct = {
                productCategoryId: productCategory.id,
                productId: product2.id
              }

              return $http.post('/api-prema2/productCategoryToProducts', productCategoryToProduct, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
          }

          if (product.productVariations) {
            product.productVariations.forEach(productVariation => {
              productVariation.createdAt = new Date()
              productVariation.updatedAt = new Date()
              productVariation.productId = product2.id
              
              return $http.post('/api-prema2/productVariations', productVariation, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
                var productVariation2 = res.data

                if (productVariation.medias) {
                  productVariation.medias.forEach(media => {
                    var productVariationToMedia = {
                      order: media.order,
                      productVariationId: productVariation2.id,
                      mediaId: media.mediaId,
                      mediaPlacementId: media.mediaPlacementId
                    }

                    return $http.post('/api-prema2/productVariationToMedias', productVariationToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
                  })
                }
              })
            })
          }
        })
      })
    },
    putProduct: function(product) {
      product.slug = AppService.toSlug(product.product)
      product.updatedAt = new Date()
      product.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/productToMedias?filter[where][productId]=' + product.id).then(res => {
        var productToMedias = res.data

        var diffs = [].concat(product.medias.filter(obj1 => productToMedias.every(obj2 => obj1.id !== obj2.id)), productToMedias.filter(obj2 => product.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!productToMedias.includes(media)) {
            var productToMedia = {
              order: media.order,
              productId: product.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/productToMedias', productToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/productToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.get('/api-prema2/productCategoryToProducts?filter[where][productId]=' + product.id).then(res => {
          var productCategoryToProducts = res.data

          var diffs = [].concat(product.productCategories.filter(obj1 => productCategoryToProducts.every(obj2 => obj1.id !== obj2.productCategoryId)), productCategoryToProducts.filter(obj2 => product.productCategories.every(obj1 => obj2.productCategoryId !== obj1.id)))
          var added = diffs.filter(diff => diff.category)
          var removed = diffs.filter(diff => diff.productCategoryId)
          if (added.length > 0) added.forEach(productCategory => {
            var productCategoryToProduct = {
              productCategoryId: productCategory.id,
              productId: product.id
            }

            $http.post('/api-prema2/productCategoryToProducts', productCategoryToProduct, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
          if (removed.length > 0) removed.forEach(productCategoryToProduct => {
            $http.delete('/api-prema2/productCategoryToProducts/' + productCategoryToProduct.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          })

          return $http.put('/api-prema2/products/' + product.id, product, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var product2 = res.data
    
            var auditLog = {
              objectId: product2.id.toString(),
              createdAt: product2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: product2.updatedById,
              componentId: AppService.componentProduct,
              logTypeId: AppService.logTypeUpdate
            }
    
            return AppService.postAuditLog(auditLog).then(() => {
              return $http.get('/api-prema2/productVariations?filter={"where":{"productId":"' + product.id + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}').then(res => {
                var productVariations = res.data
      
                var diffs = [].concat(product.productVariations.filter(obj1 => productVariations.every(obj2 => obj1.id !== obj2.id)), productVariations.filter(obj2 => product.productVariations.every(obj1 => obj2.id !== obj1.id)))
                if (diffs.length > 0) diffs.forEach(productVariation => {
                  if (!productVariations.includes(productVariation)) {
                    productVariation.createdAt = new Date()
                    productVariation.updatedAt = new Date()
                    productVariation.productId = product2.id

                    return $http.post('/api-prema2/productVariations', productVariation, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
                      var productVariation2 = res.data

                      if (productVariation.medias) {
                        productVariation.medias.forEach(media => {
                          var productVariationToMedia = {
                            order: media.order,
                            productVariationId: productVariation2.id,
                            mediaId: media.mediaId,
                            mediaPlacementId: media.mediaPlacementId
                          }
      
                          return $http.post('/api-prema2/productVariationToMedias', productVariationToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
                        })
                      }
                    })
                  } else {
                    return $http.delete('/api-prema2/productVariations/' + productVariation.id, { headers: { 'Authorization': localStorage.getItem('token') } })
                  }
                })

                productVariations.forEach(productVariation => {
                  if (!diffs.includes(productVariation)) {
                    var index = product.productVariations.findIndex(productVariation2 => productVariation2.id === productVariation.id)
                    if (!angular.equals(product.productVariations[index], productVariation)) {
                      product.productVariations[index].updatedAt = new Date()
                      product.productVariations[index].updatedById = localStorage.getItem('userId')

                      return $http.put('/api-prema2/productVariations/' + productVariation.id, product.productVariations[index], { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
                        var productVariation2 = res.data

                        return $http.get('/api-prema2/productVariationToMedias?filter[where][productVariationId]=' + productVariation.id).then(res => {
                          var productVariationToMedias = res.data
                  
                          var diffs = [].concat(product.productVariations[index].medias.filter(obj1 => productVariationToMedias.every(obj2 => obj1.id !== obj2.id)), productVariationToMedias.filter(obj2 => product.productVariations[index].medias.every(obj1 => obj2.id !== obj1.id)))
                          if (diffs.length > 0) diffs.forEach(media => {
                            if (!productVariationToMedias.includes(media)) {
                              var productVariationToMedia = {
                                order: media.order,
                                productVariationId: productVariation2.id,
                                mediaId: media.mediaId,
                                mediaPlacementId: media.mediaPlacementId
                              }
                    
                              return $http.post('/api-prema2/productVariationToMedias', productVariationToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
                            } else {
                              return $http.delete('/api-prema2/productVariationToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
                            }
                          })
                        })

                      })
                    }
                  }
                })
              })
            })
          })
        })
      })
    },
    initProductVariation: function(product) {
      var productVariation = {
        isInStock: true,
        order: product.productVariations.length + 1,
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return productVariation
    },
    deleteProduct: function(product) {
      return new Promise((resolve, reject) => {
        if (product.productCategories.length) {
          product.productCategories.forEach(productCategory => $http.get('/api-prema2/productCategoryToProducts/findOne?filter[where][productCategoryId]=' + productCategory.id + '&filter[where][productId]=' + product.id).then(res => {
            var productCategoryToProduct = res.data

            $http.delete('/api-prema2/productCategoryToProducts/' + productCategoryToProduct.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => resolve())
          }))
        } else {
          resolve()
        }
        if (product.productVariations.length) {
          product.productVariations.forEach(productVariation => $http.delete('/api-prema2/productVariations/' + productVariation.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => resolve()))
        } else {
          resolve()
        }
      }).then(() => {
        return $http.delete('/api-prema2/products/' + product.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    }
  }
}