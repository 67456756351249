EmployeeController.$inject = ['$scope', '$rootScope', 'CorporateService', '$stateParams', 'AuthService', 'AppService', '$uibModal', '$state', '$transitions', '$timeout']

export default function EmployeeController($scope, $rootScope, CorporateService, $stateParams, AuthService, AppService, $uibModal, $state, $transitions, $timeout) {
  var vm = this

  function getEmployee(profile) {
    var getEmployee = new Promise((resolve, reject) => CorporateService.getEmployee(profile.id, $stateParams.slug).then(res => {
      vm.worker = res.data
      copy = angular.copy(vm.worker)
      vm.worker.startDate = new Date(vm.worker.startDate)
      if (vm.worker.endDate) vm.worker.endDate = new Date(vm.worker.endDate)
      if (vm.worker.isSysUser) AuthService.getSysUserByContactId(vm.worker.contactId).then(res => vm.worker.sysUser = res.data)
      copy.startDate = new Date(copy.startDate)
      if (copy.endDate) copy.endDate = new Date(copy.endDate)
      if (copy.isSysUser) AuthService.getSysUserByContactId(copy.contactId).then(res => copy.sysUser = res.data)
      resolve()
    }))

    getEmployee.then(() => {
      AppService.getComponentByName('Worker').then(res => {
        var component = res.data

        AppService.getProfileTasks(profile.id, component.id).then(res => {
          vm.profileTasks = res.data

          AppService.getTaskResponses(vm.worker.contactId).then(res => {
            var taskResponses = res.data

            var intersections = vm.profileTasks.filter(profileTask => taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))

            vm.profileTasks.forEach(profileTask => {
              profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
            })
          })
        })
      })
    })
  }

  function saveSelection() {
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        var ranges = []
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
        return ranges
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange()
    }
    return null
  }

  var shell = $scope.$parent.shell
  var copy
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      getEmployee(profile)
    }
  })

  vm.insertLink = function() {
    AppService.savedSel = saveSelection()

    $uibModal.open({
      controller: 'ModalLinkController',
      controllerAs: 'modalLink',
      template: require('../../app/link-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.setPublished = function() {
    vm.worker.isPublished = !vm.worker.isPublished
  }
  vm.delete = function() {
    vm.confirmDelete = true
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    CorporateService.deleteEmployee(vm.worker).then(() => $state.go('shell.team'))
  }
  vm.tabs = [0, 1]
  vm.activeTab = 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
  $transitions.onBefore({}, () => {
    if (!angular.equals(vm.worker, copy)) {
      CorporateService.putEmployee(vm.worker).then(() => {
        if (sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
          AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
            shell.invitedTeamMembers = true
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
            $timeout(function() {
              shell.hideAlert('invitedTeamMembers')
            }, 5000)
          })
        }
      })
    } else {
      sessionStorage.removeItem('taskId')
      sessionStorage.removeItem('contactId')
    }
  }, {invokeLimit: 1})
}