settingsSlideout.$inject = ['AppService', '$uibModal', '$timeout']

export default function settingsSlideout(AppService, $uibModal, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      settings: '=',
      component: '='
    },
    link: function(scope, elem, attrs) {
      var shell = scope.$parent.$parent.shell
      var home = scope.$parent.home

      scope.$watch('settings', settings => {
        if (settings !== undefined) {
          if (scope.component && scope.component.includes(',')) {
            scope.components = scope.component.split(',')
            scope.components.forEach((scopeComponent, index) => {
              var name = 'component' + index
              scope[name] = scope.settings.components.find(component => component.name === scopeComponent)
            })
          } else {
            var name = 'component' + 0
            scope[name] = scope.settings.components.find(component => component.name === scope.component)
          }
        }
      })
      scope.toggleSettings = function() {
        AppService.putSettings(scope.settings).then(() => {
          if (scope.settings.fqdn && sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
            AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
              shell.updatedWebsiteSettings = true
              AppService.getTaskResponses(sessionStorage.getItem('contactId')).then(res => {
                home.taskResponses = res.data
  
                var intersections = home.profileTasks.filter(profileTask => home.taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))
  
                home.profileTasks.forEach(profileTask => {
                  profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
                })

                // Prema workflows
                if (home.profileTasks.length === home.taskResponses.length) {
                  $uibModal.open({
                    controller: 'ModalAppCheckoutController',
                    controllerAs: 'modalAppCheckout',
                    template: require('./app-checkout-modal-new.html'),
                    resolve: {
                      contact: function() {
                        return home.currentUser.contact
                      }
                    }
                  }).result.then(angular.noop, angular.noop)
                }
              })
              sessionStorage.removeItem('taskId')
              sessionStorage.removeItem('contactId')
              $timeout(function() {
                shell.hideAlert('updatedWebsiteSettings')
              }, 5000)
            })
          } else {
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
          }
        })
        scope.slideout = false
      }
      scope.addCustomField = function(component) {
        if (!scope[component].customFields) scope[component].customFields = new Array()
        scope[component].customFields.push({
          order: scope[component].customFields.length + 1
        })
      }
      scope.deleteCustomField = function(component, index) {
        scope[component].customFields.splice(index, 1)
        if (scope[component].customFields.length === 0) delete scope[component].customFields
      }
    },
    template: require('./_slideout-settings.html')
  }

  return directive
}