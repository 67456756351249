routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.auth.me', {
      url: '/me',
      abstract: true,
      template: require('./me.html'),
      controller: 'MeController',
      controllerAs: 'me'
    })
    .state('shell.auth.me.orders', {
      url: '/orders',
      template: require('./orders.html'),
      controller: 'MeOrdersController',
      controllerAs: 'meOrders',
      resolve: {
        $title: function() {
          return 'Orders'
        }
      }
    })
    .state('shell.auth.me.locations', {
      url: '/manage-addresses',
      template: require('./locations.html'),
      controller: 'MeLocationsController',
      controllerAs: 'meLocations',
      resolve: {
        $title: function() {
          return 'Manage Addresses'
        }
      }
    })
    .state('shell.auth.me.communications', {
      url: '/communication-preferences',
      template: require('./communications.html'),
      controller: 'MeCommunicationsController',
      controllerAs: 'meCommunications',
      resolve: {
        $title: function() {
          return 'Communication Preferences'
        }
      }
    })
}