AppService.$inject = ['$http', 'Upload', '$q', '$uibModal', '$rootScope']

export default function AppService($http, Upload, $q, $uibModal, $rootScope) {
  // Define these variables here as they are used inside this service
  var componentLead = '5ea3f983b432f2ab4453f2e7'
  var componentContract = '5ea3f98cb432f2ab4453f2e8'
  var componentOrder = '5ea3f993b432f2ab4453f2e9'
  var componentReview = '5ea3f9bcb432f2ab4453f2ed'
  var componentQuestionnaire = '60463d3ac35f4200c1e41633'
  var componentTask = '606324e386b9e093973c73a9'
  var componentBooking = '61af53ef8fb9e7209bc6b2ca'
  var componentMembership = '620133b318446649861a40ca'
  var logTypeCreate = '5ea3f902b432f2ab4453f2e3'
  var logTypeUpdate = '5ea3f907b432f2ab4453f2e4'
  var logTypeDelete = '5ea3f90db432f2ab4453f2e5'
  var logTypeRead = '61b8b99db5be26c1979dd5ba'
  function calcDuration(startDateUtc, endDateUtc) {
    var start = Date.parse(startDateUtc)
    var end = Date.parse(endDateUtc)

    return end - start
  }
  function toTitleCase(str) {
    str = str.toLowerCase().split(' ')
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(' ')
  }
  function postAuditLog(auditLog) {
    return $http.post('/api-prema2/auditLogs', auditLog, { headers: { 'Authorization': localStorage.getItem('token') } })
  }

  return {
    profileId: sessionStorage.getItem('profileId'),
    
    componentEvent: '5ea3f963b432f2ab4453f2e6',
    componentLead: componentLead,
    componentContract: componentContract,
    componentOrder: componentOrder,
    componentPost: '5ea3f99cb432f2ab4453f2ea',
    componentProduct: '5ea3f9a5b432f2ab4453f2eb',
    componentStudy: '5ea3f9a8b432f2ab4453f2ec',
    componentReview: componentReview,
    componentService: '5ea3f9d4b432f2ab4453f2ee',
    componentSupplier: '5ea4049bfb4042b01872e26f',
    componentWorker: '5eabee941f9872eeae57645c',
    componentCustomer: '60463d3ac35f4200c1e41632',
    componentQuestionnaire: componentQuestionnaire,
    componentSubscription: '606324e186b9e093973c73a8',
    componentTask: componentTask,
    componentBooking: componentBooking,
    componentMembership: componentMembership,
    componentCourse: '6246ea6face2c6bf43ea73ed',
    componentLocation: '63ca348de35543fa78aff3f4',
    logTypeCreate: logTypeCreate,
    logTypeUpdate: logTypeUpdate,
    logTypeDelete: logTypeDelete,
    logTypeRead: logTypeRead,

    getProfile: function(uri) {
      return $http.get('/api-prema2/profiles/findOne?filter={"where":{"web":"' + uri + '"},"include":["settings","businessEntity","businessSector",{"relation":"locations","scope":{"include":{"relation":"country","scope":{"include":"currency"}}}},{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}')
    },
    getProfileId: function(uri) {
      return $http.get('/api-prema2/profiles/findOne?filter[where][web]=' + uri + '&filter[fields][id]=true')
    },
    getHexPrimaryValue: function(color) {
      // Variables for red, green, blue values
      var r, g, b, hsp
      
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
        
        r = color[1]
        g = color[2]
        b = color[3]
      } 
      else {
        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'))
  
        r = color >> 16
        g = color >> 8 & 255
        b = color & 255
      }
      
      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      )
  
      // Using the HSP value, determine whether the color is light or dark
      if (hsp > 200) {
        return 'light'
      } 
      else {
        return 'dark'
      }
    },
    getContainers: function() {
      return $http.get('/api-prema2/containers')
    },
    postContainer: function() {
      var container = {
        name: sessionStorage.getItem('profileId')
      }

      return $http.post('/api-prema2/containers', container)
    },
    getFile: function(name) {
      return $http.get('/api-prema2/containers/' + sessionStorage.getItem('profileId') + '/download/' + name)
    },
    uploadFile: function(file) {
      return Upload.upload({
        url: '/api-prema2/containers/' + sessionStorage.getItem('profileId') + '/upload',
        headers: { 'Authorization' : localStorage.getItem('token') },
        file: file
      }).then(res => {
        return res
      }, function(res) {
        console.log('Error status: ' + res.status)
      }, function(event) {
        var uploadProgress = (Math.round((event.loaded * 100.0) / event.total)) - 1
        $rootScope.$broadcast('uploadProgress', uploadProgress)
      })
    },
    getMediaById: function(id) {
      return $http.get('/api-prema2/medias/' + id)
    },
    uploadMedia: function(file) {
      return Upload.upload({
        url: '/api-prema2/medias/upload',
        headers: { 'Authorization' : localStorage.getItem('token') },
        data: {
          file: file,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId')
        }
      }).then(res => {
        var defer = $q.defer()

        var file = res.data
        if (file.type.includes('video')) {
          $uibModal.open({
            controller: 'ModalVideoController',
            controllerAs: 'modalVideo',
            template: require('./video-modal-new.html'),
            resolve: {
              file: function() {
                return file
              }
            }
          }).result.then(attrs => {
            if (attrs) file.attrs = attrs
            file.updatedAt = new Date()
            file.updatedById = localStorage.getItem('userId')

            return $http.put('/api-prema2/medias/' + file.id, file, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
              defer.resolve(res)
            })
          })
        } else {
          defer.resolve(res)
        }

        return defer.promise
      }, function(res) {
        console.log('Error status: ' + res.status)
      }, function(event) {
        var uploadProgress = (Math.round((event.loaded * 100.0) / event.total)) - 1
        $rootScope.$broadcast('uploadProgress', uploadProgress)
      })
    },
    postLead: function(lead, contact) {
      return $http.get('/api-prema2/contactTypes').then(res => {
        var contactTypes = res.data

        var data = {
          firstName: toTitleCase(contact.firstName),
          lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
          displayName: toTitleCase(contact.firstName),
          profileId: sessionStorage.getItem('profileId'),
          contactTypeId: contact.businessName ? contactTypes[3].id : contactTypes[2].id
        }

        if (contact.telephone || contact.email) data.communications = new Array()
        
        if (contact.email) data.communications.push({
          method: 'Email',
          value: contact.email,
          order: data.communications.length + 1,
          isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
        })

        if (contact.telephone) data.communications.push({
          method: 'Telephone',
          value: contact.telephone,
          order: data.communications.length + 1,
          isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
        })

        return $http.post('/api-prema2/contacts', data).then(res => {
          var contact2 = res.data

          if (contact.businessName) {
            var data = {
              businessName: toTitleCase(contact.businessName),
              profileId: sessionStorage.getItem('profileId')
            }

            $http.post('/api-prema2/accounts', data).then(res => {
              var account = res.data

              var data = {
                contactId: contact2.id,
                accountId: account.id
              }

              $http.post('/api-prema2/contactToAccounts', data)
            })
          }

          var data = {
            body: lead.body,
            isCompleted: true,
            isRead: true,
            createdAt: new Date(),
            updatedAt: new Date(),
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            updatedById: localStorage.getItem('userId'),
            contactId: contact2.id
          }

          return $http.post('/api-prema2/leads', data).then(res => {
            var lead2 = res.data
    
            var auditLog = {
              objectId: lead2.id.toString(),
              createdAt: lead2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: lead2.updatedById,
              componentId: componentLead,
              logTypeId: logTypeCreate
            }
    
            return postAuditLog(auditLog).then(() => res)
          })
        })
      })
    },
    // getBookingTypes: function() {
    //   return $http.get('/api-prema2/bookingTypes?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"}}')
    // },
    postBooking: function(booking) {
      return $http.get('/api-prema2/bookings/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var bookings = res.data

        var data = {
          bookingNo: bookings.count + 1,
          referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
          startDateUtc: booking.startDateUtc,
          duration: calcDuration(booking.startDateUtc, booking.startDateUtc),
          numberOfGuests: booking.numberOfGuests,
          notes: booking.notes,
          grossValue: booking.grossValue ? booking.grossValue : undefined,
          netValue: booking.netValue ? booking.netValue : undefined,
          isRead: true,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          serviceId: booking.serviceId
        }

        return $http.post('/api-prema2/bookings', data).then(res => {
          var booking2 = res.data
  
          var auditLog = {
            objectId: booking2.id.toString(),
            createdAt: booking2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: booking2.updatedById,
            componentId: componentBooking,
            logTypeId: logTypeCreate
          }
  
          return postAuditLog(auditLog).then(() => res)
        })
      })
    },
    postOrder: function() {
      return $http.get('/api-prema2/orders/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var orders = res.data

        var data = {
          orderNo: orders.count + 1,
          referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
          isRead: true,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId')
        }

        return $http.post('/api-prema2/orders', data).then(res => {
          var order = res.data
  
          var auditLog = {
            objectId: order.id.toString(),
            createdAt: order.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: order.updatedById,
            componentId: componentOrder,
            logTypeId: logTypeCreate
          }
  
          return postAuditLog(auditLog).then(() => res)
        })
      })
    },
    postReview: function(review, contact) {
      return $http.get('/api-prema2/contactTypes').then(res => {
        var contactTypes = res.data

        var data = {
          firstName: toTitleCase(contact.firstName),
          lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
          displayName: toTitleCase(contact.firstName),
          profileId: sessionStorage.getItem('profileId'),
          contactTypeId: contactTypes[2].id
        }

        if (contact.telephone || contact.email) data.communications = new Array()
        
        if (contact.email) data.communications.push({
          method: 'Email',
          value: contact.email,
          order: data.communications.length + 1,
          isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
        })

        if (contact.telephone) data.communications.push({
          method: 'Telephone',
          value: contact.telephone,
          order: data.communications.length + 1,
          isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
        })

        return $http.post('/api-prema2/contacts', data).then(res => {
          var contact2 = res.data

          var data = {
            body: review.body,
            rating: review.rating,
            isCompleted: true,
            isRead: true,
            createdAt: new Date(),
            updatedAt: new Date(),
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            updatedById: localStorage.getItem('userId'),
            contactId: contact2.id
          }

          return $http.post('/api-prema2/reviews', data).then(res => {
            var review2 = res.data
    
            var auditLog = {
              objectId: review2.id.toString(),
              createdAt: review2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: review2.updatedById,
              componentId: componentReview,
              logTypeId: logTypeCreate
            }
    
            return postAuditLog(auditLog).then(() => res)
          })
        })
      })
    },
    postMembership: function(membership) {
      return $http.get('/api-prema2/memberships/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var memberships = res.data

        var data = {
          membershipNo: memberships.count + 1,
          referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
          startDateUtc: membership.startDateUtc,
          duration: calcDuration(membership.startDateUtc, membership.startDateUtc),
          isRead: true,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId')
        }

        return $http.post('/api-prema2/memberships', data).then(res => {
          var membership2 = res.data
  
          var auditLog = {
            objectId: membership2.id.toString(),
            createdAt: membership2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: membership2.updatedById,
            componentId: componentMembership,
            logTypeId: logTypeCreate
          }
  
          return postAuditLog(auditLog).then(() => res)
        })
      })
    },
    postContract: function(contract) {
      return $http.get('/api-prema2/contracts/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var contracts = res.data

        var data = {
          contractNo: contracts.count + 1,
          referenceNo: (Math.floor(Math.random() * 10000) + 10000).toString().substring(1),
          startDateUtc: contract.startDateUtc,
          duration: calcDuration(contract.startDateUtc, contract.startDateUtc),
          isRead: true,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId')
        }

        return $http.post('/api-prema2/contracts', data).then(res => {
          var contract2 = res.data
  
          var auditLog = {
            objectId: contract2.id.toString(),
            createdAt: contract2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: contract2.updatedById,
            componentId: componentContract,
            logTypeId: logTypeCreate
          }
  
          return postAuditLog(auditLog).then(() => res)
        })
      })
    },
    postContact: function(contact) {
      return $http.get('/api-prema2/contactTypes').then(res => {
        var contactTypes = res.data

        var data = {
          firstName: toTitleCase(contact.firstName),
          lastName: contact.lastName ? toTitleCase(contact.lastName) : undefined,
          displayName: toTitleCase(contact.firstName),
          profileId: sessionStorage.getItem('profileId'),
          contactTypeId: contact.businessName ? contactTypes[3].id : contactTypes[2].id
        }

        if (contact.telephone || contact.email) data.communications = new Array()
        
        if (contact.email) data.communications.push({
          method: 'Email',
          value: contact.email,
          order: data.communications.length + 1,
          isPrimary: !contact.telephone && contact.email ? true : contact.telephone && contact.email ? true : undefined
        })

        if (contact.telephone) data.communications.push({
          method: 'Telephone',
          value: contact.telephone,
          order: data.communications.length + 1,
          isPrimary: contact.telephone && !contact.email ? true : contact.telephone && contact.email ? undefined : undefined
        })

        return $http.post('/api-prema2/contacts', data).then(res => {
          var contact2 = res.data

          if (contact.businessName) {
            var data = {
              businessName: toTitleCase(contact.businessName),
              profileId: sessionStorage.getItem('profileId')
            }

            $http.post('/api-prema2/accounts', data).then(res => {
              var account = res.data

              var data = {
                contactId: contact2.id,
                accountId: account.id
              }

              $http.post('/api-prema2/contactToAccounts', data).then(() => contact2)
            })
          } else {
            return contact2
          }
        })
      })
    },
    getLocationById: function(locationId) {
      return $http.get('/api-prema2/locations/' + locationId + '?filter[include]=country')
    },
    postContactLocation: function(location, isPrimary, contactId) {
      var data = {
        address: {
          street1: toTitleCase(location.street1),
          street2: location.street2 ? toTitleCase(location.street2) : undefined,
          street3: location.street3 ? toTitleCase(location.street3) : undefined,
          city: toTitleCase(location.city),
          county: toTitleCase(location.county),
          postcode: location.postcode.toUpperCase()
        },
        isPrimary: isPrimary,
        countryId: location.countryId
      }

      return $http.post('/api-prema2/locations', data).then(res => {
        var location = res.data

        var locationToContact = {
          locationId: location.id,
          contactId: contactId
        }

        $http.post('/api-prema2/locationToContacts', locationToContact)

        return $http.get('/api-prema2/locations/' + location.id + '?filter[include]=country')
      })
    },
    toTitleCase: function(str) {
      return toTitleCase(str)
    },
    toSlug: function(str) {
      return str.replace(/&amp;/g, '').replace(/ - /g, '').replace(/\s\s/g, ' ').replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
    },
    toDateSlug: function(startDateUtc, str) {
      return startDateUtc.toISOString().slice(2,10).replace(/[^0-9]/g, '') + startDateUtc.toISOString().slice(11,16).replace(/[^0-9]/g, '') + '-' + str.replace(/&amp;/g, '').replace(/-/g, '').replace(/\s\s/g, ' ').replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()
    },
    toClean: function(str) {
      var first = str.indexOf('"')
      var last = str.lastIndexOf('"')
      if (first === 0) str = str.replace(/"/, '')
      if (first === 0 && last === str.length) str = str.replace(/"/, '')
      str = str.trim()
      return str
    },
    toNumber: function(str) {
      return str.replace(/[^0-9.]+/g, '')
    },
    getTimeUnits: function() {
      return $http.get('/api-prema2/timeUnits', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    setSearch: function(val) {
      $rootScope.$broadcast('search', val)
    },
    getComponents: function() {
      return $http.get('/api-prema2/components', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getComponentByName: function(component) {
      return $http.get('/api-prema2/components/findOne?filter[where][component]=' + toTitleCase(component), { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getLogTypes: function() {
      return $http.get('/api-prema2/logTypes', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getActivityTypes: function() {
      return $http.get('/api-prema2/activityTypes', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getAuditLogsCount: function(profileId) {
      return $http.get('/api-prema2/auditLogs/count?filter[where][profileId]=' + profileId, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getAuditLogsRecentX: function(profileId, x) {
      return $http.get('/api-prema2/auditLogs?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"createdBy","scope":{"include":"contact"}},"component","logType"],"order":"createdAt%20DESC","limit":' + x + '}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    postAuditLog: function(auditLog) {
      return postAuditLog(auditLog)
    },
    getMediaPlacements: function() {
      return $http.get('/api-prema2/mediaPlacements', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getCountries: function() {
      return $http.get('/api-prema2/countries')
    },
    getExternalResourceTypes: function() {
      return $http.get('/api-prema2/externalResourceTypes')
    },
    putSettings: function(settings) {
      var straps = [
        {fontFamily:'Nunito Sans',fontWeight:'800',letterSpacing:'-3px'},
        {fontFamily:'Satisfy'}
      ]

      if (settings.fqdn) settings.fqdn = settings.fqdn.toLowerCase().replace(/^https?:\/\//, '').replace('www.','')
      if (!settings.theme) delete settings.theme
      if (settings.strap && settings.strap.fontFamily) {settings.strap = straps.find(strap => strap.fontFamily === settings.strap.fontFamily)} else {delete settings.strap}

      settings.components.forEach(component => {
        if (component.customFields) component.customFields.forEach(customField => delete component[customField.name])
      })
      
      return $http.put('/api-prema2/settings', settings, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    patchSettings: function(key, value, settingsId) {
      var data = {
        [key]: value
      }

      return $http.patch('/api-prema2/settings/' + settingsId, data, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getQuestionnaireQuestionTypes: function() {
      return $http.get('/api-prema2/questionnaireQuestionTypes')
    },
    getQuestionnaireById: function(questionnaireId) {
      return $http.get('/api-prema2/questionnaires/' + questionnaireId, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    postQuestionnaire: function(settingsId) {
      var questionnaire = {
        questionnaire: 'Customer',
        createdAt: new Date(),
        updatedAt: new Date(),
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return $http.post('/api-prema2/questionnaires', questionnaire, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var questionnaire2 = res.data

        var auditLog = {
          objectId: questionnaire2.id.toString(),
          createdAt: questionnaire2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: componentQuestionnaire,
          logTypeId: logTypeCreate
        }

        return $http.post('/api-prema2/auditLogs', auditLog, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => {
          var data = {
            questionnaireId: questionnaire2.id
          }

          return $http.patch('/api-prema2/settings/' + settingsId, data).then(() => {
            return questionnaire2
          })
        })
      })
    },
    putQuestionnaire: function(questionnaireId) {
      return $http.get('/api-prema2/questionnaires/' + questionnaireId).then(res => {
        var questionnaire = res.data

        questionnaire.updatedAt = new Date()
        questionnaire.updatedById = localStorage.getItem('userId')

        return $http.put('/api-prema2/questionnaires', { headers: { 'Authorization': localStorage.getItem('token') } }, questionnaire).then(res => {
          var questionnaire2 = res.data

          var auditLog = {
            objectId: questionnaire2.id.toString(),
            createdAt: questionnaire2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            componentId: componentQuestionnaire,
            logTypeId: logTypeUpdate
          }

          return $http.post('/api-prema2/auditLogs', auditLog, { headers: { 'Authorization': localStorage.getItem('token') } })
        })
      })
    },
    getQuestionnaireQuestions: function(questionnaireId) {
      return $http.get('/api-prema2/questionnaireQuestions?filter[where][questionnaireId]=' + questionnaireId + '&filter[include]=questionnaireAnswers')
    },
    initQuestionnaireQuestion: function() {
      var questionnaireQuestion = {
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return questionnaireQuestion
    },
    postQuestionnaireQuestion: function(questionnaireQuestion) {
      questionnaireQuestion.createdAt = new Date()
      questionnaireQuestion.updatedAt = new Date()
      
      return $http.post('/api-prema2/questionnaireQuestions', questionnaireQuestion, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putQuestionnaireQuestion: function(questionnaireQuestion) {
      questionnaireQuestion.updatedAt = new Date()
      questionnaireQuestion.updatedById = localStorage.getItem('userId')
      return $http.put('/api-prema2/questionnaireQuestions', questionnaireQuestion, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteQuestionnaireQuestion: function(questionnaireQuestion) {
      return $http.get('/api-prema2/questionnaireAnswers?filter[where][questionnaireQuestionId]=' + questionnaireQuestion.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var questionnaireAnswers = res.data

        var promise = new Promise((resolve, reject) => {
          var counter = 0
          questionnaireAnswers.forEach(questionnaireAnswer => {
            $http.delete('/api-prema2/questionnaireAnswers/' + questionnaireAnswer.id, { headers: { 'Authorization': localStorage.getItem('token') } })
            counter++
            if (counter === questionnaireAnswers.length) resolve()
          })
        })

        promise.then(() => {
          return $http.delete('/api-prema2/questionnaireQuestions/' + questionnaireQuestion.id, { headers: { 'Authorization': localStorage.getItem('token') } })
        })
      })
    },
    initQuestionnaireAnswer: function() {
      var questionnaireAnswer = {
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return questionnaireAnswer
    },
    postQuestionnaireAnswer: function(questionnaireAnswer) {
      questionnaireAnswer.createdAt = new Date()
      questionnaireAnswer.updatedAt = new Date()
      
      return $http.post('/api-prema2/questionnaireAnswers', questionnaireAnswer, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putQuestionnaireAnswer: function(questionnaireAnswer) {
      questionnaireAnswer.updatedAt = new Date()
      questionnaireAnswer.updatedById = localStorage.getItem('userId')
      return $http.put('/api-prema2/questionnaireAnswers', questionnaireAnswer, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteQuestionnaireAnswer: function(questionnaireAnswer) {
      return $http.delete('/api-prema2/questionnaireAnswers/' + questionnaireAnswer.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getQuestionnaire: function(questionnaireId) {
      return $http.get('/api-prema2/questionnaires/' + questionnaireId + '?filter={"include":{"relation":"questionnaireQuestions","scope":{"include":[{"relation":"questionnaireAnswers","scope":{"include":"questionnaireQuestion"}},"questionnaireQuestionType"]}}}')
    },
    getQuestionnaireQuestionResponses: function(questionnaireId, contactId) {
      return $http.get('/api-prema2/questionnaireQuestionResponses?filter[where][questionnaireId]=' + questionnaireId + '&filter[where][contactId]=' + contactId + '&filter[include]=questionnaireQuestion')
    },
    getLeadSources: function(profileId) {
      return $http.get('/api-prema2/leadSources?filter[where][profileId]=' + profileId + '&filter[order]=order%20ASC')
    },
    putLeadSource: function(leadSource) {
      leadSource.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/leadSources', leadSource, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteLeadSource: function(leadSource) {
      return $http.delete('/api-prema2/leadSources/' + leadSource.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getRecurrencePatterns: function() {
      return $http.get('/api-prema2/recurrencePatterns', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getProfileTasks: function(profileId, componentId) {
      return $http.get('/api-prema2/profileToTasks?filter={"where":{"profileId":"' + profileId + '","componentId":"' + componentId + '"},"include":"task"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    initProfileTask: function(profileToTasks, component) {
      var profileToTask = {
        order: profileToTasks.length + 1,
        profileId: sessionStorage.getItem('profileId'),
        componentId: component.id,
        task: {
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId')
        }
      }

      return profileToTask
    },
    postProfileTask: function(task, profileToTask) {
      task.createdAt = new Date()
      task.updatedAt = new Date()
      
      return $http.post('/api-prema2/tasks', task, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var task2 = res.data

        profileToTask.taskId = task2.id

        return $http.post('/api-prema2/profileToTasks', profileToTask, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => {
          var auditLog = {
            objectId: task2.id.toString(),
            createdAt: task2.createdAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            componentId: componentTask,
            logTypeId: logTypeCreate
          }

          return $http.post('/api-prema2/auditLogs', auditLog, { headers: { 'Authorization': localStorage.getItem('token') } })
        })
      })
    },
    putProfileTask: function(task, profileToTask) {
      task.updatedAt = new Date()
      task.updatedById = localStorage.getItem('userId')

      return $http.put('/api-prema2/tasks', task, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var task2 = res.data

        return $http.put('/api-prema2/profileToTasks', profileToTask, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => {
          var auditLog = {
            objectId: task2.id.toString(),
            createdAt: task2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: localStorage.getItem('userId'),
            componentId: componentTask,
            logTypeId: logTypeUpdate
          }

          return $http.post('/api-prema2/auditLogs', auditLog, { headers: { 'Authorization': localStorage.getItem('token') } })
        })
      })
    },
    deleteProfileTask: function(task, profileToTask) {
      return $http.delete('/api-prema2/tasks/' + task.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => {
        return $http.delete('/api-prema2/profileToTasks/' + profileToTask.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    },
    getTaskById: function(taskId) {
      return $http.get('/api-prema2/tasks/' + taskId, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getTaskResponses: function(contactId) {
      return $http.get('/api-prema2/taskResponses?filter[where][contactId]=' + contactId)
    },
    postTaskResponse: function(taskId, contactId) {
      return $http.get('/api-prema2/taskResponses/count?where[contactId]=' + contactId).then(res => {
        var taskResponses = res.data

        var taskResponse = {
          taskResponseNo: taskResponses.count + 1,
          createdAt: new Date(),
          updatedAt: new Date(),
          createdById: localStorage.getItem('userId'),
          updatedById: localStorage.getItem('userId'),
          taskId: taskId,
          contactId: contactId
        }

        return $http.post('/api-prema2/taskResponses', taskResponse)
      })
    },
    getPaymentTypes: function() {
      return $http.get('/api-prema2/paymentTypes', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getPaymentStatuses: function() {
      return $http.get('/api-prema2/paymentStatuses', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getPayments: function() {
      return $http.get('/api-prema2/payments?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[order]=createdAt%20DESC')
    },
    getPaymentById: function(paymentId) {
      return $http.get('/api-prema2/payments/' + paymentId + '?filter[include]=paymentMethod&filter[include]=paymentStatus')
    },
    getComponentActivities: function(component, componentId) {
      return $http.get('/api-prema2/' + component + 's/' + componentId + '/activities?filter[include]=activityType')
    },
    postPayment: function(component, parent, payment) {
      return $http.get('/api-prema2/payments/count?where[contactId]=' + parent.contactId).then(res => {
        var payments = res.data

        var data = {
          paymentNo: payments.count + 1,
          referenceNo: parent.referenceNo,
          grossValue: parent.netValue,
          netValue: payment.netValue,
          createdAt: new Date(),
          updatedAt: new Date(),
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
          updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
          contactId: parent.contactId,
          paymentTypeId: payment.paymentTypeId,
          paymentMethodId: payment.paymentMethodId,
          paymentStatusId: payment.paymentStatusId
        }

        return $http.post('/api-prema2/payments', data).then(res => {
          var payment2 = res.data

          return $http.get('/api-prema2/activityTypes').then(res => {
            var activityTypes = res.data

            var activity = {
              objectId: payment2.id.toString(),
              createdAt: new Date(),
              updatedAt: new Date(),
              profileId: sessionStorage.getItem('profileId'),
              createdById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
              updatedById: localStorage.getItem('userId') ? localStorage.getItem('userId') : undefined,
              activityTypeId: activityTypes.find(activityType => activityType.type === 'Payment').id
            }

            return $http.post('/api-prema2/activities', activity).then(res => {
              var activity2 = res.data

              var activityToComponentVar = 'activityTo' + toTitleCase(component)
              var componentIdKey = component + 'Id'
              
              window[activityToComponentVar] = {
                activityId: activity2.id,
                [componentIdKey]: parent.id
              }

              return $http.post('/api-prema2/' + activityToComponentVar + 's', window[activityToComponentVar]).then(() => this.getComponentActivities(component, parent.id))
            })
          })
        })
      })
    },
    putPayment: function(activity, payment) {
      payment.updatedAt = new Date()
      payment.updatedById = localStorage.getItem('userId')

      return $http.put('/api-prema2/payments', payment).then(() => {
        activity.updatedAt = new Date()
        activity.updatedById = localStorage.getItem('userId')

        return $http.put('/api-prema2/activities', activity)
      })
    },
    patchPayment: function(key, value, paymentId) {
      var data = {
        [key]: value
      }

      return $http.patch('/api-prema2/payments/' + paymentId, data)
    },
    getPaymentMethods: function() {
      return $http.get('/api-prema2/paymentMethods')
    },
    getProfilePaymentMethods: function(profileId) {
      return $http.get('/api-prema2/profilePaymentMethods?filter={"where":{"profileId":"' + profileId + '"},"include":"paymentMethod"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putProfilePaymentMethod: function(profilePaymentMethod) {
      profilePaymentMethod.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/profilePaymentMethods', profilePaymentMethod, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteProfilePaymentMethod: function(profilePaymentMethod) {
      return $http.delete('/api-prema2/profilePaymentMethods/' + profilePaymentMethod.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putLocation: function(location) {
      location.isPrimary = true
      
      return $http.put('/api-prema2/locations', location).then(res => {
        var location = res.data

        return $http.get('/api-prema2/locationToProfiles?filter[where][profileId]=' + sessionStorage.getItem('profileId')).then(res => {
          var locationToProfiles = res.data

          var locationToProfileExists = locationToProfiles.some(locationToProfile => locationToProfile.locationId === location.id)
          if (!locationToProfileExists) {
            var locationToProfile = {
              locationId: location.id,
              profileId: sessionStorage.getItem('profileId')
            }
    
            return $http.put('/api-prema2/locationToProfiles', locationToProfile).then(() => {
              return $http.get('/api-prema2/locations/' + location.id + '?filter[include][country]=currency')
            })
          } else {
            return $http.get('/api-prema2/locations/' + location.id + '?filter[include][country]=currency')
          }
        })
      })
    }
  }
}