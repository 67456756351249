routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.customers', {
      url: '/customers?page',
      template: require('./customers.html'),
      controller: 'CustomersController',
      controllerAs: 'customers',
      resolve: {
        $title: function() {
          return 'Customers'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.customer', {
      url: '/customers/:slug',
      template: require('./customer.html'),
      controller: 'CustomerController',
      controllerAs: 'customer',
      resolve: {
        customer: ['CustomerService', '$stateParams', function(CustomerService, $stateParams) {
          return CustomerService.getCustomer(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['customer', function(customer) {
          return customer.data.contact.firstName + ' ' + customer.data.contact.lastName
        }]
      }
    })
    .state('shell.newCustomer', {
      url: '/customers/new',
      template: require('./new-customer.html'),
      controller: 'NewCustomerController',
      controllerAs: 'newCustomer',
      resolve: {
        $title: function() {
          return 'New Customer'
        }
      }
    })
}