routing.$inject = ['$locationProvider', '$urlRouterProvider', '$stateProvider', 'uiGmapGoogleMapApiProvider', '$sceDelegateProvider']

export default function routing($locationProvider, $urlRouterProvider, $stateProvider, uiGmapGoogleMapApiProvider, $sceDelegateProvider) {
  $locationProvider.html5Mode(true)
  $urlRouterProvider.otherwise('/')
  uiGmapGoogleMapApiProvider.configure({
    key: 'AIzaSyBLzzTUfPIRNhYsvHeVt0QWPlhYeumTjqw',
    v: '3.51',
    libraries: 'weather,geometry,visualization'
  })
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    '*://www.youtube.com/**',
    '*://player.vimeo.com/video/**',
    '*://preview.befor.business/**',
    '*://**.list-manage.com/**',
    '*://api.cloudflare.com/client/v4/**'
  ])

  $stateProvider
    .state('shell', {
      abstract: true,
      template: require('./shell.html'),
      controller: 'ShellController',
      controllerAs: 'shell'
    })
    .state('shell.auth', {
      abstract: true
    })
}