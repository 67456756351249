MembershipService.$inject = ['$http', 'AppService']

export default function MembershipService($http, AppService) {
  return {
    getMembershipStatuses: function() {
      return $http.get('/api-prema2/membershipStatuses')
    },
    getMembershipTypes: function() {
      return $http.get('/api-prema2/membershipTypes?filter[where][profileId]=' + sessionStorage.getItem('profileId') + '&filter[order]=order%20ASC')
    },
    putMembershipType: function(membershipType) {
      membershipType.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/membershipTypes', membershipType, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteMembershipType: function(membershipType) {
      return $http.delete('/api-prema2/membershipTypes/' + membershipType.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getMemberships: function() {
      return $http.get('/api-prema2/memberships?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"membershipType","membershipStatus","recurrencePattern",{"relation":"activities","scope":{"include":"activityType"}}],"order":"createdAt DESC"}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getMembership: function(referenceNo) {
      return $http.get('/api-prema2/memberships/findOne?filter={"where":{"referenceNo":"' + referenceNo + '","profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":"contactType"}},"membershipStatus","membershipLineItems",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getMembershipById: function(membershipId) {
      return $http.get('/api-prema2/memberships/' + membershipId + '?filter[include]=contact', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putMembership: function(membership) {
      membership.isRecurring = membership.recurrencePatternId ? true : undefined
      membership.isCompleted = true
      membership.updatedAt = new Date()
      membership.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/membershipLineItems?filter[where][membershipId]=' + membership.id).then(res => {
        var membershipLineItems = res.data

        var diffs = [].concat(membership.membershipLineItems.filter(obj1 => membershipLineItems.every(obj2 => obj1.subscription !== obj2.subscription)), membershipLineItems.filter(obj2 => membership.membershipLineItems.every(obj1 => obj2.subscription !== obj1.subscription)))
        if (diffs.length > 0) diffs.forEach(membershipLineItem => {
          if (!membershipLineItems.includes(membershipLineItem)) {
            var membershipLineItem = {
              subscription: {
                subscriptionNo: membershipLineItem.subscription.subscriptionNo ? membershipLineItem.subscription.subscriptionNo : undefined,
                subscription: membershipLineItem.subscription.subscription,
                unitSalePrice: membershipLineItem.subscription.unitSalePrice,
                id: membershipLineItem.subscription.id
              },
              count: membershipLineItem.count,
              totalValue: membershipLineItem.totalValue,
              membershipId: membership.id
            }
  
            $http.post('/api-prema2/membershipLineItems', membershipLineItem, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            $http.delete('/api-prema2/membershipLineItems/' + membershipLineItem.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        if (membership.contactId) {
          $http.get('/api-prema2/contacts/findOne?filter[where][id]=' + membership.contactId).then(res => {
            var contact = res.data
    
            if (!angular.equals(membership.contact, contact)) $http.put('/api-prema2/contacts/' + contact.id, membership.contact)
          })
        }
  
        return $http.put('/api-prema2/memberships/' + membership.id, membership, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var membership2 = res.data
  
          var auditLog = {
            objectId: membership2.id.toString(),
            createdAt: membership2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: membership2.updatedById,
            componentId: AppService.componentMembership,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    patchMembership: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/memberships/' + id, data).then(res => {
        var membership = res.data

        var auditLog = {
          objectId: membership.id.toString(),
          createdAt: membership.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentMembership,
          logTypeId: key === 'isRead' ? AppService.logTypeRead : AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    }
  }
}