paymentMethodsSlideout.$inject = ['$rootScope', 'AppService', '$state', '$timeout']

export default function paymentMethodsSlideout($rootScope, AppService, $state, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getProfilePaymentMethods() {
        $rootScope.$watch('profile.id', profileId => {
          if (profileId !== undefined) AppService.getProfilePaymentMethods(profileId).then(res => scope.profilePaymentMethods = res.data)
        })
      }

      var shell = scope.$parent.$parent.shell
      var home = scope.$parent.home
      getProfilePaymentMethods()
      AppService.getPaymentMethods().then(res => scope.paymentMethods = res.data)

      scope.toggleManagePaymentMethods = function() {
        new Promise((resolve, reject) => {
          scope.profilePaymentMethods.forEach(profilePaymentMethod => AppService.putProfilePaymentMethod(profilePaymentMethod).then(() => resolve()))
        }).then(() => {
          if (scope.profilePaymentMethods.length > 0 && sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
            AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
              shell.setupPaymentMethod = true
              AppService.getTaskResponses(sessionStorage.getItem('contactId')).then(res => {
                home.taskResponses = res.data
  
                var intersections = home.profileTasks.filter(profileTask => home.taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))
  
                home.profileTasks.forEach(profileTask => {
                  profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
                })
              })
              sessionStorage.removeItem('taskId')
              sessionStorage.removeItem('contactId')
              $timeout(function() {
                shell.hideAlert('setupPaymentMethod')
              }, 5000)
            })
          } else {
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
          }
          getProfilePaymentMethods()
        })

        scope.slideout = false
      }
      scope.addProfilePaymentMethod = function() {
        scope.profilePaymentMethods.push({
          order: scope.profilePaymentMethods.length + 1
        })
      }
      scope.deleteProfilePaymentMethod = function(profilePaymentMethod) {
        AppService.deleteProfilePaymentMethod(profilePaymentMethod).then(() => getProfilePaymentMethods())
      }
      scope.setPaymentMethod = function(paymentMethodId) {
        var paymentMethod = scope.paymentMethods.find(paymentMethod => paymentMethod.id === paymentMethodId)
        if (paymentMethod.method === 'Visa, American Express or MasterCard') scope.confirmCardPayments = true
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_slideout-payment-methods.html')
  }

  return directive
}