productCategoriesSlideout.$inject = ['ProductService', 'AppService', '$rootScope', '$timeout']

export default function productCategoriesSlideout(ProductService, AppService, $rootScope, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      productStores: '='
    },
    link: function(scope, elem, attrs) {
      function getProductStores(profileId) {
        ProductService.getProductStores(profileId).then(res => scope.productStores = res.data)
      }
      function getProductCategories(profileId) {
        ProductService.getProductCategories(profileId).then(res => scope.productCategories = res.data)
      }

      getProductStores(sessionStorage.getItem('profileId'))
      getProductCategories(sessionStorage.getItem('profileId'))
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)

      scope.toggleManageCategories = function() {
        new Promise((resolve, reject) => {
          scope.productStores.forEach(productStore => ProductService.putProductStore(productStore).then(() => resolve()))
          scope.productCategories.forEach(productCategory => ProductService.putProductCategory(productCategory).then(() => resolve()))
        }).then(() => {
          $timeout(function() {
            getProductStores(sessionStorage.getItem('profileId'))
          }, 1000)
        })

        scope.slideout = false
      }
      scope.addStore = function() {
        scope.productStores.push({
          order: scope.productStores.length + 1
        })
      }
      scope.uploadMedias = function(files, placement, productStore) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!productStore.medias) productStore.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingProductStoreToMediasSamePlacement = productStore.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newProductStoreToMediaSamePlacement = {
                order: existingProductStoreToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newProductStoreToMediaSamePlacement.originalMedia = originalMedia
              productStore.medias.push(newProductStoreToMediaSamePlacement)
              if (existingProductStoreToMediasSamePlacement.length === 1 && existingProductStoreToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = productStore.medias.findIndex(media => media === existingProductStoreToMediasSamePlacement[0], 1)
                productStore.medias.splice(index, 1)
                newProductStoreToMediaSamePlacement.order = newProductStoreToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.removeMedia = function(media, productStore) {
        var index = productStore.medias.findIndex(media2 => media2.id === media.id)
        productStore.medias.splice(index, 1)
      }
      scope.addCategory = function() {
        scope.productCategories.forEach(productCategory => {
          if (!productCategory.productCategoryId) {
            productCategory.productCategoryId = null
          }
        })
        scope.productCategories.push({
          order: scope.productCategories.length + 1,
          productCategoryId: null
        })
      }
      scope.isNotChild = function(productCategory) {
        if (productCategory.productCategoryId) productCategory.productCategoryId = null
      }
      scope.isChild = function(productCategory) {
        if (productCategory.productStoreId) productCategory.productStoreId = null
      }
      scope.deleteStore = function(productStore) {
        ProductService.deleteProductStore(productStore).then(() => getProductStores(sessionStorage.getItem('profileId')))
      }
      scope.deleteCategory = function(productCategory) {
        ProductService.deleteProductCategory(productCategory).then(() => getProductCategories(sessionStorage.getItem('profileId')))
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_slideout-categories.html')
  }

  return directive
}