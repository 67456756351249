AboutController.$inject = ['$scope', '$rootScope', '$transitions', 'CorporateService', 'AppService', '$timeout']

export default function AboutController($scope, $rootScope, $transitions, CorporateService, AppService, $timeout) {
  var vm = this

  var shell = $scope.$parent.shell
  var copy = angular.copy($rootScope.profile)

  $transitions.onBefore({}, () => {
    if (!angular.equals($rootScope.profile, copy)) {
      CorporateService.putProfile($rootScope.profile).then(() => {
        if (sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
          AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
            shell.updatedBusinessProfile = true
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
            $timeout(function() {
              shell.hideAlert('updatedBusinessProfile')
            }, 5000)
          })
        }
      })
    } else {
      sessionStorage.removeItem('taskId')
      sessionStorage.removeItem('contactId')
    }
  }, {invokeLimit: 1})
}