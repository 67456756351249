TeamController.$inject = ['$scope', '$rootScope', 'CorporateService', '$transitions', 'AppService', '$timeout']

export default function TeamController($scope, $rootScope, CorporateService, $transitions, AppService, $timeout) {
  var vm = this

  var shell = $scope.$parent.shell

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      CorporateService.getTeam(profileId).then(res => vm.team = res.data)
    }
  })

  vm.toggleManageTasks = function() {
    vm.manageTasks = !vm.manageTasks
  }
  vm.setSortBy = function(property) {
    var copy = vm.team
    vm.team = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    function compareNested(a, b) {
      let comparison = 0
      while (comparison < property.length) { a = a[property[comparison]]; b = b[property[comparison]]; comparison++; }
      if (a > b) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a < b) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    if (property.includes('.')) {
      property = property.split('.')
      copy.sort(compareNested)
    } else {
      copy.sort(compare)
    }
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.team = copy
    }, 100)
  }
  $transitions.onBefore({}, () => {
    if (sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
      AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
        shell.invitedTeamMembers = true
        sessionStorage.removeItem('taskId')
        sessionStorage.removeItem('contactId')
        $timeout(function() {
          shell.hideAlert('invitedTeamMembers')
        }, 5000)
      })
    }
  }, {invokeLimit: 1})
}