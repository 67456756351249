calendar.$inject = ['moment']

export default function calendar(moment) {
  var directive = {
    restrict: 'E',
    template: require('./_calendar.html'),
    scope: {
      selected: '=',
      events: '=',
      bookings: '='
    },
    link: function(scope) {
      scope.selected = moment(scope.selected) || moment()
      scope.month = scope.selected.clone()

      var start = scope.selected.clone()
      start.date(1)
      _removeTime(start.day(0))

      scope.$watch('bookings', bookings => {
        if (bookings !== undefined) {
          _buildMonth(scope, start, scope.month)
        }
      })

      scope.select = function(day) {
        scope.selected = day.date
      }

      scope.next = function() {
        var next = scope.month.clone()
        _removeTime(next.month(next.month()+1).date(1))
        scope.month.month(scope.month.month()+1)
        _buildMonth(scope, next, scope.month)
      }

      scope.today = function() {
        scope.month = scope.selected.clone()
        _buildMonth(scope, start, scope.month)
      }

      scope.previous = function() {
        var previous = scope.month.clone()
        _removeTime(previous.month(previous.month()-1).date(1))
        scope.month.month(scope.month.month()-1)
        _buildMonth(scope, previous, scope.month)
      }

      function _removeTime(date) {
        return date.day(1).hour(0).minute(0).second(0).millisecond(0)
      }
    
      function _buildMonth(scope, start, month) {
        scope.weeks = []
        var done = false, date = start.clone(), monthIndex = date.month(), count = 0
        while (!done) {
          scope.weeks.push({ days: _buildWeek(date.clone(), month, scope.events, scope.bookings) })
          date.add(1, 'w')
          done = count++ > 2 && monthIndex !== date.month()
          monthIndex = date.month()
        }
      }
    
      function _buildWeek(date, month, events, bookings) {
        var days = []
        for (var i = 0; i < 7; i++) {
          days.push({
            name: date.format('dd').substring(0, 1),
            number: date.date(),
            isCurrentMonth: date.month() === month.month(),
            isToday: date.isSame(new Date(), 'day'),
            date: date,
            events: _buildEvents(events, date),
            bookings: _buildBookings(bookings, date)
          })
          date = date.clone()
          date.add(1, 'd')
        }
        return days
      }

      function _buildEvents(events, date) {
        return events.filter(event => {
          var startDateUtc = moment(event.startDateUtc)

          if (startDateUtc.date() === date.date() && startDateUtc.month() === date.month() && startDateUtc.year() === date.year()) {
            return event
          }

          if (event.isRecurring) {
            var recurrenceEndDateUtc = event.recurrenceEndDateUtc ? moment(event.recurrenceEndDateUtc) : moment(event.endDateUtc).add(3, 'y')
            var weeks = moment.duration(recurrenceEndDateUtc.diff(startDateUtc)).asWeeks()

            for (var i = 0; i < weeks; i++) {
              startDateUtc = startDateUtc.clone()
              event.recurrencePattern.pattern === 'Every day' ? startDateUtc.add(1, 'd') : event.recurrencePattern.pattern === 'Every week' ? startDateUtc.add(1, 'w') : event.recurrencePattern.pattern === 'Every two weeks' ? startDateUtc.add(2, 'w') : event.recurrencePattern.pattern === 'Every month' ? startDateUtc.add(1, 'm') : event.recurrencePattern.pattern === 'Every year' ? startDateUtc.add(1, 'y') : null
              if (startDateUtc.date() === date.date() && startDateUtc.month() === date.month() && startDateUtc.year() === date.year()) {
                return event
              }
            }
          }
        })
      }

      function _buildBookings(bookings, date) {
        return bookings.filter(booking => {
          var startDateUtc = moment(booking.startDateUtc)

          if (startDateUtc.date() === date.date() && startDateUtc.month() === date.month() && startDateUtc.year() === date.year()) {
            return booking
          }

          if (booking.isRecurring) {
            var recurrenceEndDateUtc = booking.recurrenceEndDateUtc ? moment(booking.recurrenceEndDateUtc) : moment(booking.endDateUtc).add(3, 'y')
            var weeks = moment.duration(recurrenceEndDateUtc.diff(startDateUtc)).asWeeks()

            for (var i = 0; i < weeks; i++) {
              startDateUtc = startDateUtc.clone()
              booking.recurrencePattern.pattern === 'Every day' ? startDateUtc.add(1, 'd') : booking.recurrencePattern.pattern === 'Every week' ? startDateUtc.add(1, 'w') : booking.recurrencePattern.pattern === 'Every two weeks' ? startDateUtc.add(2, 'w') : booking.recurrencePattern.pattern === 'Every month' ? startDateUtc.add(1, 'm') : booking.recurrencePattern.pattern === 'Every year' ? startDateUtc.add(1, 'y') : null
              if (startDateUtc.date() === date.date() && startDateUtc.month() === date.month() && startDateUtc.year() === date.year()) {
                return event
              }
            }
          }
        })
      }
    }
  }

  return directive
}