import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './review.routes'
import ReviewService from './review.service'
import ReviewCard from './review-card.directive'
import ReviewsController from './reviews.controller'
import './review.less'

export default angular.module('app.review', [uirouter, ReviewCard])
  .config(routing)
  .service('ReviewService', ReviewService)
  .controller('ReviewsController', ReviewsController)
  .name