articleForm.$inject = ['$rootScope', 'ArticleService', 'AppService', '$timeout', '$uibModal']

export default function articleForm($rootScope, ArticleService, AppService, $timeout, $uibModal) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      article: '='
    },
    link: function(scope, elem, attrs) {
      scope.$parent[attrs.property] = this
      this.isPristine = () => scope.articleForm.$pristine

      scope.filePath = $rootScope.filePath
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)
      ArticleService.getArticleCategories(sessionStorage.getItem('profileId')).then(res => scope.postCategories = res.data)

      scope.uploadMedias = function(files, placement, invalidFiles) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!scope.article.medias) scope.article.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingArticleToMediasSamePlacement = scope.article.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newArticleToMediaSamePlacement = {
                order: existingArticleToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newArticleToMediaSamePlacement.originalMedia = originalMedia
              scope.article.medias.push(newArticleToMediaSamePlacement)
              if (existingArticleToMediasSamePlacement.length === 1 && existingArticleToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = scope.article.medias.findIndex(media => media === existingArticleToMediasSamePlacement[0], 1)
                scope.article.medias.splice(index, 1)
                newArticleToMediaSamePlacement.order = newArticleToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.addExternalResource = function() {
        $uibModal.open({
          controller: 'ModalExternalResourceController',
          controllerAs: 'modalExternalResource',
          template: require('../../app/external-resource-modal-new.html')
        }).result.then(externalResource => {
          if (externalResource) {
            scope.article.externalResources.push(externalResource)
          }
        })
      }
      scope.removeMedia = function(media) {
        var index = scope.article.medias.findIndex(media2 => media2.id === media.id)
        scope.article.medias.splice(index, 1)
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-article.html')
  }

  return directive
}