routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.reviews', {
      url: '/reviews?page',
      template: require('./reviews.html'),
      controller: 'ReviewsController',
      controllerAs: 'reviews',
      resolve: {
        $title: function() {
          return 'Reviews'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
}