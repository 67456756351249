SupplierController.$inject = ['$rootScope', 'SupplierService', '$stateParams', 'AppService', '$state', '$uibModal', '$transitions']

export default function SupplierController($rootScope, SupplierService, $stateParams, AppService, $state, $uibModal, $transitions) {
  var vm = this

  function saveSelection() {
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        var ranges = []
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
        return ranges
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange()
    }
    return null
  }

  var copy
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      SupplierService.getSupplier(profile.id, $stateParams.slug).then(res => {
        vm.supplier = res.data
        copy = angular.copy(vm.supplier)

        AppService.getComponentByName('Supplier').then(res => {
          var component = res.data
  
          SupplierService.getProfileTasks(profile.id, component.id).then(res => {
            vm.profileTasks = res.data
  
            SupplierService.getTaskResponses(vm.supplier.contactId).then(res => {
              var taskResponses = res.data
  
              var intersections = vm.profileTasks.filter(profileTask => taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))
  
              vm.profileTasks.forEach(profileTask => {
                profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
              })
            })
          })
        })
      })
    }
  })

  vm.insertLink = function() {
    AppService.savedSel = saveSelection()

    $uibModal.open({
      controller: 'ModalLinkController',
      controllerAs: 'modalLink',
      template: require('../../app/link-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.setPublished = function() {
    vm.supplier.isPublished = !vm.supplier.isPublished
  }
  vm.delete = function() {
    vm.confirmDelete = true
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    SupplierService.deleteSupplier(vm.supplier).then(() => $state.go('shell.suppliers'))
  }
  vm.tabs = [0, 1]
  vm.activeTab = 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.showNewContactModal = function() {
    $uibModal.open({
      template: require('../../app/contact-modal-new.html')
    }).result.then(contact => {
      vm.supplier.contacts.push(contact)
    })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
  $transitions.onBefore({}, () => {
    if (!angular.equals(vm.supplier, copy)) return SupplierService.putSupplier(vm.supplier)
  }, {invokeLimit: 1})
}