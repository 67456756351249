employeeForm.$inject = ['$rootScope', 'CorporateService', 'AppService', '$timeout', '$uibModal', 'AuthService']

export default function employeeForm($rootScope, CorporateService, AppService, $timeout, $uibModal, AuthService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      worker: '='
    },
    link: function(scope, elem, attrs) {
      scope.$parent[attrs.property] = this
      this.isPristine = () => scope.employeeForm.$pristine

      scope.filePath = $rootScope.filePath
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)
      CorporateService.getTeam(sessionStorage.getItem('profileId')).then(res => scope.team = res.data)
      CorporateService.getWorkerRoles().then(res => scope.workerRoles = res.data)
      scope.startDate = false
      scope.endDate = false
      scope.startDateOptions = {
        startingDay: 1
      }
      scope.endDateOptions = {
        minDate: new Date(),
        startingDay: 1
      }
      scope.currentUserId = localStorage.getItem('userId')

      scope.uploadMedias = function(files, placement, invalidFiles) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!scope.worker.contact.medias) scope.worker.contact.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingContactToMediasSamePlacement = scope.worker.contact.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newContactToMediaSamePlacement = {
                order: existingContactToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newContactToMediaSamePlacement.originalMedia = originalMedia
              scope.worker.contact.medias.push(newContactToMediaSamePlacement)
              if (existingContactToMediasSamePlacement.length === 1 && existingContactToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = scope.worker.contact.medias.findIndex(media => media === existingContactToMediasSamePlacement[0], 1)
                scope.worker.contact.medias.splice(index, 1)
                newContactToMediaSamePlacement.order = newContactToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.addCommunication = function() {
        if (!scope.worker.contact.communications) scope.worker.contact.communications = new Array()
        scope.worker.contact.communications.push({
          order: scope.worker.contact.communications.length + 1,
          isPrimary: scope.worker.contact.communications.length === 0 ? true : undefined
        })
      }
      scope.addSocial = function() {
        if (!scope.worker.contact.socials) scope.worker.contact.socials = new Array()
        scope.worker.contact.socials.push({
          order: scope.worker.contact.socials.length + 1
        })
      }
      scope.deleteCommunication = function(communication) {
        var index = scope.worker.contact.communications.indexOf(communication)
        scope.worker.contact.communications.splice(index, 1)
      }
      scope.deleteSocial = function(social) {
        var index = scope.worker.contact.socials.indexOf(social)
        scope.worker.contact.socials.splice(index, 1)
      }
      scope.toggleStartDate = function() {
        scope.startDate = !scope.startDate
      }
      scope.toggleEndDate = function() {
        scope.endDate = !scope.endDate
      }
      scope.isSysUser = function() {
        if (scope.worker.isSysUser) {
          $uibModal.open({
            controller: 'ModalSysUserController',
            controllerAs: 'modalSysUser',
            template: require('../auth/sys-user-modal-new.html'),
            resolve: {
              contact: function() {
                return scope.worker.contact
              }
            }
          }).result.then(sysUser => {
            scope.worker.sysUserId = sysUser.id
            scope.worker.sysUser = sysUser
          }, () => {
            scope.worker.isSysUser = false
          })
        } else {
          scope.worker.isSysUser = true
          scope.confirmSysUserDelete = true
        }
      }
      scope.doSysUserDelete = function() {
        scope.hideAlert('confirmSysUserDelete')
        AuthService.deleteSysUser(scope.worker.sysUser).then(() => {
          scope.worker.isSysUser = false
          scope.worker.sysUser = null
          scope.worker.sysUserId = null
        })
      }
      scope.removeMedia = function(media) {
        var index = scope.worker.contact.medias.findIndex(media2 => media2.id === media.id)
        scope.worker.contact.medias.splice(index, 1)
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-employee.html')
  }

  return directive
}