ModalAppCheckoutController.$inject = ['contact', 'AppService', '$uibModalInstance', '$rootScope', '$window']

export default function ModalAppCheckoutController(contact, AppService, $uibModalInstance, $rootScope, $window) {
  var vm = this

  vm.contact = contact
  vm.membership = new Object()
  vm.payment = new Object()
  AppService.getMembershipTypes().then(res => vm.membershipTypes = res.data)
  AppService.getPaymentTypes().then(res => vm.paymentTypes = res.data)
  AppService.getProfilePaymentMethods('61f8e43a79fd747201d0d3da').then(res => vm.profilePaymentMethods = res.data)
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)

  vm.createMembership = function() {
    vm.submitted = true

    vm.membership.membershipTypeId = vm.membershipTypes[0].id
    vm.membership.startDateUtc = new Date()
    
    AppService.postAppMembership(vm.membership).then(res => {
      var membership = res.data

      AppService.postAppPayment(membership, vm.payment).then(res => {
        $uibModalInstance.close()
        var hexPrimary = $rootScope.profile.settings.hexPrimary.substring(1)
        AppService.getPaymentById(membership.paymentId).then(res => {
          var payment = res.data

          $window.location.href = '//demo.vivapayments.com/web2?ref=' + payment.orderCode + '&color=' + hexPrimary
        })
      })
    })
  }
}