MeController.$inject = ['AuthService', '$uibModal']

export default function MeController(AuthService, $uibModal) {
  var vm = this

  AuthService.getCurrentUser().then(res => vm.currentUser = res.data)

  vm.showChangePasswordModal = function() {
    $uibModal.open({
      template: require('../auth/password-modal.html')
    }).result.then(angular.noop, angular.noop)
  }
}