CorporateService.$inject = ['$http', 'AppService']

export default function CorporateService($http, AppService) {
  return {
    getBusinessEntities: function() {
      return $http.get('/api-prema2/businessEntities')
    },
    // getLocations: function(profileId) {
    //   return $http.get('/api-prema2/profiles/' + profileId + '/locations?filter[include]=country&filter[order]=isPrimary%20DESC')
    // },
    // putLocation: function(location) {
    //   return $http.put('/api-prema2/locations', location).then(res => {
    //     var location = res.data

    //     return $http.get('/api-prema2/locationToProfiles?filter[where][profileId]=' + sessionStorage.getItem('profileId')).then(res => {
    //       var locationToProfiles = res.data

    //       var locationToProfileExists = locationToProfiles.some(locationToProfile => locationToProfile.locationId === location.id)
    //       if (!locationToProfileExists) {
    //         var locationToProfile = {
    //           locationId: location.id,
    //           profileId: sessionStorage.getItem('profileId')
    //         }
    
    //         return $http.put('/api-prema2/locationToProfiles', locationToProfile)
    //       }
    //     })
    //   })
    // },
    getFaqs: function(profileId) {
      return $http.get('/api-prema2/faqs?filter[where][profileId]=' + profileId + '&filter[order]=order%20ASC')
    },
    initFaq: function(faqs) {
      var faq = {
        order: faqs.length + 1,
        isActive: true,
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return faq
    },
    postFaq: function(faq) {
      faq.createdAt = new Date()
      faq.updatedAt = new Date()

      return $http.put('/api-prema2/faqs', faq, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    putFaq: function(faq) {
      faq.updatedAt = new Date()
      faq.updatedById = localStorage.getItem('userId')

      return $http.put('/api-prema2/faqs/' + faq.id, faq, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteFaq: function(faq) {
      return $http.delete('/api-prema2/faqs/' + faq.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getTeam: function(profileId) {
      return $http.get('/api-prema2/workers?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"contact","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}}],"order":"order ASC"}', { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var workers = res.data

        workers.forEach(worker => {
          if (worker.contact.medias) worker.contact.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getEmployee: function(profileId, slug) {
      return $http.get('/api-prema2/workers/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"contact","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}}]}', { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var worker = res.data

        if (worker.contact.medias) worker.contact.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getEmployeeById: function(employeeId) {
      return $http.get('/api-prema2/workers/' + employeeId + '?filter[include]=contact', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    initEmployee: function() {
      var worker = {
        startDate: new Date(),
        isActive: true,
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      $http.get('/api-prema2/workers/count?where[profileId]=' + sessionStorage.getItem('profileId'), { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var workers = res.data
        worker.order = workers.count + 1
      })

      return worker
    },
    postEmployee: function(employee) {
      employee.contact.firstName = employee.contact.displayName
      employee.contact.profileId = sessionStorage.getItem('profileId')
      employee.contact.contactTypeId = '590b41ee2dc5a21c38dcda2b'

      return $http.post('/api-prema2/contacts', employee.contact, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var contact = res.data

        if (employee.contact.medias) {
          employee.contact.medias.forEach(media => {
            var contactToMedia = {
              order: media.order,
              contactId: contact.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/contactToMedias', contactToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        return $http.get('/api-prema2/workers/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
          var workers = res.data

          var name = contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName
          employee.workerNo = (workers.count + 1).toString()
          employee.slug = AppService.toSlug(name)
          employee.startDate = new Date(employee.startDate).setUTCHours(0, 0, 0, 0)
          employee.createdAt = new Date()
          employee.updatedAt = new Date()
          employee.contactId = contact.id
          
          return $http.post('/api-prema2/workers', employee, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var employee2 = res.data
    
            var auditLog = {
              objectId: employee2.id.toString(),
              createdAt: employee2.createdAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: localStorage.getItem('userId'),
              componentId: AppService.componentWorker,
              logTypeId: AppService.logTypeCreate
            }
    
            return AppService.postAuditLog(auditLog).then(() => {
              if (employee.workerRoles) {
                employee.workerRoles.forEach(workerRole => {
                  var workerRoleToWorker = {
                    workerRoleId: workerRole.id,
                    workerId: employee2.id
                  }
    
                  return $http.post('/api-prema2/workerRoleToWorkers', workerRoleToWorker, { headers: { 'Authorization': localStorage.getItem('token') } })
                })
              }
            })
          })
        })
      })
    },
    putEmployee: function(employee) {
      employee.contact.firstName = employee.contact.displayName
      
      return $http.get('/api-prema2/contactToMedias?filter[where][contactId]=' + employee.contact.id).then(res => {
        var contactToMedias = res.data

        var diffs = [].concat(employee.contact.medias.filter(obj1 => contactToMedias.every(obj2 => obj1.id !== obj2.id)), contactToMedias.filter(obj2 => employee.contact.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!contactToMedias.includes(media)) {
            var contactToMedia = {
              order: media.order,
              contactId: employee.contact.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/contactToMedias', contactToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/contactToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.get('/api-prema2/workerRoleToWorkers?filter[where][workerId]=' + employee.id).then(res => {
          var workerRoleToWorkers = res.data

          if (workerRoleToWorkers.length > 0) {

            var diffs = [].concat(employee.workerRoles.filter(obj1 => workerRoleToWorkers.every(obj2 => obj1.id !== obj2.workerRoleId)), workerRoleToWorkers.filter(obj2 => employee.workerRoles.every(obj1 => obj2.workerRoleId !== obj1.id)))
            var added = diffs.filter(diff => diff.role)
            var removed = diffs.filter(diff => diff.workerRoleId)
            if (added.length > 0) added.forEach(workerRole => {
              var workerRoleToWorker = {
                workerRoleId: workerRole.id,
                workerId: employee.id
              }

              $http.post('/api-prema2/workerRoleToWorkers', workerRoleToWorker, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
            if (removed.length > 0) removed.forEach(workerRoleToWorker => {
              $http.delete('/api-prema2/workerRoleToWorkers/' + workerRoleToWorker.id, { headers: { 'Authorization': localStorage.getItem('token') } })
            })

          }

          return $http.put('/api-prema2/contacts/' + employee.contact.id, employee.contact, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var contact = res.data

            var name = contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName
            employee.slug = AppService.toSlug(name)
            employee.updatedAt = new Date()
            employee.updatedById = localStorage.getItem('userId')

            return $http.put('/api-prema2/workers/' + employee.id, employee, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
              var employee2 = res.data
      
              var auditLog = {
                objectId: employee2.id.toString(),
                createdAt: employee2.updatedAt,
                profileId: sessionStorage.getItem('profileId'),
                createdById: employee2.updatedById,
                componentId: AppService.componentWorker,
                logTypeId: AppService.logTypeUpdate
              }
      
              return AppService.postAuditLog(auditLog)
            })
          })
        })
      })
    },
    deleteEmployee: function(employee) {
      if (employee.isSysUser && employee.sysUserId) AuthService.deleteSysUser(employee.sysUser)
      $http.delete('/api-prema2/contacts/' + employee.contact.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      return $http.delete('/api-prema2/workers/' + employee.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getWorkers: function(profileId) {
      return $http.get('/api-prema2/profiles/' + profileId + '/workers?filter={"include":["workerRoles",{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}]}')
    },
    getWorkerRoles: function() {
      return $http.get('/api-prema2/workerRoles')
    },
    putProfile: function(profile) {
      return $http.get('/api-prema2/profileToMedias?filter[where][profileId]=' + profile.id).then(res => {
        var profileToMedias = res.data

        var diffs = [].concat(profile.medias.filter(obj1 => profileToMedias.every(obj2 => obj1.id !== obj2.id)), profileToMedias.filter(obj2 => profile.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!profileToMedias.includes(media)) {
            var profileToMedia = {
              order: media.order,
              profileId: profile.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/profileToMedias', profileToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/profileToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.put('/api-prema2/profiles/' + profile.id, profile, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    }
  }
}