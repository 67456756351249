SubscriptionService.$inject = ['$http', 'AppService']

export default function SubscriptionService($http, AppService) {
  return {
    getSubscriptions: function(profileId) {
      return $http.get('/api-prema2/subscriptions?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"order ASC"}').then(res => {
        var subscriptions = res.data

        subscriptions.forEach(subscription => {
          if (subscription.medias) subscription.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getSubscription: function(profileId, slug) {
      return $http.get('/api-prema2/subscriptions/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"recurrencePattern"]}').then(res => {
        var subscription = res.data

        if (subscription.medias) subscription.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getSubscriptionById: function(subscriptionId) {
      return $http.get('/api-prema2/subscriptions/' + subscriptionId)
    },
    patchSubscription: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/subscriptions/' + id, data, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var subscription = res.data

        var auditLog = {
          objectId: subscription.id.toString(),
          createdAt: subscription.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentSubscription,
          logTypeId: AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    initSubscription: function() {
      var subscription = {
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      $http.get('/api-prema2/subscriptions/count?where[profileId]=' + sessionStorage.getItem('profileId')).then(res => {
        var subscriptions = res.data
        subscription.order = subscriptions.count + 1
      })

      return subscription
    },
    postSubscription: function(subscription) {
      subscription.slug = AppService.toSlug(subscription.subscription)
      subscription.createdAt = new Date()
      subscription.updatedAt = new Date()
      
      return $http.post('/api-prema2/subscriptions', subscription, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var subscription2 = res.data

        if (subscription.medias) {
          subscription.medias.forEach(media => {
            var subscriptionToMedia = {
              order: media.order,
              subscriptionId: subscription2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/subscriptionToMedias', subscriptionToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: subscription2.id.toString(),
          createdAt: subscription2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentSubscription,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    putSubscription: function(subscription) {
      subscription.slug = AppService.toSlug(subscription.subscription)
      subscription.updatedAt = new Date()
      subscription.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/subscriptionToMedias?filter[where][subscriptionId]=' + subscription.id).then(res => {
        var subscriptionToMedias = res.data

        var diffs = [].concat(subscription.medias.filter(obj1 => subscriptionToMedias.every(obj2 => obj1.id !== obj2.id)), subscriptionToMedias.filter(obj2 => subscription.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!subscriptionToMedias.includes(media)) {
            var subscriptionToMedia = {
              order: media.order,
              subscriptionId: subscription.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/subscriptionToMedias', subscriptionToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/subscriptionToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.put('/api-prema2/subscriptions/' + subscription.id, subscription, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var subscription2 = res.data
  
          var auditLog = {
            objectId: subscription2.id.toString(),
            createdAt: subscription2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: subscription2.updatedById,
            componentId: AppService.componentSubscription,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    deleteSubscription: function(subscription) {
      return $http.delete('/api-prema2/subscriptions/' + subscription.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}