eventForm.$inject = ['$rootScope', 'EventService', 'AppService', 'CorporateService', '$timeout', '$uibModal']

export default function eventForm($rootScope, EventService, AppService, CorporateService, $timeout, $uibModal) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      event: '=',
      currency: '=',
      activeTab: '='
    },
    link: function(scope, elem, attrs) {
      scope.$parent[attrs.property] = this
      this.isPristine = () => scope.eventForm.$pristine

      scope.filePath = $rootScope.filePath
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)
      AppService.getRecurrencePatterns().then(res => scope.recurrencePatterns = res.data)
      EventService.getEventCategories(sessionStorage.getItem('profileId')).then(res => scope.eventCategories = res.data)
      CorporateService.getTeam(sessionStorage.getItem('profileId')).then(res => scope.workers = res.data)
      scope.startDate = false
      scope.endDate = false
      scope.recurrenceEndDate = false
      scope.ticketTypeEndDate = false
      scope.passTypeEndDate = false
      scope.startDateOptions = {
        startingDay: 1
      }
      scope.endDateOptions = {
        minDate: new Date(),
        startingDay: 1
      }
      scope.recurrenceEndDateOptions = {
        startingDay: 1
      }
      scope.ticketTypeEndDateOptions = {
        startingDay: 1
      }
      scope.passTypeEndDateOptions = {
        startingDay: 1
      }
      scope.timeOptions = {
        showMeridian: false,
        minuteStep: 5
      }
      
      scope.uploadMedias = function(files, placement, invalidFiles) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!scope.event.medias) scope.event.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingEventToMediasSamePlacement = scope.event.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newEventToMediaSamePlacement = {
                order: existingEventToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newEventToMediaSamePlacement.originalMedia = originalMedia
              scope.event.medias.push(newEventToMediaSamePlacement)
              if (existingEventToMediasSamePlacement.length === 1 && existingEventToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = scope.event.medias.findIndex(media => media === existingEventToMediasSamePlacement[0], 1)
                scope.event.medias.splice(index, 1)
                newEventToMediaSamePlacement.order = newEventToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.isAllDay = function() {
        if (!scope.event.startDateUtc) {
          scope.event.startDateUtc = new Date()
        }
        if (!scope.event.endDateUtc) {
          scope.event.endDateUtc = new Date()
        }
        scope.event.startDateUtc.setUTCHours(0, 0, 0, 0)
        scope.event.startDateUtc = new Date(scope.event.startDateUtc)
        scope.event.endDateUtc.setUTCDate(scope.event.startDateUtc.getUTCDate() + 1)
        scope.event.endDateUtc.setUTCHours(0, 0, 0, 0)
        scope.event.endDateUtc = new Date(scope.event.endDateUtc)
      }
      scope.toggleStartDate = function() {
        if (scope.startDate) {
          if (scope.event.startDateUtc) {
            scope.event.endDateUtc = angular.copy(scope.event.startDateUtc)
            scope.event.endDateUtc.setUTCHours(scope.event.endDateUtc.getUTCHours() + 1)

            var endDateMinDate = angular.copy(scope.event.startDateUtc)
            scope.endDateOptions.minDate = new Date(new Date(endDateMinDate.setDate(endDateMinDate.getUTCDate())).setHours(endDateMinDate.getUTCHours()))
          }
        }
        scope.startDate = !scope.startDate
      }
      scope.toggleEndDate = function() {
        scope.endDate = !scope.endDate
      }
      scope.toggleRecurrenceEndDate = function() {
        scope.recurrenceEndDate = !scope.recurrenceEndDate
      }
      scope.addTicketType = function() {
        if (!scope.event.eventTicketTypes) scope.event.eventTicketTypes = new Array()
        scope.event.eventTicketTypes.push({
          order: scope.event.eventTicketTypes.length + 1
        })
      }
      scope.toggleTicketTypeEndDate = function() {
        scope.ticketTypeEndDate = !scope.ticketTypeEndDate
      }
      scope.addPassType = function() {
        if (!scope.event.eventPassTypes) scope.event.eventPassTypes = new Array()
        scope.event.eventPassTypes.push({
          order: scope.event.eventPassTypes.length + 1
        })
      }
      scope.togglePassTypeEndDate = function() {
        scope.passTypeEndDate = !scope.passTypeEndDate
      }
      scope.addExternalResource = function() {
        $uibModal.open({
          controller: 'ModalExternalResourceController',
          controllerAs: 'modalExternalResource',
          template: require('../../app/external-resource-modal-new.html')
        }).result.then(externalResource => {
          if (externalResource) {
            scope.event.externalResources.push(externalResource)
          }
        })
      }
      scope.removeMedia = function(media) {
        var index = scope.event.medias.findIndex(media2 => media2.id === media.id)
        scope.event.medias.splice(index, 1)
      }
      scope.deleteTicketType = function(eventTicketType) {
        var higherIndexes = scope.event.eventTicketTypes.filter((eventTicketType2, index) => index > scope.event.eventTicketTypes.indexOf(eventTicketType))
        higherIndexes.forEach(eventTicketType => eventTicketType.order = eventTicketType.order - 1)

        var index = scope.event.eventTicketTypes.indexOf(eventTicketType)
        scope.event.eventTicketTypes.splice(index, 1)
      }
      scope.deletePassType = function(eventPassType) {
        var higherIndexes = scope.event.eventPassTypes.filter((eventPassType2, index) => index > scope.event.eventPassTypes.indexOf(eventPassType))
        higherIndexes.forEach(eventPassType => eventPassType.order = eventPassType.order - 1)

        var index = scope.event.eventPassTypes.indexOf(eventPassType)
        scope.event.eventPassTypes.splice(index, 1)
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-event.html')
  }

  return directive
}