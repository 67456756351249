import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './order.routes'
import OrderService from './order.service'
import DeliverySlideoutDirective from './delivery-slideout.directive'
import BasketController from './basket.controller'
import CheckoutController from './checkout.controller'
import DeliveryInformationController from './delivery-information.controller'
import PaymentMethodsController from './payment-methods.controller'
import OrdersController from './orders.controller'
import OrderController from './order.controller'
import './order.less'

export default angular.module('app.order', [uirouter])
  .config(routing)
  .service('OrderService', OrderService)
  .directive('deliverySlideout', DeliverySlideoutDirective)
  .controller('BasketController', BasketController)
  .controller('CheckoutController', CheckoutController)
  .controller('DeliveryInformationController', DeliveryInformationController)
  .controller('PaymentMethodsController', PaymentMethodsController)
  .controller('OrdersController', OrdersController)
  .controller('OrderController', OrderController)
  .name