import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './me.routes'
import MeService from './me.service'
import MeController from './me.controller'
import MeOrdersController from './orders.controller'
import MeLocationsController from './locations.controller'
import MeCommunicationsController from './communications.controller'
import './me.less'

export default angular.module('app.me', [uirouter])
  .config(routing)
  .service('MeService', MeService)
  .controller('MeController', MeController)
  .controller('MeOrdersController', MeOrdersController)
  .controller('MeLocationsController', MeLocationsController)
  .controller('MeCommunicationsController', MeCommunicationsController)
  .name