MeLocationsController.$inject = ['AuthService']

export default function MeLocationsController(AuthService) {
  var vm = this

  AuthService.getCurrentUser().then(res => {
    vm.currentUser = res.data

    if (vm.currentUser.contact.contactType.type === 'Customer') {
      vm.location = vm.currentUser.contact.locations.find(location => location.location === 'Default')
      vm.shippingLocation = vm.currentUser.contact.locations.find(location => location.location === 'Shipping')
      vm.isSameShippingLocation = vm.shippingLocation ? false : true
    } else if (vm.currentUser.contact.contactType.type === 'Owner' || vm.currentUser.contact.contactType.type === 'Employee') {
      vm.location = vm.currentUser.profile.location
      vm.isSameShippingLocation = true
    }
  })

  vm.updateLocations = function() {
    
  }
}