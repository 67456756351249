MembershipController.$inject = ['membership', 'MembershipService', 'AppService', 'ServiceService', '$stateParams', '$uibModal', '$rootScope', '$transitions']

export default function MembershipController(membership, MembershipService, AppService, ServiceService, $stateParams, $uibModal, $rootScope, $transitions) {
  var vm = this

  function getPaymentsById(membership) {
    var payments = membership.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => payment.object = res.data))
  }

  function init(membership) {
    if (membership.contact && membership.contact.communications) membership.contact.communications.email = membership.contact.communications.find(communication => communication.method === 'Email') ? membership.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (membership.activities.length > 0) getPaymentsById(membership)

    vm.membership = membership
    copy = angular.copy(vm.membership)
    vm.membership.startDateUtc = new Date(vm.membership.startDateUtc)
    copy.startDateUtc = new Date(copy.startDateUtc)
  }

  function getMembership() {
    MembershipService.getMembership(membership.referenceNo).then(res => init(res.data))
  }

  function calcMembershipNetValue() {
    vm.membership.grossValue = vm.membership.membershipLineItems.map(membershipLineItem => membershipLineItem.totalValue).reduce((a, c) => a + c, 0)
    vm.membership.netValue = vm.membership.discountValue ? vm.membership.grossValue - vm.membership.discountValue : vm.membership.grossValue
  }

  var copy
  if (!membership.isRead) membership.isRead = true, MembershipService.patchMembership(membership.id, 'isRead', true)
  init(membership)
  MembershipService.getMembershipStatuses().then(res => vm.membershipStatuses = res.data)
  MembershipService.getMembershipTypes().then(res => vm.membershipTypes = res.data)
  AppService.getPaymentTypes().then(res => vm.paymentTypes = res.data)
  AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => vm.profilePaymentMethods = res.data)
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)
  AppService.getRecurrencePatterns().then(res => vm.recurrencePatterns = res.data)
  AppService.getLeadSources(sessionStorage.getItem('profileId')).then(res => vm.leadSources = res.data)
  ServiceService.getSubscriptions(sessionStorage.getItem('profileId')).then(res => {
    vm.subscriptions = res.data
    vm.subscriptions.forEach(subscription => subscription.subscriptionNo = subscription.subscriptionNo ? subscription.subscriptionNo : '')
  })
  vm.startDate = false
  vm.startDateOptions = {
    startingDay: 1
  }
  vm.timeOptions = {
    showMeridian: false,
    minuteStep: 5
  }

  vm.toggleAddPayment = function() {
    vm.addPayment = !vm.addPayment
  }
  vm.getPaymentsById = function(membership) {
    getPaymentsById(membership)
  }
  vm.setMembershipStatus = function(membershipStatusId) {
    vm.membership.membershipStatusId = membershipStatusId
    vm.membership.membershipStatus = vm.membershipStatuses.find(membershipStatus => membershipStatus.id === membershipStatusId)
  }
  vm.tabs = [0, 1, 2]
  vm.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.toggleStartDate = function() {
    vm.startDate = !vm.startDate
  }
  vm.setMembershipNetValue = function() {
    calcMembershipNetValue()
  }
  vm.setMembershipContactEmail = function() {
    if (!vm.membership.contact.communications) vm.membership.contact.communications = new Array()
    vm.membership.contact.communications.push({
      order: vm.membership.contact.communications.length + 1,
      isPrimary: vm.membership.contact.communications.length === 0 ? true : undefined,
      method: 'Email',
      value: vm.email
    })
  }
  vm.showNewContactModal = function() {
    $uibModal.open({
      template: require('../../app/contact-modal-new.html')
    }).result.then(contact => {
      vm.membership.contactId = contact.id, vm.membership.contact = contact
      if (vm.membership.contact && vm.membership.contact.communications) vm.membership.contact.communications.email = vm.membership.contact.communications.find(communication => communication.method === 'Email') ? vm.membership.contact.communications.find(communication => communication.method === 'Email').value : undefined
      var name = vm.membership.contact.name = vm.membership.contact.lastName ? vm.membership.contact.firstName + ' ' + vm.membership.contact.lastName : vm.membership.contact.firstName
      $rootScope.pageTitle = $rootScope.pageTitle + ' ' + name
    })
  }
  vm.setMembershipLineItemSubscription = function(membershipLineItem) {
    var subscription = vm.subscriptions.find(subscription => subscription.id === membershipLineItem.subscription.id)
    membershipLineItem.subscription.subscription = subscription.subscription
    membershipLineItem.subscription.unitSalePrice = subscription.unitSalePrice
    if (membershipLineItem.unitSalePrice) membershipLineItem.subscription.unitSalePrice = membershipLineItem.unitSalePrice
    membershipLineItem.count = membershipLineItem.count ? membershipLineItem.count : 1
    membershipLineItem.totalValue = membershipLineItem.subscription.unitSalePrice * membershipLineItem.count
    calcMembershipNetValue()
  }
  vm.addMembershipLineItem = function() {
    if (!vm.membership.membershipLineItems) vm.membership.membershipLineItems = new Array()
    vm.membership.membershipLineItems.push({})
  }
  vm.deleteMembershipLineItem = function(index) {
    vm.membership.membershipLineItems.splice(index, 1)
    calcMembershipNetValue()
  }
  vm.setPaymentStatus = function(payment) {
    var type = vm.paymentTypes.find(paymentType => paymentType.id === payment.paymentTypeId).type
    
    payment.paymentStatusId = type === 'Payment in full' || type === 'Balance' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Paid').id : type === 'Pay on arrival' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Pending').id : type === 'No payment required' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'No payment required').id : vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Partially paid').id
  }
  vm.updatePayment = function(activity, payment) {
    AppService.putPayment(activity, payment).then(() => getPaymentsById(vm.membership))
  }
  $transitions.onBefore({}, () => {
    vm.membership.activities.forEach(activity => delete activity.object), copy.activities.forEach(activity => delete activity.object)
    if (!angular.equals(vm.membership, copy)) return MembershipService.putMembership(vm.membership)
  }, {invokeLimit: 1})
}