ArticleService.$inject = ['$http', 'AppService']

export default function ArticleService($http, AppService) {
  return {
    getArticleCategories: function(profileId) {
      return $http.get('/api-prema2/postCategories?filter[where][profileId]=' + profileId + '&filter[order]=order%20ASC')
    },
    putArticleCategory: function(articleCategory) {
      articleCategory.slug = AppService.toSlug(articleCategory.category)
      articleCategory.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/postCategories', articleCategory, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteArticleCategory: function(articleCategory) {
      return $http.delete('/api-prema2/postCategories/' + articleCategory.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getArticles: function(profileId) {
      return $http.get('/api-prema2/posts?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"createdBy","scope":{"include":"contact"}}],"order":"createdAt DESC"}').then(res => {
        var articles = res.data

        articles.forEach(article => {
          if (article.medias) article.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getArticle: function(profileId, slug) {
      return $http.get('/api-prema2/posts/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},"postCategories"]}').then(res => {
        var article = res.data

        if (article.medias) article.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getArticleById: function(articleId) {
      return $http.get('/api-prema2/posts/' + articleId)
    },
    initArticle: function() {
      var article = {
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return article
    },
    postArticle: function(article) {
      article.slug = AppService.toSlug(article.post)
      article.createdAt = new Date()
      article.updatedAt = new Date()
      
      return $http.post('/api-prema2/posts', article, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var article2 = res.data

        if (article.medias) {
          article.medias.forEach(media => {
            var articleToMedia = {
              order: media.order,
              postId: article2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/postToMedias', articleToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: article2.id.toString(),
          createdAt: article2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentPost,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog).then(() => {
          if (article.postCategories) {
            article.postCategories.forEach(postCategory => {
              var postCategoryToPost = {
                postCategoryId: postCategory.id,
                postId: article2.id
              }
  
              return $http.post('/api-prema2/postCategoryToPosts', postCategoryToPost, { headers: { 'Authorization': localStorage.getItem('token') } })
            })
          }
        })
      })
    },
    putArticle: function(article) {
      article.slug = AppService.toSlug(article.post)
      article.updatedAt = new Date()
      article.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/postToMedias?filter[where][postId]=' + article.id).then(res => {
        var articleToMedias = res.data

        var diffs = [].concat(article.medias.filter(obj1 => articleToMedias.every(obj2 => obj1.id !== obj2.id)), articleToMedias.filter(obj2 => article.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!articleToMedias.includes(media)) {
            var articleToMedia = {
              order: media.order,
              postId: article.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/postToMedias', articleToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/postToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.get('/api-prema2/postCategoryToPosts?filter[where][postId]=' + article.id).then(res => {
          var postCategoryToPosts = res.data

          var diffs = [].concat(article.postCategories.filter(obj1 => postCategoryToPosts.every(obj2 => obj1.id !== obj2.postCategoryId)), postCategoryToPosts.filter(obj2 => article.postCategories.every(obj1 => obj2.postCategoryId !== obj1.id)))
          var added = diffs.filter(diff => diff.category)
          var removed = diffs.filter(diff => diff.postCategoryId)
          if (added.length > 0) added.forEach(postCategory => {
            var postCategoryToPost = {
              postCategoryId: postCategory.id,
              postId: article.id
            }

            $http.post('/api-prema2/postCategoryToPosts', postCategoryToPost, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
          if (removed.length > 0) removed.forEach(postCategoryToPost => {
            $http.delete('/api-prema2/postCategoryToPosts/' + postCategoryToPost.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          })

          return $http.put('/api-prema2/posts/' + article.id, article, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var article2 = res.data
    
            var auditLog = {
              objectId: article2.id.toString(),
              createdAt: article2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: article2.updatedById,
              componentId: AppService.componentPost,
              logTypeId: AppService.logTypeUpdate
            }
    
            return AppService.postAuditLog(auditLog)
          })
        })
      })
    },
    deleteArticle: function(article) {
      return new Promise((resolve, reject) => {
        if (article.articleCategories.length) {
          article.articleCategories.forEach(articleCategory => $http.get('/api-prema2/postCategoryToPosts/findOne?filter[where][postCategoryId]=' + articleCategory.id + '&filter[where][articleId]=' + article.id).then(res => {
            var postCategoryToPost = res.data

            $http.delete('/api-prema2/postCategoryToPosts/' + postCategoryToPost.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => resolve())
          }))
        } else {
          resolve()
        }
      }).then(() => {
        return $http.delete('/api-prema2/posts/' + article.id, { headers: { 'Authorization': localStorage.getItem('token') } })
      })
    }
  }
}