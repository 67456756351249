aboutForm.$inject = ['CorporateService', 'AppService', '$rootScope', '$timeout', '$uibModal']

export default function aboutForm(CorporateService, AppService, $rootScope, $timeout, $uibModal) {
  var directive = {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.filePath = $rootScope.filePath
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)
      CorporateService.getBusinessEntities().then(res => scope.businessEntities = res.data)

      scope.uploadMedias = function(files, placement, invalidFiles) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!scope.profile.medias) scope.profile.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingProfileToMediasSamePlacement = scope.profile.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newProfileToMediaSamePlacement = {
                order: existingProfileToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newProfileToMediaSamePlacement.originalMedia = originalMedia
              scope.profile.medias.push(newProfileToMediaSamePlacement)
              if (existingProfileToMediasSamePlacement.length === 1 && existingProfileToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = scope.profile.medias.findIndex(media => media === existingProfileToMediasSamePlacement[0], 1)
                scope.profile.medias.splice(index, 1)
                newProfileToMediaSamePlacement.order = newProfileToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.removeMedias = function(medias) {
        medias.forEach(media => {
          var index = scope.profile.medias.findIndex(media2 => media2.id === media.id)
          scope.profile.medias.splice(index, 1)
        })
      }
      scope.showAccreditationModal = function() {
        $uibModal.open({
          controller: 'ModalAccreditationController',
          controllerAs: 'modalAccreditation',
          template: require('./accreditation-modal-new.html')
        }).result.then(accreditation => {
          if (accreditation) {
            if (!accreditation.medias) accreditation.medias = new Array()
            AppService.getMediaById(accreditation.mediaId).then(res => accreditation.medias.push(res.data))
            
            if (!scope.profile.accreditations) scope.profile.accreditations = new Array()
            scope.profile.accreditations.push(accreditation)
          }
        })
      }
      scope.removeAccreditation = function(accreditation) {
        var index = scope.profile.accreditations.findIndex(accreditation2 => accreditation2.id === accreditation.id)
        scope.profile.accreditations.splice(index, 1)
      }
      scope.addSocial = function() {
        if (!scope.profile.socials) scope.profile.socials = new Array()
        scope.profile.socials.push({
          order: scope.profile.socials.length + 1
        })
      }
      scope.deleteSocial = function(index) {
        scope.profile.socials.splice(index, 1)
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-about.html')
  }

  return directive
}