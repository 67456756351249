CourseService.$inject = ['$http', 'AppService']

export default function CourseService($http, AppService) {
  return {
    getCourses: function(profileId) {
      return $http.get('/api-prema2/courses?filter={"where":{"profileId":"' + profileId + '"},"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}],"order":"course ASC"}').then(res => {
        var courses = res.data

        courses.forEach(course => {
          if (course.medias) course.medias.forEach(media => {
            if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
              media.originalMedia = media.media
              media.media = res.data
            })
          })
        })

        return res
      })
    },
    getCourse: function(profileId, slug) {
      return $http.get('/api-prema2/courses/findOne?filter={"where":{"profileId":"' + profileId + '","slug":"' + slug + '"},"include":["durationUnit",{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"lessons","scope":{"include":"durationUnit"}}]}').then(res => {
        var course = res.data

        if (course.medias) course.medias.forEach(media => {
          if (media.media.type.includes('video')) AppService.getMediaById(media.media.attrs.mediaId).then(res => {
            media.originalMedia = media.media
            media.media = res.data
          })
        })

        return res
      })
    },
    getCourseById: function(courseId) {
      return $http.get('/api-prema2/courses/' + courseId)
    },
    initCourse: function() {
      var course = {
        isPublished: true,
        profileId: sessionStorage.getItem('profileId'),
        createdById: localStorage.getItem('userId'),
        updatedById: localStorage.getItem('userId')
      }

      return course
    },
    postCourse: function(course) {
      course.slug = AppService.toSlug(course.course)
      course.createdAt = new Date()
      course.updatedAt = new Date()
      
      return $http.post('/api-prema2/courses', course, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var course2 = res.data

        if (course.medias) {
          course.medias.forEach(media => {
            var courseToMedia = {
              order: media.order,
              courseId: course2.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }

            return $http.post('/api-prema2/courseToMedias', courseToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        if (course.lessons) {
          course.lessons.forEach((lesson, index) => {
            lesson.slug = AppService.toSlug(lesson.lesson)
            lesson.order = index + 1
            lesson.isPublished = true
            lesson.createdAt = new Date()
            lesson.updatedAt = new Date()
            lesson.profileId = sessionStorage.getItem('profileId')
            lesson.createdById = localStorage.getItem('userId')
            lesson.updatedById = localStorage.getItem('userId')
            lesson.courseId = course2.id

            return $http.post('/api-prema2/lessons', lesson, { headers: { 'Authorization': localStorage.getItem('token') } })
          })
        }

        var auditLog = {
          objectId: course2.id.toString(),
          createdAt: course2.createdAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentCourse,
          logTypeId: AppService.logTypeCreate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    putCourse: function(course) {
      course.slug = AppService.toSlug(course.course)
      course.updatedAt = new Date()
      course.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/courseToMedias?filter[where][courseId]=' + course.id).then(res => {
        var courseToMedias = res.data

        var diffs = [].concat(course.medias.filter(obj1 => courseToMedias.every(obj2 => obj1.id !== obj2.id)), courseToMedias.filter(obj2 => course.medias.every(obj1 => obj2.id !== obj1.id)))
        if (diffs.length > 0) diffs.forEach(media => {
          if (!courseToMedias.includes(media)) {
            var courseToMedia = {
              order: media.order,
              courseId: course.id,
              mediaId: media.mediaId,
              mediaPlacementId: media.mediaPlacementId
            }
  
            return $http.post('/api-prema2/courseToMedias', courseToMedia, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            return $http.delete('/api-prema2/courseToMedias/' + media.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        return $http.get('/api-prema2/lessons?filter[where][courseId]=' + course.id).then(res => {
          var lessons = res.data
  
          var diffs = [].concat(course.lessons.filter(obj1 => lessons.every(obj2 => obj1.id !== obj2.id)), lessons.filter(obj2 => course.lessons.every(obj1 => obj2.id !== obj1.id)))
          if (diffs.length > 0) diffs.forEach(lesson => {
            if (!lessons.includes(lesson)) {
              lesson.slug = AppService.toSlug(lesson.lesson)
              lesson.isPublished = true
              lesson.createdAt = new Date()
              lesson.updatedAt = new Date()
              lesson.profileId = sessionStorage.getItem('profileId')
              lesson.createdById = localStorage.getItem('userId')
              lesson.updatedById = localStorage.getItem('userId')
              lesson.courseId = course.id

              return $http.post('/api-prema2/lessons', lesson, { headers: { 'Authorization': localStorage.getItem('token') } })
            } else {
              return $http.delete('/api-prema2/lessons/' + lesson.id, { headers: { 'Authorization': localStorage.getItem('token') } })
            }
          })

          return $http.put('/api-prema2/courses/' + course.id, course, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
            var course2 = res.data
    
            var auditLog = {
              objectId: course2.id.toString(),
              createdAt: course2.updatedAt,
              profileId: sessionStorage.getItem('profileId'),
              createdById: course2.updatedById,
              componentId: AppService.componentCourse,
              logTypeId: AppService.logTypeUpdate
            }
    
            return AppService.postAuditLog(auditLog)
          })
        })
      })
    },
    deleteCourse: function(course) {
      return new Promise((resolve, reject) => {
        if (course.lessons) {
          course.lessons.forEach(lesson => $http.delete('/api-prema2/lessons/' + lesson.id, { headers: { 'Authorization': localStorage.getItem('token') } }).then(() => resolve()))
        } else {
          resolve()
        }
      }).then(() => $http.delete('/api-prema2/courses/' + course.id, { headers: { 'Authorization': localStorage.getItem('token') } }))
    },
    postLesson: function(lesson) {
      lesson.slug = AppService.toSlug(lesson.lesson)
      lesson.isPublished = true
      lesson.createdAt = new Date()
      lesson.updatedAt = new Date()
      lesson.profileId = sessionStorage.getItem('profileId')
      lesson.createdById = localStorage.getItem('userId')
      lesson.updatedById = localStorage.getItem('userId')

      return $http.post('/api-prema2/lessons', lesson, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}