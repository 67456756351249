routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('login', {
      url: '/sign-in',
      template: require('./login.html'),
      controller: 'AuthController',
      controllerAs: 'auth',
      resolve: {
        $title: function() {
          return 'Sign in'
        }
      }
    })
    .state('register', {
      url: '/sign-up',
      template: require('./register.html'),
      controller: 'AuthController',
      controllerAs: 'auth',
      resolve: {
        $title: function() {
          return 'Create your account'
        }
      }
    })
    .state('resetPassword', {
      url: '/reset-password',
      template: require('./reset-password.html'),
      controller: 'AuthController',
      controllerAs: 'auth',
      resolve: {
        $title: function() {
          return 'Reset your password'
        }
      }
    })
    .state('unauthorised', {
      url: '/oops',
      template: require('./unauthorised.html'),
      controller: 'AuthController',
      controllerAs: 'auth',
      resolve: {
        $title: function() {
          return 'Oops!'
        }
      }
    })
}