OrderController.$inject = ['order', 'OrderService', 'AppService', 'ProductService', '$stateParams', '$uibModal', '$transitions']

export default function OrderController(order, OrderService, AppService, ProductService, $stateParams, $uibModal, $transitions) {
  var vm = this

  function getPaymentsById(order) {
    var payments = order.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => payment.object = res.data))
  }

  function init(order) {
    if (order.locations) {
      order.locations.primary = order.locations.find(location => location.isPrimary)
      order.locations.alt = order.locations.find(location => !location.isPrimary)
    }
    if (order.contact && order.contact.communications) order.contact.communications.email = order.contact.communications.find(communication => communication.method === 'Email') ? order.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (order.activities.length > 0) getPaymentsById(order)

    vm.order = order
    copy = angular.copy(vm.order)
  }

  function getOrder() {
    OrderService.getOrder(order.referenceNo).then(res => init(res.data))
  }

  function calcOrderNetValue() {
    vm.order.grossValue = vm.order.orderLineItems.map(orderLineItem => orderLineItem.totalValue).reduce((a, c) => a + c, 0)
    vm.order.netValue = vm.order.discountValue ? vm.order.grossValue - vm.order.discountValue : vm.order.grossValue
  }

  var copy
  if (!order.isRead) order.isRead = true, OrderService.patchOrder(order.id, 'isRead', true)
  init(order)
  OrderService.getOrderStatuses().then(res => vm.orderStatuses = res.data)
  AppService.getPaymentTypes().then(res => vm.paymentTypes = res.data)
  AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => vm.profilePaymentMethods = res.data)
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)
  AppService.getRecurrencePatterns().then(res => vm.recurrencePatterns = res.data)
  AppService.getLeadSources(sessionStorage.getItem('profileId')).then(res => vm.leadSources = res.data)
  ProductService.getProducts(sessionStorage.getItem('profileId')).then(res => {
    vm.products = res.data
    vm.products.forEach(product => product.productNo = product.productNo ? product.productNo : '')
  })

  vm.toggleAddPayment = function() {
    vm.addPayment = !vm.addPayment
  }
  vm.getPaymentsById = function(order) {
    getPaymentsById(order)
  }
  vm.setOrderStatus = function(orderStatusId) {
    vm.order.orderStatusId = orderStatusId
    vm.order.orderStatus = vm.orderStatuses.find(orderStatus => orderStatus.id === orderStatusId)
  }
  vm.tabs = [0, 1, 2]
  vm.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.setOrderNetValue = function() {
    calcOrderNetValue()
  }
  vm.setOrderContactEmail = function() {
    if (!vm.order.contact.communications) vm.order.contact.communications = new Array()
    vm.order.contact.communications.push({
      order: vm.order.contact.communications.length + 1,
      isPrimary: vm.order.contact.communications.length === 0 ? true : undefined,
      method: 'Email',
      value: vm.email
    })
  }
  vm.showNewContactModal = function() {
    $uibModal.open({
      template: require('../../app/contact-modal-new.html')
    }).result.then(contact => {
      vm.order.contactId = contact.id, vm.order.contact = contact
      
      if (contact.locations || contact.locations.length > 0) {
        if (!vm.order.locations) vm.order.locations = new Array()
        vm.order.locations.push({
          address: contact.locations.find(location => location.isPrimary).address,
          country: contact.locations.find(location => location.isPrimary).country.country,
          notes: contact.locations.find(location => location.isPrimary).notes,
          isPrimary: contact.locations.find(location => location.isPrimary).isPrimary
        })
        vm.order.locations.primary = vm.order.locations.find(location => location.isPrimary)
      }

      if (vm.order.contact && vm.order.contact.communications) vm.order.contact.communications.email = vm.order.contact.communications.find(communication => communication.method === 'Email') ? vm.order.contact.communications.find(communication => communication.method === 'Email').value : undefined
    })
  }
  vm.showNewLocationModal = function(isPrimary) {
    $uibModal.open({
      controller: 'ModalContactLocationController',
      controllerAs: 'modalContactLocation',
      template: require('../../app/contact-location-modal-new.html'),
      resolve: {
        contact: function() {
          return vm.order.contact
        }
      }
    }).result.then(location => {
      if (!vm.order.contact.locations) vm.order.contact.locations = new Array()
      var matchContactLocation = vm.order.contact.locations.some(contactLocation => contactLocation.address.street1 === location.address.street1)
      if (!matchContactLocation) vm.order.contact.locations.push(location)

      var orderLocation = {
        address: location.address,
        country: location.country.country,
        notes: location.notes,
        isPrimary: isPrimary
      }
      if (!vm.order.locations) vm.order.locations = new Array()
      if (isPrimary) {
        var index = vm.order.locations.findIndex(location => location.isPrimary)
        if (index !== -1) vm.order.locations.splice(index, 2)

        vm.order.locations.push(orderLocation)
        vm.order.locations.primary = orderLocation
        vm.order.locations.alt = orderLocation
        vm.order.isPrimaryLocationForShipping = true
      } else {
        var index = vm.order.locations.findIndex(location => !location.isPrimary)
        if (index !== -1) vm.order.locations.splice(index, 1)

        var matchOrderLocation = vm.order.locations.some(location => location.address.street1 === orderLocation.address.street1)
        var index2 = vm.order.locations.findIndex(location => location.address.street1 === orderLocation.address.street1)
        if (matchOrderLocation) {
          vm.order.locations.splice(index2, 1)
          orderLocation.isPrimary = true
        }

        vm.order.locations.push(orderLocation)
        vm.order.locations.alt = orderLocation
        vm.order.isPrimaryLocationForShipping = matchOrderLocation ? true : false
      }
    })
  }
  vm.setOrderLineItemProduct = function(orderLineItem) {
    var product = vm.products.find(product => product.id === orderLineItem.product.id)
    orderLineItem.product.product = product.product
    orderLineItem.product.unitSalePrice = product.unitSalePrice
    if (orderLineItem.unitSalePrice) orderLineItem.product.unitSalePrice = orderLineItem.unitSalePrice
    orderLineItem.count = orderLineItem.count ? orderLineItem.count : 1
    orderLineItem.totalValue = orderLineItem.product.unitSalePrice * orderLineItem.count
    calcOrderNetValue()
  }
  vm.addOrderLineItem = function() {
    if (!vm.order.orderLineItems) vm.order.orderLineItems = new Array()
    vm.order.orderLineItems.push({})
  }
  vm.deleteOrderLineItem = function(index) {
    vm.order.orderLineItems.splice(index, 1)
    calcOrderNetValue()
  }
  vm.setPaymentStatus = function(payment) {
    var type = vm.paymentTypes.find(paymentType => paymentType.id === payment.paymentTypeId).type
    
    payment.paymentStatusId = type === 'Payment in full' || type === 'Balance' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Paid').id : type === 'Pay on arrival' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Pending').id : type === 'No payment required' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'No payment required').id : vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Partially paid').id
  }
  vm.updatePayment = function(activity, payment) {
    AppService.putPayment(activity, payment).then(() => getPaymentsById(vm.order))
  }
  $transitions.onBefore({}, () => {
    vm.order.activities.forEach(activity => delete activity.object), copy.activities.forEach(activity => delete activity.object)
    if (!angular.equals(vm.order, copy)) return OrderService.putOrder(vm.order)
  }, {invokeLimit: 1})
}