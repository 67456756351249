import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './corporate.routes'
import CorporateService from './corporate.service'
import EmployeeCard from './employee-card.directive'
import EmployeeFormDirective from './employee-form.directive'
import AboutFormDirective from './about-form.directive'
import TermsFormDirective from './terms-form.directive'
import PrivacyFormDirective from './privacy-form.directive'
// import LocationFormDirective from './location-form.directive'
import FaqFormDirective from './faq-form.directive'
import AboutController from './about.controller'
import TeamController from './team.controller'
import TermsController from './terms.controller'
import PrivacyController from './privacy.controller'
import FaqsController from './faqs.controller'
// import LocationsController from './locations.controller'
import EmployeeController from './employee.controller'
import NewEmployeeController from './new-employee.controller'
import ModalAccreditationController from './modal-accreditation.controller'
import './corporate.less'

export default angular.module('app.corporate', [uirouter, EmployeeCard])
  .config(routing)
  .service('CorporateService', CorporateService)
  .directive('employeeForm', EmployeeFormDirective)
  .directive('aboutForm', AboutFormDirective)
  .directive('termsForm', TermsFormDirective)
  .directive('privacyForm', PrivacyFormDirective)
  // .directive('locationForm', LocationFormDirective)
  .directive('faqForm', FaqFormDirective)
  .controller('AboutController', AboutController)
  .controller('TeamController', TeamController)
  .controller('TermsController', TermsController)
  .controller('PrivacyController', PrivacyController)
  .controller('FaqsController', FaqsController)
  // .controller('LocationsController', LocationsController)
  .controller('EmployeeController', EmployeeController)
  .controller('NewEmployeeController', NewEmployeeController)
  .controller('ModalAccreditationController', ModalAccreditationController)
  .name