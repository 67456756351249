import angular from 'angular'

function subscriptionCard() {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.headingClass = attrs.headingClass
      scope.showShortLede = attrs.showShortLede
    },
    template: require('./_card-subscription.html')
  }
}

export default angular.module('directives.subscriptionCard', [])
  .directive('subscriptionCard', subscriptionCard)
  .name