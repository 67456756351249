BookingController.$inject = ['booking', 'EventService', 'AppService', 'ServiceService', 'ProductService', '$stateParams', '$uibModal', '$rootScope', '$transitions']

export default function BookingController(booking, EventService, AppService, ServiceService, ProductService, $stateParams, $uibModal, $rootScope, $transitions) {
  var vm = this

  function htmlDecode(input) {
    var e = document.createElement('div')
    e.innerHTML = input
    return e.childNodes[0].nodeValue
  }

  function getPaymentsById(booking) {
    var payments = booking.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => payment.object = res.data))
  }

  function init(booking) {
    if (booking.contact && booking.contact.communications) booking.contact.communications.email = booking.contact.communications.find(communication => communication.method === 'Email') ? booking.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (booking.activities.length > 0) getPaymentsById(booking)

    vm.booking = booking
    copy = angular.copy(vm.booking)
    vm.booking.startDateUtc = new Date(vm.booking.startDateUtc)
    copy.startDateUtc = new Date(copy.startDateUtc)
  }

  function getBooking() {
    EventService.getBooking(booking.id).then(res => init(res.data))
  }

  function calcBookingNetValue() {
    vm.booking.grossValue = vm.booking.bookingLineItems.map(bookingLineItem => bookingLineItem.totalValue).reduce((a, c) => a + c, 0)
    vm.booking.netValue = vm.booking.discountValue ? vm.booking.grossValue - vm.booking.discountValue : vm.booking.grossValue
  }

  var copy
  if (!booking.isRead) booking.isRead = true, EventService.patchBooking(booking.id, 'isRead', true)
  init(booking)
  EventService.getBookingStatuses().then(res => vm.bookingStatuses = res.data)
  // AppService.getBookingTypes().then(res => vm.bookingTypes = res.data)
  ServiceService.getServices(sessionStorage.getItem('profileId')).then(res => {
    vm.services = res.data

    vm.services = vm.services.filter(service => {
      service.serviceStr = htmlDecode(service.service)

      return service
    })
  })
  AppService.getPaymentTypes().then(res => vm.paymentTypes = res.data)
  AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => vm.profilePaymentMethods = res.data)
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)
  AppService.getRecurrencePatterns().then(res => vm.recurrencePatterns = res.data)
  AppService.getLeadSources(sessionStorage.getItem('profileId')).then(res => vm.leadSources = res.data)
  ProductService.getProducts(sessionStorage.getItem('profileId')).then(res => {
    vm.products = res.data
    vm.products.forEach(product => product.productNo = product.productNo ? product.productNo : '')
  })
  vm.startDate = false
  vm.startDateOptions = {
    startingDay: 1
  }
  vm.timeOptions = {
    showMeridian: false,
    minuteStep: 5
  }

  vm.toggleAddPayment = function() {
    vm.addPayment = !vm.addPayment
  }
  vm.getPaymentsById = function(booking) {
    getPaymentsById(booking)
  }
  vm.setBookingStatus = function(bookingStatusId) {
    vm.booking.bookingStatusId = bookingStatusId
    vm.booking.bookingStatus = vm.bookingStatuses.find(bookingStatus => bookingStatus.id === bookingStatusId)
  }
  vm.tabs = [0, 1, 2]
  vm.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.toggleStartDate = function() {
    vm.startDate = !vm.startDate
  }
  vm.setBookingNetValue = function() {
    calcBookingNetValue()
  }
  vm.setBookingContactEmail = function() {
    if (!vm.booking.contact.communications) vm.booking.contact.communications = new Array()
    vm.booking.contact.communications.push({
      order: vm.booking.contact.communications.length + 1,
      isPrimary: vm.booking.contact.communications.length === 0 ? true : undefined,
      method: 'Email',
      value: vm.email
    })
  }
  vm.showNewContactModal = function() {
    $uibModal.open({
      template: require('../../app/contact-modal-new.html')
    }).result.then(contact => {
      vm.booking.contactId = contact.id, vm.booking.contact = contact
      if (vm.booking.contact && vm.booking.contact.communications) vm.booking.contact.communications.email = vm.booking.contact.communications.find(communication => communication.method === 'Email') ? vm.booking.contact.communications.find(communication => communication.method === 'Email').value : undefined
      var name = vm.booking.contact.name = vm.booking.contact.lastName ? vm.booking.contact.firstName + ' ' + vm.booking.contact.lastName : vm.booking.contact.firstName
      $rootScope.pageTitle = $rootScope.pageTitle + ' ' + name
    })
  }
  vm.setBookingLineItemProduct = function(bookingLineItem) {
    var product = vm.products.find(product => product.id === bookingLineItem.product.id)
    bookingLineItem.product.product = product.product
    bookingLineItem.product.unitSalePrice = product.unitSalePrice
    if (bookingLineItem.unitSalePrice) bookingLineItem.product.unitSalePrice = bookingLineItem.unitSalePrice
    bookingLineItem.count = bookingLineItem.count ? bookingLineItem.count : 1
    bookingLineItem.totalValue = bookingLineItem.product.unitSalePrice * bookingLineItem.count
    calcBookingNetValue()
  }
  vm.addBookingLineItem = function() {
    if (!vm.booking.bookingLineItems) vm.booking.bookingLineItems = new Array()
    vm.booking.bookingLineItems.push({})
  }
  vm.deleteBookingLineItem = function(index) {
    vm.booking.bookingLineItems.splice(index, 1)
    calcBookingNetValue()
  }
  vm.setPaymentStatus = function(payment) {
    var type = vm.paymentTypes.find(paymentType => paymentType.id === payment.paymentTypeId).type
    
    payment.paymentStatusId = type === 'Payment in full' || type === 'Balance' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Paid').id : type === 'Pay on arrival' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Pending').id : type === 'No payment required' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'No payment required').id : vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Partially paid').id
  }
  vm.updatePayment = function(activity, payment) {
    AppService.putPayment(activity, payment).then(() => getPaymentsById(vm.booking))
  }
  $transitions.onBefore({}, () => {
    vm.booking.activities.forEach(activity => delete activity.object), copy.activities.forEach(activity => delete activity.object)
    if (!angular.equals(vm.booking, copy)) return EventService.putBooking(vm.booking)
  }, {invokeLimit: 1})
}