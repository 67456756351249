import angular from 'angular'

bookingForm.$inject = ['$rootScope', 'ServiceService', 'AppService', 'CorporateService', '$state', '$timeout']

function bookingForm($rootScope, ServiceService, AppService, CorporateService, $state, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      function htmlDecode(input) {
        var e = document.createElement('div')
        e.innerHTML = input
        return e.childNodes[0].nodeValue
      }

      scope.booking = new Object()
      ServiceService.getServices(sessionStorage.getItem('profileId')).then(res => {
        scope.services = res.data

        scope.services = scope.services.filter(service => {
          service.serviceStr = htmlDecode(service.service)
  
          return service
        })
      })

      $rootScope.$watch('profile', profile => {
        if (profile !== undefined) {
          scope.startDate = false
          var daysOfTheWeek = {'sun':0, 'mon':1, 'tue':2, 'wed':3, 'thu':4, 'fri':5, 'sat':6}
          var operatingHours = profile.locationPrimary.operatingHours
          if (operatingHours) {
            Object.keys(operatingHours).forEach(key => operatingHours[key] === undefined ? delete operatingHours[key] : {})

            function getAllKeyNames(o, res) {
              Object.keys(o).forEach(function(k){
                if (Object.prototype.toString.call(o[k]) === "[object Object]") {
                  getAllKeyNames(o[k], res)
                } else if (Array.isArray(o[k])) {
                  o[k].forEach(function(v) {
                    getAllKeyNames(v, res)
                  })
                }
                res.add(k)
              })
            }
            
            var o1Keys = new Set(), o2Keys = new Set()
            getAllKeyNames(daysOfTheWeek, o1Keys) // unique keys of object1
            getAllKeyNames(operatingHours, o2Keys) // unique keys of object2
            
            // get a list of all the keys that are in object1, but not in object2
            var diff = [...o1Keys].filter((x) => !o2Keys.has(x))

            var setDaysFromDiffs = []
            diff.forEach(diff => {
              var x
              if (diff === 'sun') x = 0
              if (diff === 'mon') x = 1
              if (diff === 'tue') x = 2
              if (diff === 'wed') x = 3
              if (diff === 'thu') x = 4
              if (diff === 'fri') x = 5
              if (diff === 'sat') x = 6
              setDaysFromDiffs.push(x)
            })
          }

          function disabled(data) {
            var date = data.date, mode = data.mode;
            return mode === 'day' && (setDaysFromDiffs.includes(date.getDay()))
          }
          scope.dateOptions = {
            dateDisabled: operatingHours ? disabled : undefined,
            startingDay: 1
          }
          scope.timeOptions = {
            showMeridian: false,
            minuteStep: 15
          }
          function getAvailableTimes(startDateUtc) {
            const daysOfTheWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
            const hoursOfTheDay = [...Array(24).keys()]
            let day = daysOfTheWeek[startDateUtc.getDay()]
            let availableTimes = hoursOfTheDay.map(String)

            let availableHours
            if (operatingHours) {
              let dayOperatingHour = operatingHours[day]
              let startTimeHour = dayOperatingHour ? dayOperatingHour.startTime.split(':')[0] : undefined
              let endTimeHour = dayOperatingHour ? dayOperatingHour.endTime.split(':')[0] : undefined
              if (endTimeHour == 0) endTimeHour = 24
              availableHours = hoursOfTheDay.slice(0, endTimeHour).slice(startTimeHour)
              availableTimes = availableHours.map(String)
            }

            const arrayRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)
            var minuteStep = profile.settings.bookings.minuteStep ? profile.settings.bookings.minuteStep : 15
            var range = arrayRange(0, 59, minuteStep)

            availableTimes.forEach(availableTime => {
              for (let i = 0; i < range.length; i++) availableTimes.push(availableTime + ':' + range[i])
            })
            availableTimes.splice(0, operatingHours ? availableHours.length : hoursOfTheDay.length)

            for (let i = 0; i < availableTimes.length; i++) {
              if (availableTimes[i].split(':')[0].length === 1) availableTimes[i] = '0' + availableTimes[i]
              if (availableTimes[i].split(':')[1].length === 1) availableTimes[i] = availableTimes[i].split(':')[0] + ':' + '0' + availableTimes[i].split(':')[1]
            }

            scope.times = availableTimes
          }
          function getAvailableWorkers(startDateUtc) {
            CorporateService.getWorkers(sessionStorage.getItem('profileId')).then(res => {
              var workers = res.data

              workers.forEach(worker => {
                if (worker.workingHours) {
                  var workingHours = worker.workingHours
                  Object.keys(workingHours).forEach(key => workingHours[key] === undefined ? delete workingHours[key] : {})
                }
              })

              const daysOfTheWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
              let day = daysOfTheWeek[startDateUtc.getDay()]
              
              let hour = startDateUtc.getUTCHours()
              let minute = startDateUtc.getUTCMinutes()

              var availableWorkers = workers.filter(worker => {
                if (!worker.hasOwnProperty('workingHours')) {
                  return worker
                } else if (worker.hasOwnProperty('workingHours') && worker.workingHours.hasOwnProperty([day])) {
                  var startHour = worker.workingHours[day].startTime.split(':')[0]
                  var endHour = worker.workingHours[day].endTime.split(':')[0]
                  var isHourEarlierThanStartHour = hour < startHour
                  var isHourLaterThanEndHour = hour >= endHour
                  if (!isHourEarlierThanStartHour && !isHourLaterThanEndHour) return worker
                }
              })

              scope.team = availableWorkers
            })
          }

          scope.nextBookingStep = function() {
            scope.bookingStep++
            if (scope.bookingStep === 3 && !$rootScope.profile.settings.bookings.enableWorker) scope.bookingStep++
          }
          scope.setService = function(serviceId) {
            scope.service = scope.services.find(service => service.id === serviceId)
            if (!scope.service.multiplePurchaseDiscountPrice) scope.service.multiplePurchaseDiscountPrice = 0
          }
          scope.setNumberOfGuests = function(numberOfGuests) {
            scope.serviceTotalUnitSalePrice = numberOfGuests > 1 ? (scope.service.unitSalePrice * numberOfGuests) - scope.service.multiplePurchaseDiscountPrice : scope.service.unitSalePrice
          }
          scope.toggleStartDate = function() {
            scope.startDate = !scope.startDate
            scope.startTime = true
            if (scope.booking.startDateUtc) getAvailableTimes(scope.booking.startDateUtc)
          }
          scope.toggleStartTime = function() {
            scope.startTime = !scope.startTime
          }
          scope.setTime = function(time) {
            var hour = time.split(':')[0]
            var minute = time.split(':')[1]
            scope.booking.startDateUtc.setUTCHours(hour, minute)
            scope.booking.startDateUtc = new Date(scope.booking.startDateUtc)
            scope.startTime = !scope.startTime
            if ($rootScope.profile.settings.bookings.enableWorker) getAvailableWorkers(scope.booking.startDateUtc)
          }
          scope.setWorker = function(worker) {
            scope.booking.workerId = worker.id
          }
          scope.searchTimes = function(startTimeUtc) {
            if (startTimeUtc) return startTimeUtc.replace(/\./g, ':')
          }
        }
      })
      scope.createBooking = function() {
        scope.submitted = true

        if ($rootScope.profile.settings.bookings.enablePayment && scope.service.paymentTypeId) {
          scope.booking.grossValue = scope.serviceTotalUnitSalePrice
          scope.booking.netValue = scope.service.paymentType.type == 'Payment in full' ? scope.booking.grossValue : ['Pay on arrival','No payment required'].includes(scope.service.paymentType.type) ? 0 : scope.booking.grossValue - (scope.booking.grossValue / 2)
        }

        AppService.postBooking(scope.booking).then(res => {
          var booking = res.data

          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          shell.postedBooking = true
          $state.go('shell.booking', { referenceNo: booking.referenceNo })
          $timeout(function() {
            shell.hideAlert('postedBooking')
          }, 5000)
        })
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-booking.html')
  }
}

export default angular.module('directives.bookingForm', [])
  .directive('bookingForm', bookingForm)
  .name