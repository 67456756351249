import angular from 'angular'

run.$inject = ['$location', 'AppService', '$rootScope', '$transitions', 'AuthService', '$state', 'amMoment']

export default function run($location, AppService, $rootScope, $transitions, AuthService, $state, amMoment) {
  // $rootScope.uri = $location.host() === 'preview.befor.business' ? $location.host() + $location.url() : $location.host().replace('www.', '')

  function detectTheme() {
    var theme = 'light'

    // Local storage is used to override OS theme settings
    if (sessionStorage.getItem('theme')) {
      if (sessionStorage.getItem('theme') == 'dark') {
        var theme = 'dark'
      }
    } else if (!window.matchMedia) {
      // matchMedia method not supported
      return false
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // OS theme setting detected as dark
      var theme = 'dark'
    }

    // Dark theme preferred, set document with a `data-theme` attribute
    if (theme == 'dark') {
      document.documentElement.setAttribute('data-theme', 'dark')
    }
  }
  detectTheme()

  $rootScope.appTitle = 'Prema'
  $rootScope.filePath = '/api-prema2/containers/medias/download/'

  $transitions.onBefore({to: ['shell.**']}, $transition => {
    var expires = JSON.parse(localStorage.getItem('expires'))
    if (expires && (Date.now() > expires)) {
      window.stop()
      AuthService.logout()
      localStorage.clear()
      sessionStorage.removeItem('profileId')
      return $transition.router.stateService.target('login')
    } else if (!expires || !localStorage.getItem('userId')) return $transition.router.stateService.target('login')
  })
  
  $transitions.onSuccess({}, $transition => {
    document.body.scrollTop = document.documentElement.scrollTop = 0

    if (angular.isDefined($transition.injector().get('$title'))) $rootScope.pageTitle = $transition.injector().get('$title')
  })

  amMoment.changeLocale('en', {
    week: {
      dow: 1,
      doy: 7
    }
  })
}