NewCustomerController.$inject = ['CustomerService', '$transitions', '$scope']

export default function NewCustomerController(CustomerService, $transitions, $scope) {
  var vm = this

  vm.customer = CustomerService.initCustomer()

  $transitions.onBefore({}, () => {
    if (!vm.cancelled && !$scope.pristine.isPristine()) return CustomerService.postCustomer(vm.customer)
  }, {invokeLimit: 1})
}