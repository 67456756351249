routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.courses', {
      url: '/courses?page',
      template: require('./courses.html'),
      controller: 'CoursesController',
      controllerAs: 'courses',
      resolve: {
        $title: function() {
          return 'Courses'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.course', {
      url: '/courses/:slug',
      template: require('./course.html'),
      controller: 'CourseController',
      controllerAs: 'course',
      resolve: {
        course: ['CourseService', '$stateParams', function(CourseService, $stateParams) {
          return CourseService.getCourse(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['course', function(course) {
          return course.data.course
        }]
      }
    })
    .state('shell.newCourse', {
      url: '/courses/new',
      template: require('./new-course.html'),
      controller: 'NewCourseController',
      controllerAs: 'newCourse',
      resolve: {
        $title: function() {
          return 'New Course'
        }
      }
    })
}