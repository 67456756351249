questionnaireSlideout.$inject = ['$rootScope', 'AppService']

export default function questionnaireSlideout($rootScope, AppService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getQuestionnaireQuestions(questionnaireId) {
        AppService.getQuestionnaireQuestions(questionnaireId).then(res => {
          scope.questionnaireQuestions = res.data
          copy = angular.copy(scope.questionnaireQuestions)
        })
      }

      function processQuestionnaireQuestion(resolve, copy, questionnaireQuestion, isPostQuestionnaire, isPutQuestionnaire) {
        var index = copy.findIndex(copy => copy.id === questionnaireQuestion.id)
        if (index === -1) {
          // The question did not exist
          AppService.postQuestionnaireQuestion(questionnaireQuestion).then(res => {
            var questionnaireQuestion2 = res.data

            if (questionnaireQuestion.questionnaireAnswers) {
              var counter = 0

              questionnaireQuestion.questionnaireAnswers.forEach(questionnaireAnswer => {
                if (questionnaireAnswer.answer) {
                  questionnaireAnswer.questionnaireQuestionId = questionnaireQuestion2.id

                  AppService.postQuestionnaireAnswer(questionnaireAnswer).then(() => {
                    counter++
                    if (counter === questionnaireQuestion.questionnaireAnswers.length) {
                      if (!isPostQuestionnaire && !isPutQuestionnaire) {
                        AppService.putQuestionnaire(scope.questionnaireId).then(() => {
                          isPutQuestionnaire = true
                          resolve()
                        })
                      } else {
                        resolve()
                      }
                    }
                  })
                }
              })
            } else {
              if (!isPostQuestionnaire && !isPutQuestionnaire) {
                AppService.putQuestionnaire(scope.questionnaireId).then(() => {
                  isPutQuestionnaire = true
                  resolve()
                })
              } else {
                resolve()
              }
            }
          })
        } else {
          // The question already existed, so get it from the copy and compare it to the new version
          var copyQuestionnaireQuestion = copy.find(copy => copy.id === questionnaireQuestion.id)

          if (!angular.equals(questionnaireQuestion, copyQuestionnaireQuestion)) {
            AppService.putQuestionnaireQuestion(questionnaireQuestion).then(() => {
              if (!isPutQuestionnaire) AppService.putQuestionnaire(scope.questionnaireId).then(() => {
                isPutQuestionnaire = true
                resolve()
              })
            })
          } else {
            resolve()
          }

          if (questionnaireQuestion.questionnaireAnswers) {
            var counter = 0

            questionnaireQuestion.questionnaireAnswers.forEach(questionnaireAnswer => {
              var index = copyQuestionnaireQuestion.questionnaireAnswers.findIndex(questionnaireAnswer2 => questionnaireAnswer2.id === questionnaireAnswer.id)
              if (index === -1) {
                // The answer did not exist
                if (questionnaireAnswer.answer) {
                  questionnaireAnswer.questionnaireQuestionId = questionnaireQuestion.id

                  AppService.postQuestionnaireAnswer(questionnaireAnswer).then(() => {
                    counter++
                    if (counter === questionnaireQuestion.questionnaireAnswers.length) resolve()
                  })
                } else {
                  resolve()
                }
              } else {
                // The answer already existed, so get it from the copy and compare it to the new version
                var copyQuestionnaireAnswer = copyQuestionnaireQuestion.questionnaireAnswers.find(questionnaireAnswer2 => questionnaireAnswer2.id === questionnaireAnswer.id)

                if (!angular.equals(questionnaireAnswer, copyQuestionnaireAnswer)) {
                  new Promise((resolve2, reject2) => {
                    if (questionnaireAnswer.answer) {
                      AppService.putQuestionnaireAnswer(questionnaireAnswer).then(() => {
                        counter++
                        if (counter === questionnaireQuestion.questionnaireAnswers.length) resolve2()
                      })
                    }
                  }).then(() => {
                    if (!isPutQuestionnaire) AppService.putQuestionnaire(scope.questionnaireId).then(() => {
                      isPutQuestionnaire = true
                      resolve()
                    })
                  })
                } else {
                  resolve()
                }
              }
            })
          }
        }
      }

      var copy
      $rootScope.$watch('profile', profile => {
        if (profile !== undefined) {
          scope.settingsId = profile.settings.id

          if (profile.settings.questionnaireId) {
            getQuestionnaireQuestions(profile.settings.questionnaireId)
            scope.questionnaireId = profile.settings.questionnaireId
          } else {
            scope.questionnaireQuestions = new Array()
            copy = angular.copy(scope.questionnaireQuestions)
          }
          AppService.getQuestionnaireQuestionTypes().then(res => scope.questionnaireQuestionTypes = res.data)
        }
      })

      scope.toggleManageQuestionnaire = function() {
        var questionnaireQuestions = JSON.parse(angular.toJson(scope.questionnaireQuestions))

        new Promise((resolve, reject) => {
          var isPostQuestionnaire = false
          var isPutQuestionnaire = false

          if (scope.questionnaireId) {
            // The questionnaire already existed, so process all the questions
            questionnaireQuestions.forEach(questionnaireQuestion => {
              if (!questionnaireQuestion.questionnaireId) questionnaireQuestion.questionnaireId = scope.questionnaireId

              processQuestionnaireQuestion(resolve, copy, questionnaireQuestion, isPostQuestionnaire, isPutQuestionnaire)
            })
          } else {
            // The questionnaire did not exist, so create it and then process all the questions
            if (questionnaireQuestions.length > 0) {
              AppService.postQuestionnaire(scope.settingsId).then(res => {
                var questionnaire = res
  
                scope.questionnaireId = questionnaire.id
                isPostQuestionnaire = true
  
                questionnaireQuestions.forEach(questionnaireQuestion => {
                  if (!questionnaireQuestion.questionnaireId) questionnaireQuestion.questionnaireId = scope.questionnaireId
    
                  processQuestionnaireQuestion(resolve, copy, questionnaireQuestion, isPostQuestionnaire, isPutQuestionnaire)
                })
              })
            }
          }
        }).then(() => getQuestionnaireQuestions(scope.questionnaireId))

        scope.slideout = false
      }
      scope.addQuestionnaireQuestion = function() {
        scope.questionnaireQuestions.push(AppService.initQuestionnaireQuestion())
      }
      scope.deleteQuestionnaireQuestion = function(questionnaireQuestion) {
        AppService.deleteQuestionnaireQuestion(questionnaireQuestion).then(() => getQuestionnaireQuestions(scope.settings.questionnaireId))
      }
      scope.addQuestionnaireAnswer = function(questionnaireQuestionIndex) {
        if (!scope.questionnaireQuestions[questionnaireQuestionIndex].questionnaireAnswers) scope.questionnaireQuestions[questionnaireQuestionIndex].questionnaireAnswers = new Array()
        scope.questionnaireQuestions[questionnaireQuestionIndex].questionnaireAnswers.push(AppService.initQuestionnaireAnswer())
      }
    },
    template: require('./_slideout-questionnaire.html')
  }

  return directive
}