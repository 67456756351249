ReviewService.$inject = ['$http', 'AppService']

export default function ReviewService($http, AppService) {
  return {
    getReviews: function(profileId) {
      return $http.get('/api-prema2/reviews?filter={"where":{"profileId":"' + profileId + '"},"include":{"relation":"contact","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}]}},"order":"createdAt DESC"}')
    },
    getReviewById: function(reviewId) {
      return $http.get('/api-prema2/reviews/' + reviewId + '?filter[include]=contact')
    },
    patchReview: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/reviews/' + id, data, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        var review = res.data

        var auditLog = {
          objectId: review.id.toString(),
          createdAt: review.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentReview,
          logTypeId: AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    },
    deleteReview: function(review) {
      return $http.delete('/api-prema2/reviews/' + review.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}