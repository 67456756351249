addPaymentSlideout.$inject = ['AppService']

export default function addPaymentSlideout(AppService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      component: '=',
      parent: '=',
      currency: '=',
      get: '='
    },
    link: function(scope, elem, attrs) {
      scope.payment = new Object()
      AppService.getPaymentTypes().then(res => scope.paymentTypes = res.data)
      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => scope.profilePaymentMethods = res.data)
      AppService.getPaymentStatuses().then(res => scope.paymentStatuses = res.data)

      scope.toggleAddPayment = function() {
        if (scope.payment.netValue && scope.payment.paymentTypeId && scope.payment.paymentMethodId) AppService.postPayment(scope.component, scope.parent, scope.payment).then(res => {
          var activities = res.data
          scope.parent.activities = activities
          scope.get(scope.parent)
          delete scope.payment

          scope.slideout = false
        })
      }
      scope.setPaymentStatus = function() {
        var type = scope.paymentTypes.find(paymentType => paymentType.id === scope.payment.paymentTypeId).type
        
        scope.payment.paymentStatusId = type === 'Payment in full' || type === 'Balance' ? scope.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Paid').id : type === 'Pay on arrival' ? scope.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Pending').id : type === 'No payment required' ? scope.paymentStatuses.find(paymentStatus => paymentStatus.status === 'No payment required').id : scope.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Partially paid').id
      }
    },
    template: require('./_slideout-add-payment.html')
  }

  return directive
}