stockLocationsSlideout.$inject = ['ProductService']

export default function stockLocationsSlideout(ProductService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getStockLocations(profileId) {
        ProductService.getStockLocations(profileId).then(res => scope.stockLocations = res.data)
      }

      getStockLocations(sessionStorage.getItem('profileId'))

      scope.toggleManageStockLocations = function() {
        new Promise((resolve, reject) => {
          scope.stockLocations.forEach(stockLocation => ProductService.putStockLocation(stockLocation).then(() => resolve()))
        }).then(() => getStockLocations(sessionStorage.getItem('profileId')))

        scope.slideout = false
      }
      scope.addStockLocation = function() {
        scope.stockLocations.push({
          order: scope.stockLocations.length + 1
        })
      }
      scope.deleteStockLocation = function(stockLocation) {
        ProductService.deleteStockLocation(stockLocation).then(() => getStockLocations(sessionStorage.getItem('profileId')))
      }
    },
    template: require('./_slideout-stock-locations.html')
  }

  return directive
}