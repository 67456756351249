ServicesController.$inject = ['$rootScope', 'ServiceService', '$timeout']

export default function ServicesController($rootScope, ServiceService, $timeout) {
  var vm = this
  
  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      ServiceService.getServices(profileId).then(res => vm.services = res.data)
    }
  })

  vm.toggleManageCategories = function() {
    vm.manageCategories = !vm.manageCategories
  }
  vm.toggleManageSubscriptions = function() {
    vm.manageSubscriptions = !vm.manageSubscriptions
  }
  vm.setSortBy = function(property) {
    var copy = vm.services
    vm.services = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.services = copy
    }, 100)
  }
}