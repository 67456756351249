NewLocationController.$inject = ['LocationService', 'AppService', '$uibModal', '$transitions', '$scope']

export default function NewLocationController(LocationService, AppService, $uibModal, $transitions, $scope) {
  var vm = this

  function saveSelection() {
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        var ranges = []
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
        return ranges
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange()
    }
    return null
  }

  vm.location = LocationService.initLocation()

  vm.insertLink = function() {
    AppService.savedSel = saveSelection()

    $uibModal.open({
      controller: 'ModalLinkController',
      controllerAs: 'modalLink',
      template: require('../../app/link-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.setPromoted = function() {
    vm.location.isPromoted = !vm.location.isPromoted
  }
  vm.setPublished = function() {
    vm.location.isPublished = !vm.location.isPublished
  }
  $transitions.onBefore({}, () => {
    if (!vm.cancelled && !$scope.pristine.isPristine()) return LocationService.postLocation(vm.location)
  }, {invokeLimit: 1})
}