addGuestSlideout.$inject = ['AppService', 'CorporateService', 'EventService', '$state']

export default function addGuestSlideout(AppService, CorporateService, EventService, $state) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      event: '=',
      currency: '='
    },
    link: function(scope, elem, attrs) {
      function htmlDecode(input) {
        var e = document.createElement('div')
        e.innerHTML = input
        return e.childNodes[0].nodeValue
      }

      scope.event.eventTicketTypes = scope.event.eventTicketTypes.filter(eventTicketType => eventTicketType.typeUnitSalePriceStr = htmlDecode(eventTicketType.type + ' (' + scope.currency.symbol + eventTicketType.unitSalePrice.toFixed(2) + ')'))
      scope.contact = new Object(); scope.eventGuest = new Object(); scope.payment = new Object()
      scope.eventGuest.referenceNo = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
      AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => scope.profilePaymentMethods = res.data)
      CorporateService.getWorkers(sessionStorage.getItem('profileId')).then(res => {
        var workers = res.data

        scope.referrers = workers.filter(worker => worker.workerRoles.some(workerRole => workerRole.role === 'Referrer')).sort((a, b) => a.contact.firstName.localeCompare(b.contact.firstName))
      })

      scope.toggleAddGuest = function() {
        EventService.postEventGuest(scope.eventGuest, true, scope.event, scope.contact).then(res => {
          var eventGuest = res.data

          eventGuest.netValue = scope.eventTicketTypeTotalUnitSalePrice
          scope.payment.netValue = scope.eventTicketTypeTotalUnitSalePrice

          AppService.postPayment('eventGuest', eventGuest, scope.payment).then(res => {
            var payment = res.data

            var promise = new Promise((resolve, reject) => {
              if (scope.additionalGuests) {
                var counter = 0
                scope.additionalGuests.forEach(additionalGuest => {
                  var eventGuest2 = new Object()
                  eventGuest2.referenceNo = eventGuest.referenceNo
                  eventGuest2.eventTicketTypeId = eventGuest.eventTicketTypeId
                  eventGuest2.paymentMethodId = eventGuest.paymentMethodId
                  EventService.postEventGuest(eventGuest2, undefined, scope.event, additionalGuest).then(() => {
                    counter++
                    if (counter === scope.additionalGuests.length) resolve()
                  })
                })
              } else {
                resolve()
              }
            })

            promise.then(() => $state.reload('shell.event'))
          })
        })

        scope.slideout = false
      }
      scope.addAdditionalGuest = function() {
        if (!scope.additionalGuests) scope.additionalGuests = new Array()
        scope.additionalGuests.push({})
        if (scope.eventTicketType) {
          if (!scope.eventTicketType.multiplePurchaseDiscountPrice) scope.eventTicketType.multiplePurchaseDiscountPrice = 0
          scope.eventTicketTypeTotalUnitSalePrice = scope.eventTicketType.unitSalePrice + (scope.eventTicketType.unitSalePrice * scope.additionalGuests.length) - scope.eventTicketType.multiplePurchaseDiscountPrice
        }
      }
      scope.setEventTicketType = function(eventTicketTypeId) {
        scope.eventTicketType = scope.event.eventTicketTypes.find(eventTicketType => eventTicketType.id === eventTicketTypeId)
        if (scope.eventTicketType.type === 'Guestlist') scope.payment.paymentMethodId = undefined
        if (!scope.eventTicketType.multiplePurchaseDiscountPrice) scope.eventTicketType.multiplePurchaseDiscountPrice = 0
        scope.eventTicketTypeTotalUnitSalePrice = scope.additionalGuests ? scope.eventTicketType.unitSalePrice + (scope.eventTicketType.unitSalePrice * scope.additionalGuests.length) - scope.eventTicketType.multiplePurchaseDiscountPrice : scope.eventTicketType.unitSalePrice
      }
      scope.setProfilePaymentMethod = function(paymentMethodId) {
        scope.profilePaymentMethod = scope.profilePaymentMethods.find(profilePaymentMethod => profilePaymentMethod.paymentMethod.id === paymentMethodId)
      }
    },
    template: require('./_slideout-add-guest.html')
  }

  return directive
}