ModalVideoController.$inject = ['file', 'AppService', '$uibModalInstance']

export default function ModalVideoController(file, AppService, $uibModalInstance) {
  var vm = this

  vm.file = file

  vm.uploadMedias = function(files, invalidFiles) {
    files.forEach((file, index) => {
      AppService.uploadMedia(file).then(res => {
        vm.file2 = res.data

        if (!vm.attrs) vm.attrs = new Object()
        vm.attrs.mediaId = vm.file2.id
      })
    })
  }
  vm.close = function() {
    $uibModalInstance.close(vm.attrs)
  }
}