ShellController.$inject = ['$transitions', '$rootScope', 'OrderService', 'AuthService', 'AppService', '$state', 'EventService', '$uibModal']

export default function ShellController($transitions, $rootScope, OrderService, AuthService, AppService, $state, EventService, $uibModal) {
  var vm = this

  function changeColourValue(color, amount) {
    return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2))
  }
  
  if (window.innerWidth <= 767) {
    vm.display = 'small'
    $transitions.onSuccess({}, () => {
      if (vm.appSidebar) vm.appSidebar = false
    })
  } else if (window.innerWidth > 767 && window.innerWidth <= 1199) {
    vm.display = 'medium'
  } else {
    vm.display = 'large'
  }
  vm.appSidebar = ['medium', 'large'].includes(vm.display) ? true : false
  vm.toggleAppSidebar = () => vm.appSidebar === true ? (vm.appSidebar = false, vm.appSidebarWidth = vm.display !== 'small' ? '50px' : undefined) : (vm.appSidebar = true, vm.appSidebarWidth = undefined)
  vm.hideAppSidebar = () => vm.appSidebar = false
  vm.currentYear = new Date().getFullYear()
  $rootScope.$on('search', (event, data) => vm.search = data)
  vm.basketItems = OrderService.basketItems
  vm.isAuthenticated = AuthService.isAuthenticated()
  AuthService.getCurrentUser().then(res => {
    var currentUser = res.data
    if (currentUser.contact.contactType.type === 'Owner' || currentUser.contact.contactType.type === 'Employee') {
      $rootScope.uri = currentUser.profile.web

      AppService.getProfile($rootScope.uri).then(res => {
        sessionStorage.setItem('profileId', res.data.id)
        $rootScope.profile = res.data

        // Stats tiles
        if ($rootScope.profile.locations.length > 0) {
          $rootScope.profile.locationPrimary = $rootScope.profile.locations.find(location => location.isPrimary)
          $rootScope.locationPrimary = $rootScope.profile.locations.find(location => location.isPrimary)
        }
    
        // if ($rootScope.profile.settings && $rootScope.profile.settings.theme) {
        //   if ($rootScope.profile.settings.theme === 'dark') {
        //     sessionStorage.setItem('theme', 'dark')
        //     document.documentElement.setAttribute('data-theme', 'dark')
        //   } else {
        //     sessionStorage.setItem('theme', 'light')
        //     document.documentElement.setAttribute('data-theme', 'light')
        //   }
        // }
    
        if ($rootScope.profile.settings.hexPrimary) {
          $rootScope.hexPrimaryValue = AppService.getHexPrimaryValue($rootScope.profile.settings.hexPrimary)
          $rootScope.hexPrimaryLighter = changeColourValue($rootScope.profile.settings.hexPrimary, 70)
          $rootScope.hexPrimaryDarker = changeColourValue($rootScope.profile.settings.hexPrimary, -30)
        }

        $rootScope.profile.settings.components.forEach(component => {
          $rootScope.profile.settings[component.name] = component
    
          if (component.customFields) component.customFields.forEach(customField => component[customField.name] = customField.value)
        })
      })
    } else {
      $state.go('unauthorised')
    }
  })

  vm.searchProducts = function() {
    if (vm.search) $state.go('shell.products')
  }
  vm.searchEvents = function() {
    if (vm.search) {
      EventService.getEventGuestBySearch(vm.search).then(res => {
        var eventGuest = isNaN(vm.search) ? res : res.data

        if ($state.$current.name !== 'shell.event') {
          $state.go('shell.event', { slug: eventGuest.event.slug, activeTab: 1, search: vm.search })
        } else {
          $state.go('.', { activeTab: 1, search: vm.search }, { notify: false })
        }
      })
    } else {
      $state.go('.', { activeTab: 1, search: undefined }, { notify: false })
    }
  }
  vm.showNewLeadModal = function() {
    $uibModal.open({
      template: require('./lead-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewBookingModal = function() {
    $uibModal.open({
      template: require('./booking-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewOrderModal = function() {
    $uibModal.open({
      template: require('./order-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewReviewModal = function() {
    $uibModal.open({
      template: require('./review-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewMembershipModal = function() {
    $uibModal.open({
      template: require('./membership-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.showNewContractModal = function() {
    $uibModal.open({
      template: require('./contract-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}