ContractController.$inject = ['contract', 'ContractService', 'AppService', 'ProductService', 'ServiceService', '$stateParams', '$uibModal', '$rootScope', '$transitions']

export default function ContractController(contract, ContractService, AppService, ProductService, ServiceService, $stateParams, $uibModal, $rootScope, $transitions) {
  var vm = this

  function getPaymentsById(contract) {
    var payments = contract.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => payment.object = res.data))
  }

  function init(contract) {
    if (contract.contact && contract.contact.communications) contract.contact.communications.email = contract.contact.communications.find(communication => communication.method === 'Email') ? contract.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (contract.contractLineItems) {
      contract.contractLineItemProducts = contract.contractLineItems.filter(contractLineItem => contractLineItem.product)
      contract.contractLineItemServices = contract.contractLineItems.filter(contractLineItem => contractLineItem.service)
    }

    if (contract.activities.length > 0) getPaymentsById(contract)

    vm.contract = contract
    copy = angular.copy(vm.contract)
    vm.contract.startDateUtc = new Date(vm.contract.startDateUtc)
    copy.startDateUtc = new Date(copy.startDateUtc)
  }

  function getContract() {
    ContractService.getContract(contract.referenceNo).then(res => init(res.data))
  }

  function calcContractNetValue() {
    vm.contract.grossValue = (vm.contract.contractLineItemProducts ? vm.contract.contractLineItemProducts.map(contractLineItemProduct => contractLineItemProduct.totalValue).reduce((a, c) => a + c, 0) : 0) + (vm.contract.contractLineItemServices ? vm.contract.contractLineItemServices.map(contractLineItemService => contractLineItemService.totalValue).reduce((a, c) => a + c, 0) : 0)
    vm.contract.netValue = vm.contract.discountValue ? vm.contract.grossValue - vm.contract.discountValue : vm.contract.grossValue
  }

  var copy
  if (!contract.isRead) contract.isRead = true, ContractService.patchContract(contract.id, 'isRead', true)
  init(contract)
  ContractService.getContractStatuses().then(res => vm.contractStatuses = res.data)
  AppService.getPaymentTypes().then(res => vm.paymentTypes = res.data)
  AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => vm.profilePaymentMethods = res.data)
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)
  AppService.getRecurrencePatterns().then(res => vm.recurrencePatterns = res.data)
  AppService.getLeadSources(sessionStorage.getItem('profileId')).then(res => vm.leadSources = res.data)
  ProductService.getProducts(sessionStorage.getItem('profileId')).then(res => {
    vm.products = res.data
    vm.products.forEach(product => product.productNo = product.productNo ? product.productNo : '')
  })
  ServiceService.getServices(sessionStorage.getItem('profileId')).then(res => {
    vm.services = res.data
    vm.services.forEach(service => service.serviceNo = service.serviceNo ? service.serviceNo : '')
  })
  vm.startDate = false
  vm.startDateOptions = {
    startingDay: 1
  }
  vm.timeOptions = {
    showMeridian: false,
    minuteStep: 5
  }

  vm.toggleAddPayment = function() {
    vm.addPayment = !vm.addPayment
  }
  vm.getPaymentsById = function(contract) {
    getPaymentsById(contract)
  }
  vm.setContractStatus = function(contractStatusId) {
    vm.contract.contractStatusId = contractStatusId
    vm.contract.contractStatus = vm.contractStatuses.find(contractStatus => contractStatus.id === contractStatusId)
  }
  vm.tabs = [0, 1, 2]
  vm.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.toggleStartDate = function() {
    vm.startDate = !vm.startDate
  }
  vm.setContractNetValue = function() {
    calcContractNetValue()
  }
  vm.setContractContactEmail = function() {
    if (!vm.contract.contact.communications) vm.contract.contact.communications = new Array()
    vm.contract.contact.communications.push({
      order: vm.contract.contact.communications.length + 1,
      isPrimary: vm.contract.contact.communications.length === 0 ? true : undefined,
      method: 'Email',
      value: vm.email
    })
  }
  vm.showNewContactModal = function() {
    $uibModal.open({
      template: require('../../app/contact-modal-new.html')
    }).result.then(contact => {
      vm.contract.contactId = contact.id, vm.contract.contact = contact
      if (vm.contract.contact && vm.contract.contact.communications) vm.contract.contact.communications.email = vm.contract.contact.communications.find(communication => communication.method === 'Email') ? vm.contract.contact.communications.find(communication => communication.method === 'Email').value : undefined
      var name = vm.contract.contact.name = vm.contract.contact.lastName ? vm.contract.contact.firstName + ' ' + vm.contract.contact.lastName : vm.contract.contact.firstName
      $rootScope.pageTitle = $rootScope.pageTitle + ' ' + name
    })
  }
  vm.setContractLineItemProduct = function(contractLineItemProduct) {
    var product = vm.products.find(product => product.id === contractLineItemProduct.product.id)
    contractLineItemProduct.product.product = product.product
    contractLineItemProduct.product.unitSalePrice = product.unitSalePrice
    if (contractLineItemProduct.unitSalePrice) contractLineItemProduct.product.unitSalePrice = contractLineItemProduct.unitSalePrice
    contractLineItemProduct.count = contractLineItemProduct.count ? contractLineItemProduct.count : 1
    contractLineItemProduct.totalValue = contractLineItemProduct.product.unitSalePrice * contractLineItemProduct.count
    calcContractNetValue()
  }
  vm.addContractLineItemProduct = function() {
    if (!vm.contract.contractLineItemProducts) vm.contract.contractLineItemProducts = new Array()
    vm.contract.contractLineItemProducts.push({})
  }
  vm.deleteContractLineItemProduct = function(index) {
    vm.contract.contractLineItemProducts.splice(index, 1)
    calcContractNetValue()
  }
  vm.setContractLineItemService = function(contractLineItemService) {
    var service = vm.services.find(service => service.id === contractLineItemService.service.id)
    contractLineItemService.service.service = service.service
    contractLineItemService.service.unitSalePrice = service.unitSalePrice
    if (contractLineItemService.unitSalePrice) contractLineItemService.service.unitSalePrice = contractLineItemService.unitSalePrice
    contractLineItemService.count = contractLineItemService.count ? contractLineItemService.count : 1
    contractLineItemService.totalValue = contractLineItemService.service.unitSalePrice * contractLineItemService.count
    calcContractNetValue()
  }
  vm.addContractLineItemService = function() {
    if (!vm.contract.contractLineItemServices) vm.contract.contractLineItemServices = new Array()
    vm.contract.contractLineItemServices.push({})
  }
  vm.deleteContractLineItemService = function(index) {
    vm.contract.contractLineItemServices.splice(index, 1)
    calcContractNetValue()
  }
  vm.setPaymentStatus = function(payment) {
    var type = vm.paymentTypes.find(paymentType => paymentType.id === payment.paymentTypeId).type
    
    payment.paymentStatusId = type === 'Payment in full' || type === 'Balance' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Paid').id : type === 'Pay on arrival' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Pending').id : type === 'No payment required' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'No payment required').id : vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Partially paid').id
  }
  vm.updatePayment = function(activity, payment) {
    AppService.putPayment(activity, payment).then(() => getPaymentsById(vm.contract))
  }
  $transitions.onBefore({}, () => {
    vm.contract.activities.forEach(activity => delete activity.object), copy.activities.forEach(activity => delete activity.object)
    if (!angular.equals(vm.contract, copy)) return ContractService.putContract(vm.contract)
  }, {invokeLimit: 1})
}