routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.studies', {
      url: '/studies?page',
      template: require('./studies.html'),
      controller: 'StudiesController',
      controllerAs: 'studies',
      resolve: {
        $title: function() {
          return 'Studies'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.study', {
      url: '/studies/:slug',
      template: require('./study.html'),
      controller: 'StudyController',
      controllerAs: 'study',
      resolve: {
        study: ['StudyService', '$stateParams', function(StudyService, $stateParams) {
          return StudyService.getStudy(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['study', function(study) {
          return study.data.study
        }]
      }
    })
    .state('shell.newStudy', {
      url: '/studies/new',
      template: require('./new-study.html'),
      controller: 'NewStudyController',
      controllerAs: 'newStudy',
      resolve: {
        $title: function() {
          return 'New Study'
        }
      }
    })
}