import angular from 'angular'

contenteditable.$inject = ['$sce']

function contenteditable($sce) {
  return {
    restrict: 'A', // only activate on element attribute
    require: '?ngModel', // get a hold of NgModelController
    link: function(scope, element, attrs, ngModel) {
      if (!ngModel) {
        return // do nothing if no ng-model
      }

      // Specify how UI should be updated
      ngModel.$render = function() {
        element.html($sce.getTrustedHtml(ngModel.$viewValue || ''))
      }

      // Listen for change events to enable binding
      element.on('blur keyup change', function() {
        scope.$evalAsync(read)
      })
      element.bind('paste', function(e) {
        e.preventDefault()
        document.execCommand('inserttext', false, e.clipboardData.getData('text/plain'))
      })
      // read(); // initialize

      // Write data to the model
      function read() {
        var html = element.html()
        // When we clear the content editable the browser leaves a <br> behind
        // If strip-br attribute is provided then we strip this out
        if ( attrs.stripBr && html == '<br>' ) {
          html = ''
        }
        ngModel.$setViewValue(html)
      }
    }
  }
}

export default angular.module('directives.contenteditable', [])
  .directive('contenteditable', contenteditable)
  .name