LeadController.$inject = ['lead', 'LeadService', 'AppService', '$stateParams', '$uibModal', '$rootScope', '$transitions']

export default function LeadController(lead, LeadService, AppService, $stateParams, $uibModal, $rootScope, $transitions) {
  var vm = this

  function getPaymentsById(lead) {
    var payments = lead.activities.filter(activity => activity.activityType.type === 'Payment')
    payments.forEach(payment => AppService.getPaymentById(payment.objectId).then(res => payment.object = res.data))
  }

  function init(lead) {
    if (lead.contact && lead.contact.communications) lead.contact.communications.email = lead.contact.communications.find(communication => communication.method === 'Email') ? lead.contact.communications.find(communication => communication.method === 'Email').value : undefined

    if (lead.activities.length > 0) getPaymentsById(lead)

    vm.lead = lead
    copy = angular.copy(vm.lead)
  }

  function getLead() {
    LeadService.getLead(lead.id).then(res => init(res.data))
  }

  var copy
  if (!lead.isRead) lead.isRead = true, LeadService.patchLead(lead.id, 'isRead', true)
  init(lead)
  LeadService.getLeadStatuses().then(res => vm.leadStatuses = res.data)
  AppService.getPaymentTypes().then(res => vm.paymentTypes = res.data)
  AppService.getProfilePaymentMethods(sessionStorage.getItem('profileId')).then(res => vm.profilePaymentMethods = res.data)
  AppService.getPaymentStatuses().then(res => vm.paymentStatuses = res.data)
  AppService.getRecurrencePatterns().then(res => vm.recurrencePatterns = res.data)
  AppService.getLeadSources(sessionStorage.getItem('profileId')).then(res => vm.leadSources = res.data)

  vm.toggleAddPayment = function() {
    vm.addPayment = !vm.addPayment
  }
  vm.getPaymentsById = function(lead) {
    getPaymentsById(lead)
  }
  vm.setLeadStatus = function(leadStatusId) {
    vm.lead.leadStatusId = leadStatusId
    vm.lead.leadStatus = vm.leadStatuses.find(leadStatus => leadStatus.id === leadStatusId)
  }
  vm.tabs = [0, 1]
  vm.activeTab = $stateParams.activeTab ? $stateParams.activeTab : 0
  vm.setActiveTab = function(index) {
    vm.activeTab = index
  }
  vm.setLeadContactEmail = function() {
    if (!vm.lead.contact.communications) vm.lead.contact.communications = new Array()
    vm.lead.contact.communications.push({
      order: vm.lead.contact.communications.length + 1,
      isPrimary: vm.lead.contact.communications.length === 0 ? true : undefined,
      method: 'Email',
      value: vm.email
    })
  }
  vm.showNewContactModal = function() {
    $uibModal.open({
      template: require('../../app/contact-modal-new.html')
    }).result.then(contact => {
      vm.lead.contactId = contact.id, vm.lead.contact = contact
      if (vm.lead.contact && vm.lead.contact.communications) vm.lead.contact.communications.email = vm.lead.contact.communications.find(communication => communication.method === 'Email') ? vm.lead.contact.communications.find(communication => communication.method === 'Email').value : undefined
      var name = vm.lead.contact.name = vm.lead.contact.lastName ? vm.lead.contact.firstName + ' ' + vm.lead.contact.lastName : vm.lead.contact.firstName
      $rootScope.pageTitle = $rootScope.pageTitle + ' ' + name
    })
  }
  vm.setPaymentStatus = function(payment) {
    var type = vm.paymentTypes.find(paymentType => paymentType.id === payment.paymentTypeId).type
    
    payment.paymentStatusId = type === 'Payment in full' || type === 'Balance' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Paid').id : type === 'Pay on arrival' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Pending').id : type === 'No payment required' ? vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'No payment required').id : vm.paymentStatuses.find(paymentStatus => paymentStatus.status === 'Partially paid').id
  }
  vm.updatePayment = function(activity, payment) {
    AppService.putPayment(activity, payment).then(() => getPaymentsById(vm.lead))
  }
  $transitions.onBefore({}, () => {
    vm.lead.activities.forEach(activity => delete activity.object), copy.activities.forEach(activity => delete activity.object)
    if (!angular.equals(vm.lead, copy)) return LeadService.putLead(vm.lead)
  }, {invokeLimit: 1})
}