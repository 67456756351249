subscriptionsSlideout.$inject = ['ServiceService', 'AppService', '$timeout']

export default function subscriptionsSlideout(ServiceService, AppService, $timeout) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      currency: '='
    },
    link: function(scope, elem, attrs) {
      function getSubscriptions() {
        ServiceService.getSubscriptions(sessionStorage.getItem('profileId')).then(res => {
          scope.subscriptions = res.data
          copy = angular.copy(scope.subscriptions)
        })
      }

      var shell = scope.$parent.$parent.shell
      var copy
      getSubscriptions()
      AppService.getRecurrencePatterns().then(res => scope.recurrencePatterns = res.data)

      scope.toggleManageSubscriptions = function() {
        var subscriptions = JSON.parse(angular.toJson(scope.subscriptions))

        new Promise((resolve, reject) => {
          subscriptions.forEach(subscription => {
            var index = copy.findIndex(copy => copy.id === subscription.id)
            if (index === -1) {
              ServiceService.postSubscription(subscription).then(() => resolve())
            } else {
              var copySubscription = copy.find(copy => copy.id === subscription.id)

              if (!angular.equals(subscription, copySubscription)) {
                ServiceService.putSubscription(subscription).then(() => resolve())
              }
            }
          })
        }).then(() => {
          if (subscriptions.length > 0 && sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
            AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
              shell.addedRGA = true
              sessionStorage.removeItem('taskId')
              sessionStorage.removeItem('contactId')
              $timeout(function() {
                shell.hideAlert('addedRGA')
              }, 5000)
            })
          } else {
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
          }
          getSubscriptions()
        })

        scope.slideout = false
      }
      scope.addSubscription = function() {
        scope.subscriptions.push(ServiceService.initSubscription(scope.subscriptions))
      }
      scope.deleteSubscription = function(subscription) {
        ServiceService.deleteSubscription(subscription).then(() => getSubscriptions())
      }
    },
    template: require('./_slideout-subscriptions.html')
  }

  return directive
}