import angular from 'angular'

leadForm.$inject = ['AppService', '$timeout', '$state']

function leadForm(AppService, $timeout, $state) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.contact = new Object(); scope.lead = new Object()

      scope.createLead = function() {
        scope.submitted = true
        
        AppService.postLead(scope.lead, scope.contact).then(res => {
          var lead = res.data

          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          shell.postedEnquiry = true
          $state.go('shell.lead', { id: lead.id })
          $timeout(function() {
            shell.hideAlert('postedEnquiry')
          }, 5000)
        })
      }
    },
    template: require('./_form-lead.html')
  }
}

export default angular.module('directives.leadForm', [])
  .directive('leadForm', leadForm)
  .name