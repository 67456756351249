sysUserForm.$inject = ['$window', 'AuthService']

export default function sysUserForm($window, AuthService) {
  var body = angular.element($window.document.body)
  var textarea = angular.element('<textarea/>')
  textarea.css({
    position: 'fixed',
    opacity: '0'
  })

  function copy(toCopy) {
    textarea.val(toCopy)
    body.append(textarea)
    textarea[0].select()

    var successful = document.execCommand('copy')
    if (!successful) {
      throw successful
    }
    textarea.remove()
  }

  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      contact: '='
    },
    link: function(scope, elem, attrs) {
      if (scope.contact.communications) {
        if (scope.contact.communications.some(communication => communication.type.toLowerCase() === 'email')) scope.email = scope.contact.communications.find(communication => communication.type.toLowerCase() === 'email').value
      }
      scope.password = AuthService.generatePassword()

      scope.generatePassword = function() {
        scope.password = AuthService.generatePassword()
      }
      scope.copyPassword = function() {
        copy(scope.password)
      }
      scope.createSysUser = function() {
        AuthService.postSysUser(scope.email, scope.password, scope.contact.id).then(res => scope.$parent.$close(res.data))
      }
      scope.dismissModal = function() {
        scope.$parent.$dismiss()
      }
    },
    template: require('./_form-sys-user.html')
  }

  return directive
}