import angular from 'angular'

orderForm.$inject = ['AppService', '$timeout', '$state']

function orderForm(AppService, $timeout, $state) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.createOrder = function() {
        scope.submitted = true

        AppService.postOrder().then(res => {
          var order = res.data
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          shell.postedOrder = true
          $state.go('shell.order', { referenceNo: order.referenceNo })
          $timeout(function() {
            shell.hideAlert('postedOrder')
          }, 5000)
        })
      }
    },
    template: require('./_form-order.html')
  }
}

export default angular.module('directives.orderForm', [])
  .directive('orderForm', orderForm)
  .name