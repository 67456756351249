import angular from 'angular'

contactLocationForm.$inject = ['AppService', '$timeout']

function contactLocationForm(AppService, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      contact: '='
    },
    link: function(scope, elem, attrs) {
      scope.location = new Object()
      AppService.getCountries().then(res => scope.countries = res.data)

      scope.addContactLocation = function() {
        scope.submitted = true

        AppService.getLocationById(scope.location.id).then(res => {
          var location = res.data
          scope.$parent.$close(location)
        })
      }
      scope.createContactLocation = function() {
        scope.submitted = true
        
        AppService.postContactLocation(scope.location, !scope.contact.locations || scope.contact.locations.length === 0 ? true : false, scope.contact.id).then(res => {
          var location = res.data

          var shell = scope.$parent.$$topModalIndex === 0 ? scope.$parent.$$prevSibling.shell : scope.$parent.$parent.shell
          scope.$parent.$close(location)
          shell.postedContactLocation = true
          $timeout(function() {
            shell.hideAlert('postedContactLocation')
          }, 5000)
        })
      }
    },
    template: require('./_form-contact-location.html')
  }
}

export default angular.module('directives.contactLocationForm', [])
  .directive('contactLocationForm', contactLocationForm)
  .name