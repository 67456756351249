ReviewsController.$inject = ['$rootScope', 'ReviewService', '$stateParams', 'angularGridInstance', 'AppService', '$timeout', '$state']

export default function ReviewsController($rootScope, ReviewService, $stateParams, angularGridInstance, AppService, $timeout, $state) {
  var vm = this

  function getReviews(profileId) {
    ReviewService.getReviews(profileId).then(res => vm.reviews = res.data)
  }

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      getReviews(profile.id)
      vm.settings = profile.settings.components.find(component => component.name === 'reviews')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components, $rootScope.profile.settings.id)
  }
  vm.setSortBy = function(property) {
    var copy = vm.reviews
    vm.reviews = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    function compareNested(a, b) {
      let comparison = 0
      while (comparison < property.length) { a = a[property[comparison]]; b = b[property[comparison]]; comparison++; }
      if (a > b) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a < b) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    if (property.includes('.')) {
      property = property.split('.')
      copy.sort(compareNested)
    } else {
      copy.sort(compare)
    }
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.reviews = copy
    }, 100)
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
  vm.setPromoted = function(review) {
    ReviewService.patchReview(review.id, 'isPromoted', !review.isPromoted).then(() => {
      vm.reviews = null
      $timeout(function() {
        getReviews(sessionStorage.getItem('profileId'))
      }, 100)
    })
  }
  vm.setPublished = function(review) {
    ReviewService.patchReview(review.id, 'isPublished', !review.isPublished).then(() => {
      vm.reviews = null
      $timeout(function() {
        getReviews(sessionStorage.getItem('profileId'))
      }, 100)
    })
  }
  vm.numberOfPages = function() {
    return parseInt(vm.reviews.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}