import angular from 'angular'

function customerCard() {
  return {
    restrict: 'E',
    replace: true,
    template: require('./_card-customer.html')
  }
}

export default angular.module('directives.customerCard', [])
  .directive('customerCard', customerCard)
  .name