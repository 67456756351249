import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './contract.routes'
import ContractService from './contract.service'
import ContractCard from './contract-card.directive'
import ContractsController from './contracts.controller'
import ContractController from './contract.controller'
import './contract.less'

export default angular.module('app.contract', [uirouter, ContractCard])
  .config(routing)
  .service('ContractService', ContractService)
  .controller('ContractsController', ContractsController)
  .controller('ContractController', ContractController)
  .name