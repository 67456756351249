routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.suppliers', {
      url: '/suppliers?page',
      template: require('./suppliers.html'),
      controller: 'SuppliersController',
      controllerAs: 'suppliers',
      resolve: {
        $title: function() {
          return 'Suppliers'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.supplier', {
      url: '/suppliers/:slug',
      template: require('./supplier.html'),
      controller: 'SupplierController',
      controllerAs: 'supplier',
      resolve: {
        supplier: ['SupplierService', '$stateParams', function(SupplierService, $stateParams) {
          return SupplierService.getSupplier(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['supplier', function(supplier) {
          return supplier.data.businessName
        }]
      }
    })
    .state('shell.newSupplier', {
      url: '/suppliers/new',
      template: require('./new-supplier.html'),
      controller: 'NewSupplierController',
      controllerAs: 'newSupplier',
      resolve: {
        $title: function() {
          return 'New Supplier'
        }
      }
    })
}