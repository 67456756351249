import angular from 'angular'
import uirouter from 'angular-ui-router'
import uibootstrap from 'angular-ui-bootstrap'
import ngsantitize from 'angular-sanitize'
import ngAnimate from 'angular-animate'
import ngfileupload from 'ng-file-upload'
import checklistModel from 'checklist-model'
window.Chartist = Chartist
import * as Chartist from 'chartist'
import angularChartist from 'angular-chartist.js'
import 'angular-parallax-npm'
import 'angulargrid'
import 'angular-simple-logger'
import 'angular-google-maps'
import 'angular-slideout'
import 'bootstrap-ui-datetime-picker'
import 'chartist-plugin-legend'
import 'angular-moment'

import 'less/main.less'
import routing from './app.routes'
import run from './app.run'
import AppService from './app.service'
import ShellController from './shell.controller'
import ModalLinkController from './modal-link.controller'
import ModalVideoController from './modal-video.controller'
import ModalExternalResourceController from './modal-external-resource.controller'
import ModalContactLocationController from './modal-contact-location.controller'
import ModalAppCheckoutController from './modal-app-checkout.controller'
import ModalSendEmailController from './modal-send-email.controller'
import PaginateFilter from './paginate.filter'
import HtmlFilter from './html.filter'
import ParseUrlFilter from './parse-url.filter'
import LeadFormDirective from './lead-form.directive'
import BookingFormDirective from './booking-form.directive'
import OrderFormDirective from './order-form.directive'
import ReviewFormDirective from './review-form.directive'
import MembershipFormDirective from './membership-form.directive'
import ContractFormDirective from './contract-form.directive'
import ContactFormDirective from './contact-form.directive'
import ContactLocationFormDirective from './contact-location-form.directive'
import ContentEditableDirective from './content-editable.directive'
import ElasticDirective from './elastic.directive'
import QuestionnaireSlideoutDirective from './questionnaire-slideout.directive'
import LeadSourcesSlideoutDirective from './lead-sources-slideout.directive'
import TasksSlideoutDirective from './tasks-slideout.directive'
import AddPaymentSlideoutDirective from './add-payment-slideout.directive'
import AddEventResponseSlideoutDirective from './add-event-response-slideout.directive'
import SettingsSlideoutDirective from './settings-slideout.directive'
import PaymentMethodsSlideoutDirective from './payment-methods-slideout.directive'
import PrimaryLocationSlideoutDirective from './primary-location-slideout.directive'
import auth from 'components/auth'
import home from 'components/home'
import study from 'components/study'
import article from 'components/article'
import service from 'components/service'
import product from 'components/product'
import event from 'components/event'
import corporate from 'components/corporate'
import order from 'components/order'
import me from 'components/me'
import lead from 'components/lead'
import review from 'components/review'
import customer from 'components/customer'
import supplier from 'components/supplier'
import membership from 'components/membership'
import contract from 'components/contract'
import course from 'components/course'
import subscription from 'components/subscription'
import location from 'components/location'

angular.module('app', [uirouter, uibootstrap, ngsantitize, ngAnimate, ngfileupload, checklistModel, angularChartist, LeadFormDirective, BookingFormDirective, OrderFormDirective, ReviewFormDirective, MembershipFormDirective, ContractFormDirective, ContactFormDirective, ContactLocationFormDirective, ContentEditableDirective, ElasticDirective, 'angular-parallax', 'angularGrid', 'uiGmapgoogle-maps', 'angular-slideout', 'ui.bootstrap.datetimepicker', 'angularMoment', auth, home, study, article, service, product, event, corporate, order, me, lead, review, customer, supplier, membership, contract, course, subscription, location])
  .config(routing)
  .run(run)
  .service('AppService', AppService)
  .directive('questionnaireSlideout', QuestionnaireSlideoutDirective)
  .directive('leadSourcesSlideout', LeadSourcesSlideoutDirective)
  .directive('tasksSlideout', TasksSlideoutDirective)
  .directive('addPaymentSlideout', AddPaymentSlideoutDirective)
  .directive('addEventResponseSlideout', AddEventResponseSlideoutDirective)
  .directive('settingsSlideout', SettingsSlideoutDirective)
  .directive('paymentMethodsSlideout', PaymentMethodsSlideoutDirective)
  .directive('primaryLocationSlideout', PrimaryLocationSlideoutDirective)
  .controller('ShellController', ShellController)
  .controller('ModalLinkController', ModalLinkController)
  .controller('ModalVideoController', ModalVideoController)
  .controller('ModalExternalResourceController', ModalExternalResourceController)
  .controller('ModalContactLocationController', ModalContactLocationController)
  .controller('ModalAppCheckoutController', ModalAppCheckoutController)
  .controller('ModalSendEmailController', ModalSendEmailController)
  .filter('PaginateFilter', PaginateFilter)
  .filter('HtmlFilter', HtmlFilter)
  .filter('ParseUrlFilter', ParseUrlFilter)