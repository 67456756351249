ProductStoreController.$inject = ['$rootScope', 'ProductService', '$scope', '$stateParams', 'AppService', '$timeout', 'angularGridInstance', '$state']

export default function ProductStoreController($rootScope, ProductService, $scope, $stateParams, AppService, $timeout, angularGridInstance, $state) {
  var vm = this

  function getProductStore(profileId, slug) {
    ProductService.getProductStore(profileId, slug).then(res => {
      vm.productStoreProducts = new Array()
      vm.productStore = res.data

      if (vm.productStore.productCategories.length > 0) {
        var productCategoryProducts = vm.productStore.productCategories.map(productCategory => productCategory.products).filter(e => e).flat()
        productCategoryProducts = [...new Map(productCategoryProducts.map(item => [item.id, item])).values()]

        var productCategoryCategories = vm.productStore.productCategories.map(productCategory => productCategory.productCategories).filter(e => e).flat()
        var productCategoryCategoriesInclProducts = new Array()
        var promise = new Promise((resolve, reject) => {
          productCategoryCategories.forEach(productCategory => {
            ProductService.getProductCategory(profileId, productCategory.slug).then(res => {
              productCategoryCategoriesInclProducts.push(res.data)
              resolve()
            })
          })
        })

        promise.then(() => {
          var productCategoryCategoryProducts = productCategoryCategoriesInclProducts.map(productCategory => productCategory.products).filter(e => e).flat()

          var duplicates = [].concat(productCategoryProducts.filter(obj1 => productCategoryCategoryProducts.every(obj2 => obj1.id === obj2.id)), productCategoryCategoryProducts.filter(obj2 => productCategoryProducts.every(obj1 => obj2.id === obj1.id)))
          duplicates.forEach(product => {
            var index = productCategoryCategoryProducts.indexOf(product)
            productCategoryCategoryProducts.splice(index, 1)
          })

          vm.productStoreProducts = productCategoryProducts.concat(productCategoryCategoryProducts)
          $scope.$apply()
        })
      }
    })
  }
  function doUploadCsv(file) {
    AppService.uploadFile(file).then(res => {
      var name = res.data.result.files.file[0].name
      AppService.getFile(name).then(res => {
        var lines = res.data.split(/\r\n/)
        var headers = lines.shift().split(/,(?=\S)/)
        var rows = lines.filter(line => line !== headers && line).map(row => row.split(/,(?=\S)/))

        vm.putProductCounter = new Number()
        vm.rows = rows.length
        vm.uploadedCsv = true
        $rootScope.$on('putProductCounter', (event, data) => {
          vm.putProductCounter = data
          $timeout(function() {
            vm.hideAlert('uploadedCsv')
          }, 5000)
        })

        new Promise((resolve, reject) => {
          ProductService.getSuppliers(sessionStorage.getItem('profileId')).then(res => {
            var suppliers = res.data

            ProductService.getProductCategories(sessionStorage.getItem('profileId')).then(res => {
              var productCategories = res.data
              
              rows.forEach(row => {
                row.forEach((v, i) => {
                  if ([undefined, ',', ';'].includes(v)) v = ''
                  row[i] = v.trim()
                })

                if (headers[0] === 'productNo') {
                  ProductService.putProducts(productCategories, vm.products, suppliers, row).then(() => resolve())
                } else if (headers[0] === 'variationNo') {
                  ProductService.putProductVariations(productCategories, vm.products, suppliers, row).then(() => resolve())
                }
              })
            })
          })
        }).then(() => {
          vm.products = null
          $timeout(function() {
            getProductStore(sessionStorage.getItem('profileId'), $stateParams.slug)
          }, 100)
        })
      })
    })
  }

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      ProductService.getProductStores(profile.id).then(res => vm.productStores = res.data)
      getProductStore(profile.id, $stateParams.slug)
      vm.settings = profile.settings.components.find(component => component.name === 'products')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.uploadCsv = function(file) {
    AppService.getContainers().then(res => {
      var containers = res.data
      if (containers.find(container => container.name === sessionStorage.getItem('profileId'))) {
        doUploadCsv(file)
      } else {
        AppService.postContainer().then(() => doUploadCsv(file))
      }
    })
  }
  vm.toggleManageSuppliers = function() {
    vm.manageSuppliers = !vm.manageSuppliers
  }
  vm.toggleManageStockLocations = function() {
    vm.manageStockLocations = !vm.manageStockLocations
  }
  vm.toggleManageCategories = function() {
    vm.manageCategories = !vm.manageCategories
  }
  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components)
  }
  vm.setSortBy = function(property) {
    var copy = vm.productStoreProducts
    vm.productStoreProducts = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.productStoreProducts = copy
    }, 100)
  }
  vm.setPromoted = function(product) {
    ProductService.patchProduct(product.id, 'isPromoted', !product.isPromoted).then(() => getProductStore(sessionStorage.getItem('profileId'), $stateParams.slug))
  }
  vm.setPublished = function(product) {
    ProductService.patchProduct(product.id, 'isPublished', !product.isPublished).then(() => getProductStore(sessionStorage.getItem('profileId'), $stateParams.slug))
  }
  vm.delete = function(product) {
    vm.confirmDelete = true
    vm.productToDelete = product
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    ProductService.deleteProduct(vm.productToDelete).then(() => getProductStore(sessionStorage.getItem('profileId'), $stateParams.slug))
  }
  vm.setProperty = function(id, key, value) {
    if (key === 'product') ProductService.patchProduct(id, 'slug', AppService.toSlug(value))
    ProductService.patchProduct(id, key, value).then(() => getProductStore(sessionStorage.getItem('profileId'), $stateParams.slug))
  }
  vm.numberOfPages = function() {
    return parseInt(vm.productStore.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}