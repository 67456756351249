import angular from 'angular'

contactForm.$inject = ['CustomerService', 'AppService', '$timeout']

function contactForm(CustomerService, AppService, $timeout) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.contact = new Object()
      CustomerService.getCustomers(sessionStorage.getItem('profileId')).then(res => {
        scope.customers = res.data
        scope.customers.forEach(customer => customer.contact.name = customer.contact.lastName ? customer.contact.firstName + ' ' + customer.contact.lastName : customer.contact.firstName)
        scope.customers.sort((a, b) => {   
          if (a.contact.name > b.contact.name) return 1
          if (a.contact.name < b.contact.name) return -1
          return 0
        })
      })

      scope.addContact = function() {
        scope.submitted = true

        CustomerService.getCustomerById(scope.contact.id).then(res => {
          var customer = res.data
          if (scope.$$topModalIndex === 0) scope.$close(customer.contact)
        })
      }
      scope.createContact = function() {
        scope.submitted = true
        
        AppService.postContact(scope.contact).then(res => {
          var contact = res
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close(contact)
          shell.postedContact = true
          $timeout(function() {
            shell.hideAlert('postedContact')
          }, 5000)
        })
      }
    },
    template: require('./_form-contact.html')
  }
}

export default angular.module('directives.contactForm', [])
  .directive('contactForm', contactForm)
  .name