productForm.$inject = ['$rootScope', 'ProductService', 'AppService', '$timeout', '$uibModal']

export default function productForm($rootScope, ProductService, AppService, $timeout, $uibModal) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      product: '=',
      currency: '='
    },
    link: function(scope, elem, attrs) {
      scope.$parent[attrs.property] = this
      this.isPristine = () => scope.productForm.$pristine

      scope.filePath = $rootScope.filePath
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)
      ProductService.getSuppliers(sessionStorage.getItem('profileId')).then(res => scope.suppliers = res.data)
      ProductService.getStockLocations(sessionStorage.getItem('profileId')).then(res => scope.stockLocations = res.data)
      ProductService.getProductStores(sessionStorage.getItem('profileId')).then(res => scope.productStores = res.data)
      ProductService.getProductCategories(sessionStorage.getItem('profileId')).then(res => scope.productCategories = res.data)

      scope.uploadMedias = function(files, placement, invalidFiles) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!scope.product.medias) scope.product.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingProductToMediasSamePlacement = scope.product.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newProductToMediaSamePlacement = {
                order: existingProductToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newProductToMediaSamePlacement.originalMedia = originalMedia
              scope.product.medias.push(newProductToMediaSamePlacement)
              if (existingProductToMediasSamePlacement.length === 1 && existingProductToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = scope.product.medias.findIndex(media => media === existingProductToMediasSamePlacement[0], 1)
                scope.product.medias.splice(index, 1)
                newProductToMediaSamePlacement.order = newProductToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.addExternalResource = function() {
        $uibModal.open({
          controller: 'ModalExternalResourceController',
          controllerAs: 'modalExternalResource',
          template: require('../../app/external-resource-modal-new.html')
        }).result.then(externalResource => {
          if (externalResource) {
            scope.product.externalResources.push(externalResource)
          }
        })
      }
      scope.uploadMedias2 = function(files, placement, productVariation) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            var index = scope.product.productVariations.findIndex(productVariation2 => productVariation2 === productVariation)
            if (!scope.product.productVariations[index].medias) scope.product.productVariations[index].medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingProductVariationToMediasSamePlacement = scope.product.productVariations[index].medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newProductVariationToMediaSamePlacement = {
                order: existingProductVariationToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newProductVariationToMediaSamePlacement.originalMedia = originalMedia
              scope.product.productVariations[index].medias.push(newProductVariationToMediaSamePlacement)
              if (existingProductVariationToMediasSamePlacement.length === 1 && existingProductVariationToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index2 = scope.product.productVariations[index].medias.findIndex(media => media === existingProductVariationToMediasSamePlacement[0], 1)
                scope.product.productVariations[index].medias.splice(index2, 1)
                newProductVariationToMediaSamePlacement.order = newProductVariationToMediaSamePlacement.order - 1
              }
            })
          })
        })
      }
      scope.removeMedia = function(media) {
        var index = scope.product.medias.findIndex(media2 => media2.id === media.id)
        scope.product.medias.splice(index, 1)
      }
      scope.removeMedia2 = function(media, productVariation) {
        var index = scope.product.productVariations.findIndex(productVariation2 => productVariation2 === productVariation)
        var index2 = scope.product.productVariations[index].medias.findIndex(media2 => media2.id === media.id)
        scope.product.productVariations[index].medias.splice(index2, 1)
      }
      scope.deleteProductVariation = function(productVariation) {
        var higherIndexes = scope.product.productVariations.filter((productVariation2, index) => index > scope.product.productVariations.indexOf(productVariation))
        higherIndexes.forEach(productVariation => productVariation.order = productVariation.order - 1)

        var index = scope.product.productVariations.indexOf(productVariation)
        scope.product.productVariations.splice(index, 1)
        if (scope.product.productVariations.length === 0) scope.product.isFamily = false
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-product.html')
  }

  return directive
}