OrderService.$inject = ['$http', 'AppService']

export default function OrderService($http, AppService) {
  return {
    getOrderStatuses: function() {
      return $http.get('/api-prema2/orderStatuses', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getDeliveryOptions: function(profileId) {
      return $http.get('/api-prema2/deliveryOptions?filter[where][profileId]=' + profileId + '&filter[include]=supplier')
    },
    putDeliveryOption: function(deliveryOption) {
      deliveryOption.profileId = sessionStorage.getItem('profileId')
      return $http.put('/api-prema2/deliveryOptions', deliveryOption, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteDeliveryOption: function(deliveryOption) {
      return $http.delete('/api-prema2/deliveryOptions/' + deliveryOption.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getOrders: function() {
      return $http.get('/api-prema2/orders?filter={"where":{"profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}},"orderStatus",{"relation":"activities","scope":{"include":"activityType"}}],"order":"createdAt DESC"}')
    },
    getOrder: function(referenceNo) {
      return $http.get('/api-prema2/orders/findOne?filter={"where":{"referenceNo":"' + referenceNo + '","profileId":"' + sessionStorage.getItem('profileId') + '"},"include":[{"relation":"contact","scope":{"include":["contactType","locations"]}},"orderStatus","orderLineItems",{"relation":"activities","scope":{"order":"createdAt DESC","include":[{"relation":"createdBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},{"relation":"updatedBy","scope":{"include":{"relation":"contact","scope":{"include":{"relation":"medias","scope":{"include":["media","mediaPlacement"]}}}}}},"activityType"]}}]}')
    },
    getOrderById: function(orderId) {
      return $http.get('/api-prema2/orders/' + orderId + '?filter[include]=contact')
    },
    putOrder: function(order) {
      order.updatedAt = new Date()
      order.updatedById = localStorage.getItem('userId')

      return $http.get('/api-prema2/orderLineItems?filter[where][orderId]=' + order.id).then(res => {
        var orderLineItems = res.data

        var diffs = [].concat(order.orderLineItems.filter(obj1 => orderLineItems.every(obj2 => obj1.product !== obj2.product)), orderLineItems.filter(obj2 => order.orderLineItems.every(obj1 => obj2.product !== obj1.product)))
        if (diffs.length > 0) diffs.forEach(orderLineItem => {
          if (!orderLineItems.includes(orderLineItem)) {
            var orderLineItem = {
              product: {
                productNo: orderLineItem.product.productNo ? orderLineItem.product.productNo : undefined,
                product: orderLineItem.product.product,
                unitSalePrice: orderLineItem.product.unitSalePrice,
                id: orderLineItem.product.id
              },
              count: orderLineItem.count,
              totalValue: orderLineItem.totalValue,
              orderId: order.id
            }
  
            $http.post('/api-prema2/orderLineItems', orderLineItem, { headers: { 'Authorization': localStorage.getItem('token') } })
          } else {
            $http.delete('/api-prema2/orderLineItems/' + orderLineItem.id, { headers: { 'Authorization': localStorage.getItem('token') } })
          }
        })

        if (order.contactId) {
          $http.get('/api-prema2/contacts/findOne?filter[where][id]=' + order.contactId).then(res => {
            var contact = res.data
    
            if (!angular.equals(order.contact, contact)) $http.put('/api-prema2/contacts/' + contact.id, order.contact)
          })
        }
  
        return $http.put('/api-prema2/orders/' + order.id, order, { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
          var order2 = res.data
  
          var auditLog = {
            objectId: order2.id.toString(),
            createdAt: order2.updatedAt,
            profileId: sessionStorage.getItem('profileId'),
            createdById: order2.updatedById,
            componentId: AppService.componentOrder,
            logTypeId: AppService.logTypeUpdate
          }
  
          return AppService.postAuditLog(auditLog)
        })
      })
    },
    patchOrder: function(id, key, value) {
      var data = {
        [key]: value,
        updatedAt: new Date(),
        updatedById: localStorage.getItem('userId')
      }

      return $http.patch('/api-prema2/orders/' + id, data).then(res => {
        var order = res.data

        var auditLog = {
          objectId: order.id.toString(),
          createdAt: order.updatedAt,
          profileId: sessionStorage.getItem('profileId'),
          createdById: localStorage.getItem('userId'),
          componentId: AppService.componentOrder,
          logTypeId: key === 'isRead' ? AppService.logTypeRead : AppService.logTypeUpdate
        }

        return AppService.postAuditLog(auditLog)
      })
    }
  }
}