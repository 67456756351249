articleCategoriesSlideout.$inject = ['ArticleService']

export default function articleCategoriesSlideout(ArticleService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '='
    },
    link: function(scope, elem, attrs) {
      function getArticleCategories(profileId) {
        ArticleService.getArticleCategories(profileId).then(res => scope.articleCategories = res.data)
      }

      getArticleCategories(sessionStorage.getItem('profileId'))

      scope.toggleManageCategories = function() {
        new Promise((resolve, reject) => {
          scope.articleCategories.forEach(articleCategory => ArticleService.putArticleCategory(articleCategory).then(() => resolve()))
        }).then(() => getArticleCategories(sessionStorage.getItem('profileId')))
        
        scope.slideout = false
      }
      scope.addCategory = function() {
        scope.articleCategories.push({
          order: scope.articleCategories.length + 1
        })
      }
      scope.deleteCategory = function(articleCategory) {
        ArticleService.deleteArticleCategory(articleCategory).then(() => getArticleCategories(sessionStorage.getItem('profileId')))
      }
    },
    template: require('./_slideout-categories.html')
  }

  return directive
}