routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.basket', {
      url: '/basket',
      template: require('./basket.html'),
      controller: 'BasketController',
      controllerAs: 'basket',
      resolve: {
        $title: function() {
          return 'Basket'
        }
      }
    })
    .state('shell.checkout', {
      url: '/checkout',
      template: require('./checkout.html'),
      controller: 'CheckoutController',
      controllerAs: 'checkout',
      resolve: {
        $title: function() {
          return 'Checkout'
        }
      }
    })
    .state('shell.deliveryInformation', {
      url: '/delivery-information',
      template: require('./delivery-information.html'),
      controller: 'DeliveryInformationController',
      controllerAs: 'deliveryInformation',
      resolve: {
        $title: function() {
          return 'Delivery Information'
        }
      }
    })
    .state('shell.paymentMethods', {
      url: '/payment-methods',
      template: require('./payment-methods.html'),
      controller: 'PaymentMethodsController',
      controllerAs: 'paymentMethods',
      resolve: {
        $title: function() {
          return 'Payment Methods'
        }
      }
    })
    .state('shell.orders', {
      url: '/orders?page',
      template: require('./orders.html'),
      controller: 'OrdersController',
      controllerAs: 'orders',
      resolve: {
        $title: function() {
          return 'Orders'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.order', {
      url: '/orders/:referenceNo',
      template: require('./order.html'),
      controller: 'OrderController',
      controllerAs: 'order',
      resolve: {
        order: ['OrderService', '$stateParams', function(OrderService, $stateParams) {
          return OrderService.getOrder($stateParams.referenceNo).then(res => res.data)
        }],
        $title: ['order', function(order) {
          return 'Ref. &#35;' + order.referenceNo
        }]
      }
    })
}