import angular from 'angular'

membershipForm.$inject = ['AppService', '$timeout', '$state']

function membershipForm(AppService, $timeout, $state) {
  return {
    restrict: 'E',
    replace: true,
    link: function(scope, elem, attrs) {
      scope.membership = new Object()
      scope.dateOptions = {
        startingDay: 1
      }

      scope.toggleStartDate = function() {
        scope.startDate = !scope.startDate
      }
      scope.createMembership = function() {
        scope.submitted = true
        
        AppService.postMembership(scope.membership).then(res => {
          var membership = res.data
          var shell = scope.$$topModalIndex === 0 ? scope.$$prevSibling.shell : scope.$parent.shell
          if (scope.$$topModalIndex === 0) scope.$close()
          shell.postedMembership = true
          $state.go('shell.membership', { referenceNo: membership.referenceNo })
          $timeout(function() {
            shell.hideAlert('postedMembership')
          }, 5000)
        })
      }
    },
    template: require('./_form-membership.html')
  }
}

export default angular.module('directives.membershipForm', [])
  .directive('membershipForm', membershipForm)
  .name