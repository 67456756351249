import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './lead.routes'
import LeadService from './lead.service'
import LeadsController from './leads.controller'
import LeadController from './lead.controller'
import './lead.less'

export default angular.module('app.lead', [uirouter])
  .config(routing)
  .service('LeadService', LeadService)
  .controller('LeadsController', LeadsController)
  .controller('LeadController', LeadController)
  .name