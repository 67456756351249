routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.leads', {
      url: '/enquiries?page',
      template: require('./leads.html'),
      controller: 'LeadsController',
      controllerAs: 'leads',
      resolve: {
        $title: function() {
          return 'Enquiries'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.lead', {
      url: '/enquiries/:id',
      template: require('./lead.html'),
      controller: 'LeadController',
      controllerAs: 'lead',
      resolve: {
        lead: ['LeadService', '$stateParams', function(LeadService, $stateParams) {
          return LeadService.getLead($stateParams.id).then(res => res.data)
        }],
        $title: ['lead', function(lead) {
          var name
          if (lead.contactId) {
            name = lead.contact.lastName ? lead.contact.firstName + ' ' + lead.contact.lastName : lead.contact.firstName
          } else {
            name = ''
          }
          return name
        }]
      }
    })
}