TermsController.$inject = ['$rootScope', '$transitions', 'AppService']

export default function TermsController($rootScope, $transitions, AppService) {
  var vm = this

  var copy
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      copy = angular.copy(profile.settings.terms)
    }
  })
  
  $transitions.onBefore({}, () => {
    if (!angular.equals($rootScope.profile.settings.terms, copy)) return AppService.patchSettings('terms', $rootScope.profile.settings.terms, $rootScope.profile.settings.id)
  }, {invokeLimit: 1})
}