NewServiceController.$inject = ['$scope', 'ServiceService', 'AppService', '$uibModal', '$transitions', '$timeout']

export default function NewServiceController($scope, ServiceService, AppService, $uibModal, $transitions, $timeout) {
  var vm = this

  function saveSelection() {
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        var ranges = []
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
        return ranges
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange()
    }
    return null
  }

  var shell = $scope.$parent.shell
  vm.service = ServiceService.initService()

  vm.insertLink = function() {
    AppService.savedSel = saveSelection()

    $uibModal.open({
      controller: 'ModalLinkController',
      controllerAs: 'modalLink',
      template: require('../../app/link-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.setPromoted = function() {
    vm.service.isPromoted = !vm.service.isPromoted
  }
  vm.setPublished = function() {
    vm.service.isPublished = !vm.service.isPublished
  }
  $transitions.onBefore({}, () => {
    if (!vm.cancelled && !$scope.pristine.isPristine()) {
      ServiceService.postService(vm.service).then(() => {
        if (sessionStorage.getItem('taskId') && sessionStorage.getItem('contactId')) {
          AppService.postTaskResponse(sessionStorage.getItem('taskId'), sessionStorage.getItem('contactId')).then(() => {
            shell.addedRGA = true
            sessionStorage.removeItem('taskId')
            sessionStorage.removeItem('contactId')
            $timeout(function() {
              shell.hideAlert('addedRGA')
            }, 5000)
          })
        }
      })
    } else {
      sessionStorage.removeItem('taskId')
      sessionStorage.removeItem('contactId')
    }
  }, {invokeLimit: 1})
}