privacyForm.$inject = []

export default function privacyForm() {
  var directive = {
    restrict: 'E',
    replace: true,
    template: require('./_form-privacy.html')
  }

  return directive
}