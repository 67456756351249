import angular from 'angular'

function membershipCard() {
  return {
    restrict: 'E',
    replace: true,
    template: require('./_card-membership.html')
  }
}

export default angular.module('directives.membershipCard', [])
  .directive('membershipCard', membershipCard)
  .name