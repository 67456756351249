ModalAccreditationController.$inject = ['AppService', '$uibModalInstance']

export default function ModalAccreditationController(AppService, $uibModalInstance) {
  var vm = this

  vm.uploadMedias = function(files, invalidFiles) {
    files.forEach((file, index) => {
      AppService.uploadMedia(file).then(res => {
        vm.file = res.data

        if (!vm.accreditation) vm.accreditation = new Object()
        vm.accreditation.mediaId = vm.file.id
      })
    })
  }
  vm.close = function() {
    $uibModalInstance.close(vm.accreditation)
  }
}