courseForm.$inject = ['$rootScope', 'AppService', '$timeout', '$uibModal']

export default function courseForm($rootScope, AppService, $timeout, $uibModal) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      course: '=',
      currency: '='
    },
    link: function(scope, elem, attrs) {
      scope.$parent[attrs.property] = this
      this.isPristine = () => scope.courseForm.$pristine

      scope.filePath = $rootScope.filePath
      AppService.getMediaPlacements().then(res => scope.mediaPlacements = res.data)
      AppService.getTimeUnits().then(res => scope.timeUnits = res.data)

      scope.uploadMedias = function(files, placement, invalidFiles) {
        files.forEach((file, index) => {
          scope.uploadedFile = true
          $rootScope.$on('uploadProgress', (event, data) => scope.uploadProgress = data)

          AppService.uploadMedia(file).then(res => {
            var file = res.data

            scope.uploadProgress = 100.0
            $timeout(function() {
              scope.hideAlert('uploadedFile')
            }, 5000)

            if (!scope.course.medias) scope.course.medias = new Array()

            var media, originalMedia
            new Promise((resolve, reject) => {
              if (file.type.includes('video')) {
                AppService.getMediaById(file.attrs.mediaId).then(res => {
                  originalMedia = file
                  media = res.data
                  resolve()
                })
              } else {
                media = file
                resolve()
              }
            }).then(() => {
              var existingCourseToMediasSamePlacement = scope.course.medias.filter(media => media.mediaPlacement.placement.toLowerCase() == placement)
              var mediaPlacement = scope.mediaPlacements.find(mediaPlacement => mediaPlacement.placement.toLowerCase() == placement)
              var newCourseToMediaSamePlacement = {
                order: existingCourseToMediasSamePlacement.length + 1,
                mediaId: file.id,
                mediaPlacementId: mediaPlacement.id,
                media: media,
                mediaPlacement: mediaPlacement
              }
              if (originalMedia) newCourseToMediaSamePlacement.originalMedia = originalMedia
              scope.course.medias.push(newCourseToMediaSamePlacement)
              if (existingCourseToMediasSamePlacement.length === 1 && existingCourseToMediasSamePlacement[0].mediaPlacement.isReplace) {
                var index = scope.course.medias.findIndex(media => media === existingCourseToMediasSamePlacement[0], 1)
                scope.course.medias.splice(index, 1)
                newCourseToMediaSamePlacement.order = newCourseToMediaSamePlacement.order - 1
              }
            })
          })
        })
        invalidFiles.forEach((file, index) => {
          scope.invalidFile = true
          scope.file = file
          $timeout(function() {
            scope.hideAlert('invalidFile')
          }, 5000)
        })
      }
      scope.addLearningOutcome = function() {
        if (!scope.course.learningOutcomes) scope.course.learningOutcomes = new Array()
        scope.course.learningOutcomes.length++
      }
      scope.deleteLearningOutcome = function(index) {
        scope.course.learningOutcomes.splice(index, 1)
      }
      scope.addExternalResource = function() {
        $uibModal.open({
          controller: 'ModalExternalResourceController',
          controllerAs: 'modalExternalResource',
          template: require('../../app/external-resource-modal-new.html')
        }).result.then(externalResource => {
          if (externalResource) {
            scope.course.externalResources.push(externalResource)
          }
        })
      }
      scope.removeMedia = function(media) {
        var index = scope.course.medias.findIndex(media2 => media2.id === media.id)
        scope.course.medias.splice(index, 1)
      }
      scope.hideAlert = function(alert) {
        scope[alert] = false
      }
    },
    template: require('./_form-course.html')
  }

  return directive
}