HomeController.$inject = ['AuthService', 'AppService', '$rootScope', '$uibModal', '$http', 'EventService', 'LeadService', 'OrderService', 'ArticleService', 'ProductService', 'StudyService', 'ServiceService', 'SupplierService', 'CorporateService', 'ReviewService', 'CustomerService', 'MembershipService', 'ContractService', 'CourseService', 'LocationService', '$state']

export default function HomeController(AuthService, AppService, $rootScope, $uibModal, $http, EventService, LeadService, OrderService, ArticleService, ProductService, StudyService, ServiceService, SupplierService, CorporateService, ReviewService, CustomerService, MembershipService, ContractService, CourseService, LocationService, $state) {
  var vm = this

  function processAuditLogs(auditLogs) {
    auditLogs.forEach(auditLog => {
      switch(auditLog.component.component) {
        case "Event":
          EventService.getEventById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Lead":
          LeadService.getLeadById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Contract":
          ContractService.getContractById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Order":
          OrderService.getOrderById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Post":
          ArticleService.getArticleById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Product":
          ProductService.getProductById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Study":
          StudyService.getStudyById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Review":
          ReviewService.getReviewById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Service":
          ServiceService.getServiceById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Supplier":
          SupplierService.getSupplierById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Worker":
          CorporateService.getEmployeeById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Customer":
          CustomerService.getCustomerById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Questionnaire":
          AppService.getQuestionnaireById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Subscription":
          ServiceService.getSubscriptionById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Task":
          AppService.getTaskById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Booking":
          EventService.getBookingById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Membership":
          MembershipService.getMembershipById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Course":
          CourseService.getCourseById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        case "Location":
          LocationService.getLocationById(auditLog.objectId).then(res => auditLog.object = res.data)
          break
        default:
          break
      }
    })

    return auditLogs
  }

  AppService.getComponents().then(res => vm.components = res.data)

  // From Prema
  ArticleService.getArticles('61f8e43a79fd747201d0d3da').then(res => vm.articles = res.data)

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {

      // Onboarding
      AuthService.getCurrentUser().then(res => {
        vm.currentUser = res.data

        AppService.getComponentByName(vm.currentUser.contact.contactType.type === 'Owner' ? 'Customer' : 'Worker').then(res => {
          var component = res.data

          AppService.getProfileTasks(vm.currentUser.contact.contactType.type === 'Owner' ? '61f8e43a79fd747201d0d3da' : sessionStorage.getItem('profileId'), component.id).then(res => {
            vm.profileTasks = res.data

            AppService.getTaskResponses(vm.currentUser.contactId).then(res => {
              vm.taskResponses = res.data

              var intersections = vm.profileTasks.filter(profileTask => vm.taskResponses.some(taskResponse => profileTask.taskId === taskResponse.taskId))

              vm.profileTasks.forEach(profileTask => {
                profileTask.isCompleted = intersections.find(intersection => intersection.taskId === profileTask.taskId) ? true : false
              })

              // Prema workflows
              if (vm.profileTasks.length === vm.taskResponses.length) {
                $uibModal.open({
                  controller: 'ModalAppCheckoutController',
                  controllerAs: 'modalAppCheckout',
                  template: require('../../app/app-checkout-modal-new.html'),
                  resolve: {
                    contact: function() {
                      return vm.currentUser.contact
                    }
                  }
                }).result.then(angular.noop, angular.noop)
              }
            })
          })
        })
      })
      
      // Analytics
      var now = new Date()
      var xDays = 1
      var minusXDays = new Date(angular.copy(now).setDate(angular.copy(now).getDate() - xDays))

      $http({
        method: 'POST',
        url: '/api-cloudflare/client/v4/graphql',
        data: {
          query: `{
            viewer {
              zones(filter: {zoneTag: "` + profile.settings.zoneIdentifer + `"}) {
                httpRequests1hGroups(limit: 100, filter: {datetime_geq: "` + minusXDays.toISOString() + `", datetime_leq: "` + now.toISOString() + `"}) {
                  uniq {
                    uniques
                  }
                }
              }
            }
          }`
        },
        headers: {
          'X-Auth-Key': '25d292c75c18bf85326ca82adbd8bfc490b75',
          'X-Auth-Email': 'lukefisher@me.com'
        }
      }).then(res => {
        var data = res.data.data
        vm.uniques = data.viewer.zones[0].httpRequests1hGroups[0].uniq.uniques

        // Charts
        // vm.chartOptions = {
        //   fullWidth: true,
        //   chartPadding: {
        //     right: 40
        //   }
        // }
        // var uniques = data.timeseries.map(timeseries => timeseries.uniques.all)
    
        // var timeOptions = {
        //   weekday: 'short',
        //   day: 'numeric',
        //   hour12: false
        // }
        // var timestamps = data.timeseries.map(timeseries => new Date(timeseries.since).toLocaleDateString('en-CY', timeOptions))
        // vm.uniquesRecent7 = {
        //   labels: timestamps,
        //   series: [
        //     uniques
        //   ]
        // }
      })

      LeadService.getLeads(profile.id).then(res => {
        var leads = res.data
        vm.leads = leads.filter(lead => lead.createdAt > minusXDays.toISOString())
      })
      EventService.getBookings().then(res => {
        var bookings = res.data
        vm.bookings = bookings.filter(booking => booking.createdAt > minusXDays.toISOString())
      })
      EventService.getEventGuests().then(res => {
        var eventGuests = res.data
        vm.eventGuests = eventGuests.filter(eventGuest => eventGuest.createdAt > minusXDays.toISOString())
      })
      OrderService.getOrders().then(res => {
        var orders = res.data
        vm.orders = orders.filter(order => order.createdAt > minusXDays.toISOString())
      })
      MembershipService.getMemberships().then(res => {
        var memberships = res.data
        vm.memberships = memberships.filter(membership => membership.createdAt > minusXDays.toISOString())
      })
      ContractService.getContracts().then(res => {
        var contracts = res.data
        vm.contracts = contracts.filter(contract => contract.createdAt > minusXDays.toISOString())
      })
      AppService.getPayments().then(res => {
        var payments = res.data
        var paymentsNetValues = payments.filter(payment => payment.createdAt > minusXDays.toISOString()).map(payment => payment.netValue)
        vm.paymentsTotalNetValue = paymentsNetValues.reduce((a, c) => a + c, 0)
      })
      CustomerService.getCustomers(profile.id).then(res => {
        var customers = res.data
        vm.customers = customers.filter(customer => customer.createdAt > minusXDays.toISOString())
      })
      SupplierService.getSuppliers(profile.id).then(res => {
        var suppliers = res.data
        vm.suppliers = suppliers.filter(supplier => supplier.createdAt > minusXDays.toISOString())
      })
      ReviewService.getReviews(profile.id).then(res => {
        var reviews = res.data
        vm.reviews = reviews.filter(review => review.createdAt > minusXDays.toISOString())
      })

      // Audit Logs
      AppService.getAuditLogsCount(profile.id).then(res => vm.auditLogsCount = res.data.count)
      AppService.getAuditLogsRecentX(profile.id, 5).then(res => {
        vm.auditLogs = res.data
        processAuditLogs(vm.auditLogs)
      })
    }
  })

  vm.doAction = profileTask => {
    profileTask.task.action.includes('()') ? eval('vm.' + profileTask.task.action) : $state.go(profileTask.task.action)
    sessionStorage.setItem('taskId', profileTask.taskId)
    sessionStorage.setItem('contactId', vm.currentUser.contactId)
  }
  vm.toggleManageLeadSources = function() {
    vm.manageLeadSources = !vm.manageLeadSources
  }
  vm.toggleManagePaymentMethods = function() {
    vm.managePaymentMethods = !vm.managePaymentMethods
  }
  vm.toggleManagePrimaryLocation = function() {
    vm.managePrimaryLocation = !vm.managePrimaryLocation
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
  vm.toggleFirstSellableItemMenu = function() {
    vm.firstSellableItemMenu = !vm.firstSellableItemMenu
    var firstSellableItemMenu = angular.element(document.getElementsByClassName('sellable'))[0]
    if (firstSellableItemMenu) {
      firstSellableItemMenu.style.position = 'absolute'
      firstSellableItemMenu.style.top = window.innerWidth > 767 ? 0 : 137 + 'px'
      // firstSellableItemMenu.style.marginLeft = window.innerWidth > 767 ? -192 + 'px' : -149 + 'px'
      firstSellableItemMenu.style.right = window.innerWidth > 767 ? 0 : 20 + 'px'
    }
  }
  vm.showMoreAuditLogs = function() {
    AppService.getAuditLogsRecentX(sessionStorage.getItem('profileId'), vm.auditLogs.length + 5).then(res => {
      var auditLogs = res.data
      var sliced = auditLogs.slice(vm.auditLogs.length, auditLogs.length)
      processAuditLogs(sliced)
      sliced.forEach(slice => vm.auditLogs.push(slice))
    })
  }
}