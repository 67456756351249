deliverySlideout.$inject = ['OrderService', 'ProductService']

export default function deliverySlideout(OrderService, ProductService) {
  var directive = {
    restrict: 'E',
    replace: true,
    scope: {
      slideout: '=',
      currency: '=',
    },
    link: function(scope, elem, attrs) {
      function getDeliveryOptions(profileId) {
        OrderService.getDeliveryOptions(profileId).then(res => scope.deliveryOptions = res.data)
      }

      getDeliveryOptions(sessionStorage.getItem('profileId'))
      ProductService.getSuppliers(sessionStorage.getItem('profileId')).then(res => scope.suppliers = res.data)

      scope.toggleManageDelivery = function() {
        new Promise((resolve, reject) => {
          scope.deliveryOptions.forEach(deliveryOption => OrderService.putDeliveryOption(deliveryOption).then(() => resolve()))
        }).then(() => getDeliveryOptions(sessionStorage.getItem('profileId')))

        scope.slideout = false
      }
      scope.addDeliveryOption = function() {
        scope.deliveryOptions.push({})
      }
      scope.deleteDeliveryOption = function(deliveryOption) {
        OrderService.deleteDeliveryOption(deliveryOption).then(() => getDeliveryOptions(sessionStorage.getItem('profileId')))
      }
    },
    template: require('./_slideout-delivery.html')
  }

  return directive
}