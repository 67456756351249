routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.memberships', {
      url: '/memberships?page',
      template: require('./memberships.html'),
      controller: 'MembershipsController',
      controllerAs: 'memberships',
      resolve: {
        $title: function() {
          return 'Memberships'
        }
      },
      params: {
        page: {
          value: '1',
          squash: true
        }
      }
    })
    .state('shell.membership', {
      url: '/memberships/:referenceNo',
      template: require('./membership.html'),
      controller: 'MembershipController',
      controllerAs: 'membership',
      resolve: {
        membership: ['MembershipService', '$stateParams', function(MembershipService, $stateParams) {
          return MembershipService.getMembership($stateParams.referenceNo).then(res => res.data)
        }],
        $title: ['membership', function(membership) {
          var name
          if (membership.contactId) {
            name = membership.contact.lastName ? membership.contact.firstName + ' ' + membership.contact.lastName : membership.contact.firstName
          } else {
            name = ''
          }
          return 'Ref. &#35;' + membership.referenceNo + ' ' + name
        }]
      }
    })
}