NewSupplierController.$inject = ['SupplierService', '$transitions', '$scope']

export default function NewSupplierController(SupplierService, $transitions, $scope) {
  var vm = this

  vm.supplier = SupplierService.initSupplier()

  vm.setPublished = function() {
    vm.supplier.isPublished = !vm.supplier.isPublished
  }
  $transitions.onBefore({}, () => {
    if (!vm.cancelled && !$scope.pristine.isPristine()) return SupplierService.postSupplier(vm.supplier)
  }, {invokeLimit: 1})
}