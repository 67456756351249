import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './course.routes'
import CourseService from './course.service'
import CourseCard from './course-card.directive'
import CourseFormDirective from './course-form.directive'
import AddLessonSlideoutDirective from './add-lesson.directive'
import CoursesController from './courses.controller'
import CourseController from './course.controller'
import NewCourseController from './new-course.controller'
import './course.less'

export default angular.module('app.course', [uirouter, CourseCard])
  .config(routing)
  .service('CourseService', CourseService)
  .directive('courseForm', CourseFormDirective)
  .directive('addLessonSlideout', AddLessonSlideoutDirective)
  .controller('CoursesController', CoursesController)
  .controller('CourseController', CourseController)
  .controller('NewCourseController', NewCourseController)
  .name