routes.$inject = ['$stateProvider']

export default function routes($stateProvider) {
  $stateProvider
    .state('shell.about', {
      url: '/about',
      template: require('./about.html'),
      controller: 'AboutController',
      controllerAs: 'about',
      resolve: {
        $title: function() {
          return 'About'
        }
      }
    })
    .state('shell.team', {
      url: '/team',
      template: require('./team.html'),
      controller: 'TeamController',
      controllerAs: 'team',
      resolve: {
        $title: function() {
          return 'Team'
        }
      }
    })
    .state('shell.terms', {
      url: '/terms',
      template: require('./terms.html'),
      controller: 'TermsController',
      controllerAs: 'terms',
      resolve: {
        $title: function() {
          return 'Terms & Conditions'
        }
      }
    })
    .state('shell.privacy', {
      url: '/privacy',
      template: require('./privacy.html'),
      controller: 'PrivacyController',
      controllerAs: 'privacy',
      resolve: {
        $title: function() {
          return 'Privacy Policy'
        }
      }
    })
    .state('shell.faqs', {
      url: '/faqs',
      template: require('./faqs.html'),
      controller: 'FaqsController',
      controllerAs: 'faqs',
      resolve: {
        $title: function() {
          return 'FAQs'
        }
      }
    })
    // .state('shell.locations', {
    //   url: '/locations',
    //   template: require('./locations.html'),
    //   controller: 'LocationsController',
    //   controllerAs: 'locations',
    //   resolve: {
    //     $title: function() {
    //       return 'Locations'
    //     }
    //   }
    // })
    .state('shell.employee', {
      url: '/team/:slug',
      template: require('./employee.html'),
      controller: 'EmployeeController',
      controllerAs: 'employee',
      resolve: {
        worker: ['CorporateService', '$stateParams', function(CorporateService, $stateParams) {
          return CorporateService.getEmployee(sessionStorage.getItem('profileId'), $stateParams.slug)
        }],
        $title: ['worker', function(worker) {
          return worker.data.contact.firstName + ' ' + worker.data.contact.lastName
        }]
      }
    })
    .state('shell.newEmployee', {
      url: '/team/new',
      template: require('./new-employee.html'),
      controller: 'NewEmployeeController',
      controllerAs: 'newEmployee',
      resolve: {
        $title: function() {
          return 'New Employee'
        }
      }
    })
}