AuthService.$inject = ['$http', 'AppService']

export default function AuthService($http, AppService) {
  return {
    getBusinessSectors: function() {
      return $http.get('/api-prema2/businessSectors?filter[order]=sector ASC')
    },
    registerBusiness: function(firstName, lastName, businessName, businessSectorId, email, password) {
      var profile = {
        businessName: AppService.toTitleCase(businessName),
        web: 'preview.prema.tech/?' + businessName.replace(/\s+/g, '').toLowerCase(),
        createdAt: new Date(),
        businessSectorId: businessSectorId
      }

      return $http.post('/api-prema2/profiles', profile).then(res => {
        var profile = res.data

        return $http.get('/api-prema2/contactTypes').then(res => {
          var contactTypes = res.data

          var contact = {
            displayName: AppService.toTitleCase(firstName),
            firstName: AppService.toTitleCase(firstName),
            lastName: lastName ? AppService.toTitleCase(lastName) : undefined,
            profileId: profile.id,
            contactTypeId: contactTypes[0].id
          }

          if (email) contact.communications = new Array()
              
          if (email) contact.communications.push({
            method: 'Email',
            value: email,
            order: contact.communications.length + 1,
            isPrimary: true
          })
    
          return $http.post('/api-prema2/contacts', contact).then(res => {
            var contact = res.data
    
            var sysUser = {
              email: email,
              password: password,
              profileId: profile.id,
              contactId: contact.id
            }
    
            return $http.post('/api-prema2/sysUsers', sysUser).then(res => {
              var sysUser = res.data

              if (businessName) {
                var account = {
                  businessName: AppService.toTitleCase(businessName),
                  profileId: '61f8e43a79fd747201d0d3da'
                }

                $http.post('/api-prema2/accounts', account).then(res => {
                  var account = res.data

                  var contactToAccount = {
                    contactId: contact.id,
                    accountId: account.id
                  }

                  $http.post('/api-prema2/contactToAccounts', contactToAccount)
                })
              }

              var name = businessName ? AppService.toTitleCase(businessName) : contact.lastName ? contact.firstName + ' ' + contact.lastName : contact.firstName
              var now = new Date()

              $http.get('/api-prema2/customers/count?where[profileId]=' + '61f8e43a79fd747201d0d3da').then(res => {
                var customers = res.data

                return $http.get('/api-prema2/leadSources/findOne?filter[where][profileId]=' + '61f8e43a79fd747201d0d3da' + '&filter[where][source]=Website').then(res => {
                  var leadSource = res.data

                  var data = {
                    customerNo: (customers.count + 1).toString(),
                    slug: name.replace(/&amp;/g, '').replace(/-/g, '').replace(/\s\s/g, ' ').replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
                    startDate: new Date(now).setUTCHours(0, 0, 0, 0),
                    isSysUser: true,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                    profileId: '61f8e43a79fd747201d0d3da',
                    createdById: sysUser.id,
                    updatedById: sysUser.id,
                    contactId: contact.id,
                    sysUserId: sysUser.id,
                    leadSourceId: leadSource.id
                  }
          
                  return $http.post('/api-prema2/customers', data)
                })
              })

              return $http.get('/api-prema2/workers/count?where[profileId]=' + profile.id).then(res => {
                var workers = res.data

                var name = lastName ? firstName + ' ' + lastName : firstName
                var slug = AppService.toSlug(name)
                var worker = {
                  workerNo: (workers.count + 1).toString(),
                  slug: slug,
                  startDate: new Date().setUTCHours(0, 0, 0, 0),
                  order: 1,
                  isPublished: true,
                  isSysUser: true,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  profileId: profile.id,
                  contactId: contact.id,
                  sysUserId: sysUser.id
                }

                return $http.post('/api-prema2/workers', worker).then(() => {
                  var leadSource = {
                    source: 'Website',
                    order: 1,
                    profileId: profile.id
                  }

                  return $http.post('/api-prema2/leadSources', leadSource).then(() => {
                    var settings = {
                      components: [
                        {
                          name: 'bookings'
                        },
                        {
                          name: 'contracts'
                        },
                        {
                          name: 'customers',
                          view: 'grid',
                          isPublished: true
                        },
                        {
                          name: 'events',
                          view: 'list'
                        },
                        {
                          name: 'leads',
                          isPublished: true
                        },
                        {
                          name: 'memberships',
                          view: 'grid'
                        },
                        {
                          name: 'orders'
                        },
                        {
                          name: 'products',
                          view: 'grid'
                        },
                        {
                          name: 'projects'
                        },
                        {
                          name: 'reviews',
                          view: 'grid',
                          isPublished: true
                        },
                        {
                          name: 'subscriptions',
                          view: 'grid'
                        },
                        {
                          name: 'suppliers',
                          view: 'grid',
                          isPublished: true
                        }
                      ],
                      profileId: profile.id
                    }
      
                    return $http.post('/api-prema2/settings', settings)
                  })
                })
              })
            })
          })
        })
      })
    },
    register: function(state, firstName, lastName, businessName, email, password) {
      if (state === 'registerSupplier') {
        return $http.get('/api-prema2/contactTypes').then(res => {
          var contactTypes = res.data

          var contact = {
            firstName: AppService.toTitleCase(firstName),
            lastName: lastName ? AppService.toTitleCase(lastName) : undefined,
            displayName: AppService.toTitleCase(firstName),
            profileId: sessionStorage.getItem('profileId'),
            contactTypeId: contactTypes[4].id
          }

          if (email) contact.communications = new Array()
          
          if (email) contact.communications.push({
            method: 'Email',
            value: email,
            order: contact.communications.length + 1,
            isPrimary: true
          })

          return $http.post('/api-prema2/contacts', contact).then(res => {
            var contact = res.data

            var supplier = {
              businessName: AppService.toTitleCase(businessName),
              slug: AppService.toSlug(businessName),
              createdAt: new Date(),
              updatedAt: new Date(),
              profileId: sessionStorage.getItem('profileId')
            }

            return $http.post('/api-prema2/suppliers', supplier).then(res => {
              var supplier = res.data

              var contactToSupplier = {
                contactId: contact.id,
                supplierId: supplier.id
              }

              return $http.post('/api-prema2/contactToSuppliers', contactToSupplier).then(() => {
                var sysUser = {
                  email: email,
                  password: password,
                  profileId: sessionStorage.getItem('profileId'),
                  contactId: contact.id
                }
  
                return $http.post('/api-prema2/sysUsers', sysUser)
              })
            })
          })
        })
      } else {
        return $http.get('/api-prema2/contactTypes').then(res => {
          var contactTypes = res.data

          var contact = {
            firstName: AppService.toTitleCase(firstName),
            lastName: lastName ? AppService.toTitleCase(lastName) : undefined,
            displayName: AppService.toTitleCase(firstName),
            profileId: sessionStorage.getItem('profileId'),
            contactTypeId: !businessName ? contactTypes[2].id : contactTypes[3].id
          }

          if (email) contact.communications = new Array()
          
          if (email) contact.communications.push({
            method: 'Email',
            value: email,
            order: contact.communications.length + 1,
            isPrimary: true
          })

          return $http.post('/api-prema2/contacts', contact).then(res => {
            var contact = res.data

            var sysUser = {
              email: email,
              password: password,
              profileId: sessionStorage.getItem('profileId'),
              contactId: contact.id
            }

            return $http.post('/api-prema2/sysUsers', sysUser).then(() => {
              if (businessName) {
                var account = {
                  businessName: AppService.toTitleCase(businessName),
                  profileId: sessionStorage.getItem('profileId')
                }

                return $http.post('/api-prema2/accounts', account).then(res => {
                  var account = res.data

                  var contactToAccount = {
                    contactId: contact.id,
                    accountId: account.id
                  }

                  return $http.post('/api-prema2/contactToAccounts', contactToAccount)
                })
              }
            })
          })
        })
      }
    },
    login: function(email, password) {
      var data = {
        email: email,
        password: password
      }
      
      return $http.post('/api-prema2/sysUsers/login', data).then(res => {
        localStorage.setItem('token', res.data.id)
        localStorage.setItem('expires', JSON.stringify(Date.now() + 86400000))
        localStorage.setItem('userId', res.data.userId)
      })
    },
    logout: function() {
      return $http.post('/api-prema2/sysUsers/logout?access_token=' + localStorage.getItem('token'), { headers: { 'Authorization': localStorage.getItem('token') } }).then(res => {
        localStorage.clear()
        sessionStorage.removeItem('profileId')
      })
    },
    generatePassword: function() {
      var l = 16,
      c = 'abcdefghijklmnopqrstuvwxyzACDEFGHJKLMNPQRSTUVWXYZ123456789',
      n = c.length,
      p = '!@#$+-*&_',
      o = p.length,
      r = '',
      s = Math.floor(Math.random() * (p.length - 1))
  
      n = c.length
  
      for (var i = 0; i < l; ++i) {
        if (s == i) {
          r += p.charAt(Math.floor(Math.random() * o))
        } else {
          r += c.charAt(Math.floor(Math.random() * n))
        }
      }
  
      return r
    },
    changePassword: function(password, accessToken) {
      var data = {
        password: password
      }

      return $http.patch('/api-prema2/sysUsers/' + accessToken.userId + '?access_token=' + accessToken.id, data, { headers: { 'Authorization': accessToken.id } })
    },
    resetPasswordRequest: function(email) {
      var data = {
        email: email
      }

      return $http.post('/api-prema2/sysUsers/reset', data)
    },
    getAccessTokenById: function(id) {
      return $http.get('/api-prema2/accessTokens/' + id + '?access_token=' + id, { headers: { 'Authorization': id } })
    },
    isAuthenticated: function() {
      return localStorage.getItem('token') ? true : false
    },
    getCurrentUser: function() {
      return $http.get('/api-prema2/sysUsers/' + localStorage.getItem('userId') + '?filter={"include":[{"relation":"contact","scope":{"include":[{"relation":"medias","scope":{"include":["media","mediaPlacement"]}},{"relation":"locations","scope":{"include":"country"}},"contactType"]}},{"relation":"profile","scope":{"include":{"relation":"locations","scope":{"include":"country"}}}}]}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getSysUserById: function(id) {
      return $http.get('/api-prema2/sysUsers/' + id, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getSysUserByEmail: function(email) {
      return $http.get('/api-prema2/sysUsers/findOne?filter={"where":{"email":"' + email + '"}}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    getSysUserByContactId: function(contactId) {
      return $http.get('/api-prema2/sysUsers/findOne?filter={"where":{"contactId":"' + contactId + '"}}', { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    postSysUser: function(email, password, contactId) {
      var sysUser = {
        email: email,
        password: password,
        profileId: sessionStorage.getItem('profileId'),
        contactId: contactId
      }

      return $http.post('/api-prema2/sysUsers', sysUser, { headers: { 'Authorization': localStorage.getItem('token') } })
    },
    deleteSysUser: function(sysUser) {
      return $http.delete('/api-prema2/sysUsers/' + sysUser.id, { headers: { 'Authorization': localStorage.getItem('token') } })
    }
  }
}