EventsController.$inject = ['$rootScope', 'EventService', 'AppService']

export default function EventsController($rootScope, EventService, AppService) {
  var vm = this

  vm.pastEventsOn = false
  
  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      // For calendar view
      EventService.getEvents(profile.id).then(res => vm.events = res.data)
      EventService.getBookings(profile.id).then(res => vm.bookings = res.data)

      // For list view
      EventService.getUpcomingEvents(profile.id).then(res => vm.upcomingEvents = res.data)
      EventService.getRecurringEvents(profile.id).then(res => {
        vm.recurringEvents = res.data
        vm.recurringEvents.map(recurringEvent => {
          recurringEvent.dayOfWeek = new Date(recurringEvent.startDateUtc).getDay()
          if (recurringEvent.dayOfWeek == 0) {
            recurringEvent.dayOfWeek = 7
          }
          return recurringEvent
        })
        var dayOfWeekToday = new Date().getDay()
        var daysOfWeek = [1,2,3,4,5,6,7]

        var arrOfDayOfWeek = vm.recurringEvents.map(recurringEvent => recurringEvent.dayOfWeek).sort((a, b) => a - b)
        var arrOfNotDayOfWeek = daysOfWeek.filter(item => !arrOfDayOfWeek.includes(item)).concat(arrOfDayOfWeek.filter(item => !daysOfWeek.includes(item)))
        var daysOfWeekSortByDayOfWeekToday = daysOfWeek.slice(dayOfWeekToday - 1).concat(daysOfWeek.slice(0,dayOfWeekToday - 1))
        var arrOfDayOfWeekSortByDayOfWeekToday = daysOfWeekSortByDayOfWeekToday.filter(item => !arrOfNotDayOfWeek.includes(item))

        vm.recurringEvents.sort((a, b) => arrOfDayOfWeekSortByDayOfWeekToday.indexOf(a.dayOfWeek) - arrOfDayOfWeekSortByDayOfWeekToday.indexOf(b.dayOfWeek))
      })
      EventService.getPastEvents(profile.id).then(res => vm.pastEvents = res.data)
      vm.settings = profile.settings.components.find(component => component.name === 'events')
    }
  })

  vm.viewDate = new Date()

  vm.toggleManageCategories = function() {
    vm.manageCategories = !vm.manageCategories
  }
  vm.toggleManageBookingTypes = function() {
    vm.manageBookingTypes = !vm.manageBookingTypes
  }
  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'list' ? 'calendar' : 'list'
    AppService.patchSettings('components', $rootScope.profile.settings.components, $rootScope.profile.settings.id)
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
  vm.togglePastEvents = function() {
    vm.pastEventsOn = !vm.pastEventsOn
  }
}