SuppliersController.$inject = ['$rootScope', 'SupplierService', '$stateParams', 'angularGridInstance', 'AppService', '$timeout', '$state']

export default function SuppliersController($rootScope, SupplierService, $stateParams, angularGridInstance, AppService, $timeout, $state) {
  var vm = this

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      SupplierService.getSuppliers(profile.id).then(res => vm.suppliers = res.data)
      vm.settings = profile.settings.components.find(component => component.name === 'suppliers')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.toggleManageTasks = function() {
    vm.manageTasks = !vm.manageTasks
  }
  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components, $rootScope.profile.settings.id)
  }
  vm.setSortBy = function(property) {
    var copy = vm.suppliers
    vm.suppliers = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.suppliers = copy
    }, 100)
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
  vm.numberOfPages = function() {
    return parseInt(vm.suppliers.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
}