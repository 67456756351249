LeadsController.$inject = ['$rootScope', 'LeadService', '$stateParams', '$timeout', '$uibModal', '$scope', '$window', 'CorporateService', 'AuthService', '$state']

export default function LeadsController($rootScope, LeadService, $stateParams, $timeout, $uibModal, $scope, $window, CorporateService, AuthService, $state) {
  var vm = this

  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      LeadService.getLeads(profileId).then(res => vm.leads = res.data)
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.setSortBy = function(property) {
    var copy = vm.leads
    vm.leads = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.leads = copy
    }, 100)
  }
  vm.showSendEmailModal = function() {
    $uibModal.open({
      scope: $scope,
      controller: 'ModalSendEmailController',
      controllerAs: 'modalSendEmail',
      template: require('../../app/send-email-modal-new.html')
    }).result.then(sendEmailPrefs => {
      vm.sendLeadEmails(sendEmailPrefs)
    })
  }
  vm.sendLeadEmails = function(sendEmailPrefs) {
    var leads = vm.leads.filter(lead => lead[sendEmailPrefs.filterKey] === sendEmailPrefs.filterValue)
    
    var leadContactEmails = leads.map(lead => lead.contact.communications ? lead.contact.communications.map(communication => communication.method === 'Email' ? communication.value : undefined).filter(el => el !== undefined) : undefined).filter(el => el !== undefined).filter(el => el.length).flat()

    var leadCreatedByEmails = [...new Set(leads.map(lead => lead.createdBy ? lead.createdBy.email.toLowerCase() : undefined))]

    CorporateService.getTeam(sessionStorage.getItem('profileId')).then(res => {
      var team = res.data

      var teamEmails = new Array()
      var counter = 0
      var promise = new Promise((resolve, reject) => team.filter(worker => worker.isSysUser).forEach(worker => AuthService.getSysUserById(worker.sysUserId).then(res => {
        var sysUser = res.data
        
        teamEmails.push(sysUser.email)
        counter++
        if (counter == team.filter(worker => worker.isSysUser).length) resolve()
      })))
      
      promise.then(() => {
        var leadEmails = [...new Set([...leadContactEmails, ...leadCreatedByEmails])].filter(el => !teamEmails.includes(el))

        vm.leadEmailsCount = leadEmails.length
        vm.sentEmail = true
        LeadService.emailLeads(leadEmails, sendEmailPrefs.message, sendEmailPrefs.cta)
        $scope.$apply()
        $timeout(function() {
          vm.hideAlert('sentEmail')
        }, 5000)
      })
    })
  }
  vm.toggleManageSettings = function() {
    vm.manageSettings = !vm.manageSettings
  }
  vm.numberOfPages = function() {
    return parseInt(vm.leads.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}