SubscriptionsController.$inject = ['$rootScope', 'SubscriptionService', '$stateParams', 'angularGridInstance', 'AppService', '$timeout', '$state']

export default function SubscriptionsController($rootScope, SubscriptionService, $stateParams, angularGridInstance, AppService, $timeout, $state) {
  var vm = this

  function getSubscriptions(profileId) {
    SubscriptionService.getSubscriptions(profileId).then(res => vm.subscriptions = res.data)
  }

  $rootScope.$watch('profile', profile => {
    if (profile !== undefined) {
      getSubscriptions(profile.id)
      AppService.getRecurrencePatterns().then(res => vm.recurrencePatterns = res.data)
      vm.settings = profile.settings.components.find(component => component.name === 'subscriptions')
    }
  })

  vm.currentPage = $stateParams.page
  vm.pageSize = 24
  vm.maxSize = 5

  vm.setView = function() {
    vm.settings.view = vm.settings.view === 'grid' ? 'list' : 'grid', angularGridInstance.grid.refresh()
    AppService.patchSettings('components', $rootScope.profile.settings.components, $rootScope.profile.settings.id)
  }
  vm.setSortBy = function(property) {
    var copy = vm.subscriptions
    vm.subscriptions = null
    function compare(a, b) {
      let comparison = 0
      if (a[property] > b[property]) {
        comparison = vm.sortChanged ? -1 : 1
      } else if (a[property] < b[property]) {
        comparison = vm.sortChanged ? 1 : -1
      }
      return comparison
    }
    copy.sort(compare)
    vm.sortChanged = !vm.sortChanged
    $timeout(function() {
      vm.subscriptions = copy
    }, 100)
  }
  vm.setPromoted = function(subscription) {
    SubscriptionService.patchSubscription(subscription.id, 'isPromoted', !subscription.isPromoted).then(() => {
      vm.subscriptions = null
      $timeout(function() {
        getSubscriptions(sessionStorage.getItem('profileId'))
      }, 100)
    })
  }
  vm.setPublished = function(subscription) {
    SubscriptionService.patchSubscription(subscription.id, 'isPublished', !subscription.isPublished).then(() => {
      vm.subscriptions = null
      $timeout(function() {
        getSubscriptions(sessionStorage.getItem('profileId'))
      }, 100)
    })
  }
  vm.delete = function(subscription) {
    vm.confirmDelete = true
    vm.subscriptionToDelete = subscription
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    SubscriptionService.deleteSubscription(vm.subscriptionToDelete).then(() => getSubscriptions(sessionStorage.getItem('profileId')))
  }
  vm.setProperty = function(id, key, value) {
    if (key === 'subscription') SubscriptionService.patchSubscription(id, 'slug', AppService.toSlug(value))
    SubscriptionService.patchSubscription(id, key, value).then(() => getSubscriptions(sessionStorage.getItem('profileId')))
  }
  vm.numberOfPages = function() {
    return parseInt(vm.subscriptions.length / vm.pageSize)
  }
  vm.pageChanged = function(currentPage) {
    $state.go('.', { page: currentPage }, { notify: false })
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
}