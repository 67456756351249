StudyController.$inject = ['$rootScope', 'StudyService', '$stateParams', 'AppService', '$uibModal', '$state', '$transitions']

export default function StudyController($rootScope, StudyService, $stateParams, AppService, $uibModal, $state, $transitions) {
  var vm = this

  function saveSelection() {
    if (window.getSelection) {
      sel = window.getSelection()
      if (sel.getRangeAt && sel.rangeCount) {
        var ranges = []
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
          ranges.push(sel.getRangeAt(i))
        }
        return ranges
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange()
    }
    return null
  }

  var copy
  $rootScope.$watch('profile.id', profileId => {
    if (profileId !== undefined) {
      StudyService.getStudy(profileId, $stateParams.slug).then(res => {
        vm.study = res.data
        copy = angular.copy(vm.study)
      })
    }
  })

  vm.insertLink = function() {
    AppService.savedSel = saveSelection()

    $uibModal.open({
      controller: 'ModalLinkController',
      controllerAs: 'modalLink',
      template: require('../../app/link-modal-new.html')
    }).result.then(angular.noop, angular.noop)
  }
  vm.setPromoted = function() {
    vm.study.isPromoted = !vm.study.isPromoted
  }
  vm.setPublished = function() {
    vm.study.isPublished = !vm.study.isPublished
  }
  vm.delete = function() {
    vm.confirmDelete = true
  }
  vm.doDelete = function() {
    vm.hideAlert('confirmDelete')
    StudyService.deleteStudy(vm.study).then(() => $state.go('shell.studies'))
  }
  vm.hideAlert = function(alert) {
    vm[alert] = false
  }
  $transitions.onBefore({}, () => {
    if (!angular.equals(vm.study, copy)) return StudyService.putStudy(vm.study)
  }, {invokeLimit: 1})
}